import React, { useCallback, useEffect, useState } from 'react';
import { Unauthorized, Spinner } from '@scolab/publisher-ui-kit';
import { useMediator } from '@scolab/common-editor';
import LoginAppMediator from '../mediators/LoginAppMediator';

/**
 * This page collects a single use token obtained from the global login page and uses it to authenticate
 * a user while ending with a final redirection to a deep link in the publisher.
 */
export const StartSessionFromAccessToken: React.FC = () => {
  const [error, setError] = useState<Error | null>(null);

  const mediator = useMediator(LoginAppMediator);

  const authenticateUser = useCallback(async (token: string, returnUrl: string) => {
    if (!await mediator.authenticate(token)) {
      setError(new Error('An error occurred while authenticating.'));
      return;
    }

    // Forward using pure javascript because of possible competing
    // React Router instances with their own prefixes.
    const destination = new URL(returnUrl);
    window.location.replace(destination.toString());
  }, [mediator]);

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    if (!currentUrlParams.has('token')) {
      setError(new Error('"token" is missing from the query string.'));
      return;
    }

    if (!currentUrlParams.has('ReturnUrl')) {
      setError(new Error('"ReturnUrl" is missing from the query string.'));
      return;
    }

    authenticateUser(currentUrlParams.get('token')!, currentUrlParams.get('ReturnUrl')!);
  }, [authenticateUser]);

  return error ? <Unauthorized details={error} /> : <Spinner />;
};
