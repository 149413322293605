import { Point } from '../../../js/geom/Point';

import { XGeom } from '../../core/XGeom';
import { XMath } from '../../core/XMath';
import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WSegment extends TokenElement {
  private _a: Point;

  private _b: Point;

  public get a(): Point {
    return this._a.clone();
  }

  public get b(): Point {
    return this._b.clone();
  }

  /**
   *
   */
  constructor(a: Point, b: Point) {
    super();
    this._a = a;
    this._b = b;
  }

  /**
   *
   */
  public get length(): number {
    return Point.distance(this.a, this.b);
  }

  /**
   *
   */
  public get isVertical(): boolean {
    return this.a.x === this.b.x;
  }

  /**
   *
   */
  public get slope(): number {
    if (this.a.x === this.b.x) {
      return NaN;
    }
    return (this.b.y - this.a.y) / (this.b.x - this.a.x);
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WSegment) {
      return this.a.equals(value.a)
        && this.b.equals(value.b);
    }
    return false;
  }

  /**
   * Returns true if two segments overlap perfectly.
   * Order of points is not important.
   */
  public overlap(s: WSegment): boolean {
    return (this.a.equals(s.a) && this.b.equals(s.b))
      || (this.a.equals(s.b) && this.b.equals(s.a));
  }

  /**
   *
   */
  public containsPoint(p: Point): boolean {
    return XGeom.pointOnSegment(p, this.a, this.b);
  }

  /**
   *
   */
  public isParallelTo(otherSegment: WSegment): boolean {
    if (this.isVertical && otherSegment.isVertical) {
      return true;
    }
    if (this.isVertical || otherSegment.isVertical) {
      return false;
    }
    return XMath.safeEquals(this.slope, otherSegment.slope);
  }

  /**
   *
   */
  public isPerpendicularTo(otherSegment: WSegment): boolean {
    // slope == (-1 / other_slope)
    const sa: number = this.slope;
    const sb: number = otherSegment.slope;
    if ((sa === 0 && isNaN(sb)) || (isNaN(sa) && sb === 0)) {
      return true;
    }
    if (sa === 0 || isNaN(sa) || sb === 0 || isNaN(sb)) {
      return false;
    }
    return XMath.safeEquals(sa, -1 / sb);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(this.toString());
    }
    return true;
  }

  /**
   *
   */
  public toString(): string {
    return XString.substitute(
      '[Segment({0}; {1})]',
      this.a.toString(),
      this.b.toString());
  }

  /**
   *
   */
  public hashCode(): string {
    return `S(${this.a.toString()};${this.b.toString()})`;
  }

  /**
   *
   */
  public getListItemCode(): string {
    return 'segment';
  }

  /**
   * Use this function to sort a list of segments.
   */
  public static compare(
    a: WSegment,
    b: WSegment): number {
    const al: number = Math.min(a.a.x, a.b.x);
    const bl: number = Math.min(b.a.x, b.b.x);
    const at: number = Math.min(a.a.y, a.b.y);
    const bt: number = Math.min(b.a.y, b.b.y);

    if (al < bl) {
      return -1;
    }
    if (al > bl) {
      return 1;
    }
    if (at < bt) {
      return -1;
    }
    if (at > bt) {
      return 1;
    }
    return 0;
  }

  /**
   *
   */
  public static tryPrase(
    a: Point,
    b: Point): WSegment {
    if (a.equals(b)) {
      return null;
    }
    return new WSegment(a, b);
  }

  /**
   *
   */
  public getType(): string {
    return 'segment';
  }
}
