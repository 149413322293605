import { computed, observable, runInAction } from 'mobx';
import { cloneDeep } from 'lodash';
import { Store } from '../../dependencyInjection/Store';
import { IContentTag } from '../IContentTag';
import { getAllContentTags } from '../../api/contentTags/getAllContentTags';
import { createContentTag } from '../../api/contentTags/createContentTag';
import { IBackEndContentTag } from '../../api/contentTags/IBackEndContentTag';
import { updateContentTag } from '../../api/contentTags/updateContentTag';
import { deleteContentTag } from '../../api/contentTags/deleteContentTag';
import { mergeContentTags } from '../../api/contentTags/mergeContentTags';
import { historyLogEvent, historyLogEvents } from '../../command/HistoryLogEventCommand';
import { HistoryEventEntityType, HistoryEventTypes } from '../../models/history';

@Store('ContentTagsStore')
export class ContentTagsStore {
  @observable
  private accessor _contentTagsList: IContentTag[];

  private contentTagsFetchStatus: 'notInitiated' | 'initiated' | 'finished';

  constructor() {
    runInAction(() => {
      this._contentTagsList = [];
      this.contentTagsFetchStatus = 'notInitiated';
    });
  }

  public get contentTagsList(): ReadonlyArray<IContentTag> {
    if (this.contentTagsFetchStatus === 'notInitiated') {
      this.fetchContentTags();
    }
    return this._contentTagsList;
  }

  @computed
  public get contentTagsMap(): Record<number, IContentTag> {
    const contentTagsMap: Record<number, IContentTag> = {};
    this.contentTagsList.forEach((contentTag) => {
      contentTagsMap[contentTag.id] = contentTag;
    });
    return contentTagsMap;
  }

  public createContentTag = async (subjectId: number, nameFr: string, description: string) => {
    const data = await createContentTag(subjectId, nameFr, description);
    const contentTag = convertContentTag(data);
    runInAction(() => {
      this._contentTagsList.push(contentTag);
    });

    const undo = async () => {
      runInAction(() => {
        this._contentTagsList = this._contentTagsList.filter(ct => ct.id !== contentTag.id);
      });
      await deleteContentTag(contentTag.id);
    };

    await historyLogEvent({
      eventType: HistoryEventTypes.ContentTagCreated,
      entity1: {
        entityType: HistoryEventEntityType.ContentTag,
        entityId: Number(contentTag.id),
      },
    }, undo);
  };

  public updateContentTag = async (contentTag: IContentTag, newValues: Partial<IContentTag>) => {
    const originalContentTag = cloneDeep(contentTag);
    runInAction(() => {
      Object.assign(contentTag, newValues);
    });

    const undo = async () => {
      runInAction(() => {
        Object.assign(contentTag, originalContentTag);
      });
      await updateContentTag(contentTag);
    };

    await Promise.all([
      updateContentTag(contentTag),
      historyLogEvent({
        eventType: HistoryEventTypes.ContentTagUpdated,
        entity1: {
          entityType: HistoryEventEntityType.ContentTag,
          entityId: Number(contentTag.id),
        },
      }, undo),
    ]);
  };

  public deleteContentTag = async (contentTag: IContentTag) => {
    runInAction(() => {
      this._contentTagsList = this._contentTagsList.filter(ct => ct.id !== contentTag.id);
    });

    const undo = async () => {
      runInAction(() => {
        this._contentTagsList.push(contentTag);
      });
    };
    const delayedPersistCallback = async () => {
      await deleteContentTag(contentTag.id);
    };

    await historyLogEvent({
      eventType: HistoryEventTypes.ContentTagDeleted,
      entity1: {
        entityType: HistoryEventEntityType.ContentTag,
        entityId: Number(contentTag.id),
      },
    }, undo, delayedPersistCallback);
  };

  public mergeContentTags = async (tagIdsToMerge: ReadonlyArray<number>, subjectId: number, nameFr: string, description: string) => {
    const resultingTag = this.contentTagsMap[tagIdsToMerge[0]];
    const tagIdsToRemove = tagIdsToMerge.filter(id => id !== resultingTag.id);

    const originalTagValues = cloneDeep(resultingTag);
    const deletedTags = tagIdsToRemove.map(id => this.contentTagsMap[id]);

    runInAction(() => {
      resultingTag.subjectId = subjectId;
      resultingTag.name.fr = nameFr;
      resultingTag.description = description;
      this._contentTagsList = this._contentTagsList.filter(ct => !tagIdsToRemove.includes(ct.id));
    });

    const undo = async () => {
      runInAction(() => {
        Object.assign(resultingTag, originalTagValues);
        this._contentTagsList.push(...deletedTags);
      });
    };
    const delayedPersistCallback = async () => {
      await Promise.all([
        updateContentTag(resultingTag),
        mergeContentTags(tagIdsToRemove, resultingTag.id),
      ]);
    };

    await historyLogEvents([
      {
        eventType: HistoryEventTypes.ContentTagsMerged,
        entity1: {
          entityType: HistoryEventEntityType.ContentTag,
          entityId: Number(resultingTag.id),
        },
        oldValue: tagIdsToRemove.join(','),
        newValue: String(resultingTag.id),
      }, {
        eventType: HistoryEventTypes.ContentTagUpdated,
        entity1: {
          entityType: HistoryEventEntityType.ContentTag,
          entityId: Number(resultingTag.id),
        },
      },
    ], undo, delayedPersistCallback);
  };

  private fetchContentTags = async () => {
    this.contentTagsFetchStatus = 'initiated';

    const allContentTags = await getAllContentTags();

    runInAction(() => {
      this.contentTagsFetchStatus = 'finished';
      this._contentTagsList = allContentTags.map(convertContentTag);
    });
  };
}

const convertContentTag = (backEndContentTag: IBackEndContentTag): IContentTag => ({
  id: backEndContentTag.id,
  subjectId: backEndContentTag.subjectId,
  name: backEndContentTag.name,
  useCount: backEndContentTag.useCount,
  description: backEndContentTag.notes,
  creationDateISO: backEndContentTag.createdOn,
});
