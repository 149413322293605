import { BasicWriter } from '../core/mml/BasicWriter';
import { MElement } from '../core/mml/MElement';
import { MmlWriter } from '../core/mml/MmlWriter';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Expression } from '../elements/abstract/Expression';
import { Node } from '../elements/abstract/Node';
import { FormProvider } from '../elements/factories/FormProvider';
import { FormatProvider } from '../elements/factories/FormatProvider';
import { OutputProvider } from '../elements/factories/OutputProvider';
import { StringImporter } from '../expr/conversion/input/StringImporter';
import { AudioExporter } from '../expr/conversion/output/AudioExporter';
import { HtmlExporter } from '../expr/conversion/output/HtmlExporter';
import { MarkupExporter } from '../expr/conversion/output/MarkupExporter';
import { ApplyRecursive } from '../expr/manipulation/ApplyRecursive';
import { AlternateForm } from '../expr/manipulation/alt/AlternateForm';
import { Environment } from '../expr/Environment';

/**
 *
 */
export class Variable {
  // init
  public id: string;

  public expression: Expression;

  public format: FormatProvider;

  public output: OutputProvider;

  public form: FormProvider;

  public env: Environment;

  //
  public apply: ApplyRecursive;

  public result: Expression;

  /**
   *
   */
  constructor(
    id: string,
    expression: Expression,
    format: FormatProvider,
    output: OutputProvider,
    form: FormProvider,
    env: Environment) {
    this.id = id;
    this.expression = expression;
    this.format = format || new FormatProvider(env.culture);
    this.output = output || OutputProvider.getDefaultInstance();
    this.form = form || new FormProvider(env.culture, null, null);
    this.env = env;
    this.simplify();
  }

  private simplify(): void {
    if (this.expression) {
      this.apply = new ApplyRecursive(this.expression, this.env, this.format);
      this.result = this.apply.simplify(this.output.isStepsOrRawSteps, this.output.isVerbose, this.output.isRawSteps);
    }
  }

  /**
   * Return the value to use when this declaration is referenced
   * in another expression and need to be substituted
   */
  public get substitutionValue(): Node {
    if (this.output.isRaw) {
      if (this.expression && this.expression.root) {
        return this.expression.root.clone();
      }
      return null;
    }

    if (this.result && this.result.root) {
      return this.result.root.clone();
    }

    return null;
  }

  /**
   *
   * @returns {boolean}
   */
  public get isOriginalExpression(): boolean {
    return this.output.isRaw;
  }

  /**
   * Returns null if plain text option is not possible.
   */
  public toText(): string {
    if (this.form.getAlternateForm()) {
      return null;
    }
    const leaf: ContentElement = this.toLeaf();
    return leaf ? this.format.translateString(leaf.toText(true)) : null;
  }

  /**
   * Valid html tags are permitted inside authored strings. Sometime operators < > are
   * mixed with html tags and we need to encode the < > operators. User-input doesn't
   * allow html tags, in this case, all < > are encoded.
   */
  public toEncodedText(): string {
    if (this.form.getAlternateForm()) {
      return null;
    }
    const leaf: ContentElement = this.toLeaf();
    return leaf ? this.format.translateString(leaf.toEncodedText(true)) : null;
  }

  /**
   * Returns an alternative text to be sent to the tts engine.
   */
  public toSpeakText(): string {
    const leaf: ContentElement = this.toLeaf();

    if (!leaf) {
      return null;
    }

    const alternateForm: AlternateForm = this.form.getAlternateForm();

    if (alternateForm) {
      return alternateForm.tts(leaf);
    }

    return (new AudioExporter(this.env.culture)).exportLeaf(leaf);
  }

  /**
   * Returns the leaf simplified result for
   * this declaration if it exists.
   */
  public toLeaf(isOutputSensitive: boolean = true): ContentElement {
    if (this.output.isSimplified || !isOutputSensitive) {
      if (!this.result) {
        return null;
      }
      if (!this.result.root) {
        return null;
      }
      return this.result.root.isLeaf ? this.result.root.value : null;
    }
    return null;
  }

  /**
   *
   */
  public toElement(): MElement {
    const w: BasicWriter = new BasicWriter();
    const m: MmlWriter = new MmlWriter(w);
    this.writeTo(m);
    return m.content as MElement;
  }

  /**
   *
   */
  public toHtml(withCss: boolean): string {
    return new HtmlExporter(this.toElement(), this.format, withCss).toHtmlString();
  }

  public writeTo(writer: MmlWriter): void {
    const expressions: Expression[] = [];
    let withStyles: boolean; // indicate that the output is css enabled

    if (this.output.isRaw) {
      withStyles = false;
      expressions.push(this.expression);
    } else if (this.output.isStepsOrRawSteps) {
      withStyles = true;
      if (this.apply.states.length === 0) {
        expressions.push(this.result);
      } else {
        for (const item of this.apply.states) {
          expressions.push(item);
        }
      }
    } else {
      withStyles = false;
      expressions.push(this.toAlternate() != null ? this.toAlternate() : this.result);
    }

    try {
      const exporter = new MarkupExporter(writer, this.format, withStyles);
      exporter.writeStartDocument();
      exporter.writeExpressions(expressions);
      exporter.writeEndDocument();
    } catch (e) {
      window.console.error('Cannot write variable to writer.', e);
    }
  }

  /**
   * Returns alternate form.
   */
  public toAlternate(): Expression {
    const alternateForm: AlternateForm = this.form.getAlternateForm();

    if (!alternateForm) {
      return null;
    }

    const leaf: ContentElement = this.toLeaf();

    if (!leaf) {
      return null;
    }

    const _alt: string = alternateForm.alt(leaf);

    if (_alt != null) {
      const e: Expression
        = new StringImporter(
          _alt,
          null,
          this.env,
          false).expr;
      e.lineBreakOperator = alternateForm.lineBreakOperator();
      return e;
    }

    return null;
  }
}
