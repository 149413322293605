import { RestrictExpression } from '../../elements/models/RestrictExpression';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RestrictPolicy } from '../../funcs/expr/RestrictPolicy';

/**
 *
 */
export class DenyOperators extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getExpression(0) && args.getString(1)) {
      return this.restrict(args.getExpression(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private restrict(
    expr: WExpression,
    operators: WString): WExpression {
    const r: RestrictExpression
      = expr.restrict
        ? expr.restrict.clone()
        : new RestrictExpression();

    r.operators = operators.getString().split('');
    r.operatorsPolicy = RestrictPolicy.DENY;

    return expr.setRestrict(r);
  }
}
