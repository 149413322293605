import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';

/**
 *
 */
export class ListUtil {
  /**
   *
   */
  public static smallestFollowing(
    n: RealElement,
    values: RealElement[],
    acceptEquals: boolean): ContentElement {
    const sorted: RealElement[] = ListUtil.sort(values);

    for (let i: number = 0; i < sorted.length; i++) {
      const c: RealElement = sorted[i];
      if ((acceptEquals && n.toNumber() <= c.toNumber()) || n.toNumber() < c.toNumber()) {
        return sorted[i];
      }
    }

    return WNotANumber.getInstance();
  }

  /**
   *
   */
  public static largestPrevious(
    n: RealElement,
    values: RealElement[],
    acceptEquals: boolean): ContentElement {
    let sorted: RealElement[] = ListUtil.sort(values);
    sorted = sorted.reverse();

    for (let i: number = 0; i < sorted.length; i++) {
      const c: RealElement = sorted[i];
      if ((acceptEquals && n.toNumber() >= c.toNumber()) || n.toNumber() > c.toNumber()) {
        return sorted[i];
      }
    }

    return WNotANumber.getInstance();
  }

  /**
   *
   */
  public static sort(values: RealElement[]): RealElement[] {
    const copy: any[] = values.concat();
    const mapped: any[] = copy.map(RealElement.encapsulator);
    mapped.sort(RealElement.compareEncapsulated);
    const sorted: any[] = mapped.map(RealElement.decapsulator);
    return sorted;
  }
}
