import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { IPageLock } from '../IPageLock';

type PageGUID = string;

@Store('PageLockStore', true)
export class PageLockStore {
  @observable
  private accessor _pagesLock: Record<PageGUID, IPageLock>;

  constructor() {
    runInAction(() => {
      this._pagesLock = {};
    });
  }

  public getPageLock = (pageGUID: PageGUID) => {
    return this._pagesLock[pageGUID];
  };

  @action
  public setPageLock = (pageGUID: PageGUID, pageLock: IPageLock) => {
    this._pagesLock[pageGUID] = pageLock;
  };

  @action
  public removePageLock = (pageGUID: PageGUID) => {
    delete this._pagesLock[pageGUID];
  };
}
