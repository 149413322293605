import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeGroup extends AbstractNode {
  private _open: string;

  private _close: string;

  private _content: AbstractNode;

  private _keep: boolean;

  constructor(content: AbstractNode, open: string, close: string, keep: boolean = false) {
    super();
    this._content = content;
    this._open = open;
    this._close = close;
    this._keep = keep;

    this._content.setParent(this);
  }

  public get content(): AbstractNode {
    return this._content;
  }

  public getToken(): any[] {
    return [new MConstruct(MConstruct.TYPE_FENCE, [this._content.getToken()], this._open, this._close)];
  }

  public simplify(): AbstractNode {
    let contentSimplified: AbstractNode = this._content.simplify();

    if (contentSimplified instanceof EmptyLeaf) {
      return contentSimplified;
    }

    if (!this._keep
      && (contentSimplified instanceof NodeConstant
        || contentSimplified instanceof NodeVariable
        || contentSimplified instanceof NodeGroup)) {
      return contentSimplified;
    }

    if (this._keep && contentSimplified instanceof NodeGroup) {
      contentSimplified = contentSimplified.content;
    }

    return new NodeGroup(contentSimplified, this._open, this._close);
  }
}
