import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class SetOperations extends InlineContext {
  constructor() {
    super('SetOperations', ['a'] as string[], null);
  }

  public getContextModel(): IContextModel {
    const o: IContextModel = {
      variables: [{
        type: 'texts',
        id: 'symbols',
        texts: {
          useList: true,
          values: [
            {
              value: '∪',
            }, {
              value: '∩',
            }, {
              value: '∖',
            },
          ],
        },
      }, {
        type: 'texts',
        id: 'wordsFr',
        texts: {
          useList: true,
          values: [
            {
              value: 'ou qu\'il soit',
            }, {
              value: 'et qu\'il soit',
            }, {
              value: 'sans qu\'il soit',
            },
          ],
        },
      }, {
        type: 'texts',
        id: 'wordsEn',
        texts: {
          useList: true,
          values: [
            {
              value: 'or is',
            }, {
              value: 'and it is',
            }, {
              value: 'without',
            },
          ],
        },
      }, {
        value: 'if(isfr(), wordsFr, wordsEn)',
        id: 'words',
      }, {
        type: 'function',
        piecewise: {
          inputArguments: 'o',
          pieces: [{
            value: 'λ(A, B, A∪B)',
            condition: 'o=0',
          }, {
            value: 'λ(A, B, A∩B)',
            condition: 'o=1',
          }, {
            value: 'λ(A, B, A∖B)',
            condition: 'o=2',
          },
          ],
        },
        id: 'functions',
      }, {
        value: 'symbols_a',
        id: 'symbol',
      }, {
        value: 'words_a',
        id: 'word',
      }, {
        value: 'functions(a)',
        id: 'function',
      },
      ],
    };

    return o;
  }
}
