import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { FractionModel } from '../elements/models/FractionModel';
import { WPower } from '../elements/tokens/WPower';
import { WRational } from '../elements/tokens/WRational';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { COptions } from './COptions';
import { CNumber } from './CNumber';
import { CFraction } from './CFraction';
import { BaseCorrector } from './BaseCorrector';

/**
 * (num/den)^exp
 */
export class CFracExp extends BaseCorrector {
  private cbase: CFraction;

  private cexp: CNumber;

  /**
   *
   */
  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.cbase = new CFraction();
    this.cexp = new CNumber(null, null, true, false);
    super.configureOther(this.cbase);
    super.configureOther(this.cexp);
  }

  public parse(value: string): Node {
    const po: WPower = this.tryParse(value);
    if (po) {
      const node: Node = new Node(po);
      // preserve power, preserve rationals
      node.userData = po.userData.replace('{', '(').replace('}', ')') + '@1025';
      return node;
    }
    return null;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const value2: WPower = this.tryParse(value);
    const target2: WPower = target as WPower;

    if (!value2) {
      // TODO: gentle reminder invalid format
      return false;
    }

    let base: string = value2.userData.split('^')[0];
    let exp: string = value2.userData.split('^')[1];

    const baseEnclosed: boolean = XString.isEnclosed(base);
    const baseRational: boolean = base.indexOf('/') !== -1;

    if (!baseEnclosed && baseRational) {
      // TODO: gentle reminder ambiguous exponent with rational base
    }

    base = XString.trimParenthesis(base);
    exp = XString.trimBraces(exp);

    return this.cbase.correct(base, target2.base)
      && this.cexp.correct(exp, target2.exponent);
  }

  private tryParse(valueArg: string): WPower {
    const value = this.sanitizeInput(valueArg);

    const parts: any[] = value.split('^');
    const baseEnclosed: boolean = XString.isEnclosed(parts[0]);

    parts[0] = XString.trimParenthesis(parts[0]);
    if (parts.length > 1) {
      parts[1] = XString.trimBraces(parts[1]);
    } else {
      parts.push('1');
    }

    const base = FractionModel.tryParse(parts[0], this.useLatex);
    if (!base) {
      return null;
    }

    const exp = this.env.culture.createNumber(this.numberParser.parseNumber(parts[1]));
    if (!exp) {
      return null;
    }

    const power: WPower = new WPower(this.parseRationalModel(base), exp);
    power.userData = (baseEnclosed ? '(' : '') + parts[0] + (baseEnclosed ? ')' : '') + '^{' + parts[1] + '}';
    return power;
  }

  public get inputCapabilities(): InputCapabilities {
    const o: InputCapabilities = new InputCapabilities();
    o.fractionExponent = true;
    o.symbols = '−/^()';
    return o;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.FRACTION_EXP;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const po: WPower = target as WPower;

    if (po.base instanceof WRational) {
      w.openParenthesis();
      w.beginFraction();
      w.writeNumber(po.base.numerator);
      if (this.useLatex) {
        w.beginDenominator();
      } else {
        w.writeInfixOperator('/');
      }
      w.writeNumber(po.base.denominator);
      w.endFraction();
      w.closeParenthesis();
    } else {
      w.writeNumber(po.base.toNumber());
    }

    if (po.exponent.toNumber() !== 1) {
      w.startParagraph();
      w.beginExponent();
      w.writeNumber(po.exponent.toNumber());
      w.endExponent();
    }
  }
}
