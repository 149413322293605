import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { WSegment } from '../../elements/tokens/WSegment';

/**
 * Collection of segments.
 */
export class WListOfSegments extends ListElement {
  constructor(
    segments: ContentElement[],
    formatter: BaseListFormatter) {
    super(segments, formatter);
  }

  public getTypedItemAt(index: number): WSegment {
    return this.getItemAt(index) as WSegment;
  }

  public acceptElement(element: ContentElement): boolean {
    return element.getListItemCode() === 'segment';
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WListOfSegments) {
      return this.unorderedEqualsTo(value);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfSegments) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  public toSegments(): WSegment[] {
    const o: WSegment[] = [];
    for (let i: number = 0; i < this.count; i++) {
      o.push(this.getTypedItemAt(i));
    }
    return o;
  }

  protected createList(
    items: ContentElement[],
    formatter: BaseListFormatter): ListElement {
    return new WListOfSegments(items, formatter);
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    return WSegment.compare(a as WSegment, b as WSegment);
  }

  protected elementsEquals(
    a: ContentElement,
    b: ContentElement,
    unordered: boolean): boolean {
    const sa: WSegment = a as WSegment;
    const sb: WSegment = b as WSegment;

    if (unordered) {
      return sa.overlap(sb);
    }

    return sa.equalsTo(sb);
  }

  /**
   *
   */
  public getType(): string {
    return 'segments';
  }
}
