import { AuthTokenProvider } from './AuthTokenProvider';

class PublisherApiAuthService extends AuthTokenProvider {
  protected readonly onUnauthorizedCallbacks: Array<() => void> = [];

  protected get storageKey() {
    return 'PublisherApiAccessToken';
  }

  public addUnauthorizedRequestEventListener(callback: () => void) {
    this.onUnauthorizedCallbacks.push(callback);
  }

  public onUnauthorizedRequest(executeListeners = true) {
    this.clearToken();

    if (executeListeners) {
      this.onUnauthorizedCallbacks.forEach(callback => callback());
    }
  }

  public removeUnauthorizedRequestEventListener(callback: () => void) {
    const index = this.onUnauthorizedCallbacks.indexOf(callback);
    if (index > -1) {
      this.onUnauthorizedCallbacks.splice(index, 1);
    }
  }
}

export const PublisherApiAuth = new PublisherApiAuthService();
