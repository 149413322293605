import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WTable } from '../../elements/tokens/WTable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';

/**
 *
 */
export class Table extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    args.expectingArguments(0, Number.POSITIVE_INFINITY);
    return this.createTable(args.args, args.env);
  }

  /**
   *
   */
  private createTable(columns: ContentElement[], env: Environment): WTable {
    const _columns: ListElement[] = [];

    if (columns.length === 1 && columns[0] instanceof WListOfPoints) {
      const pointList = columns[0];
      for (let i = 0; i < pointList.count; i++) {
        const point = pointList.getTypedItemAt(i);
        _columns.push(env.culture.listFactory.createList([point.x, point.y]));
      }
      return new WTable(_columns);
    }

    // 1. Add every list as an individual column
    // 2. Add every columns of matrices
    // 3. Add every list items as an individual column with one row
    for (let i: number = 0; i < columns.length; i++) {
      const column: ContentElement = columns[i];
      if (column instanceof ListElement) {
        _columns.push(column);
      } else if (column instanceof WMatrix) {
        const mat: WMatrix = column;
        if (mat.count === 0) {
          continue;
        }
        for (let c: number = 0; c < mat.columns; c++) {
          const col: ContentElement[] = [];
          for (let r: number = 0; r < mat.rows; r++) {
            col.push(mat.valueAt(r, c));
          }
          _columns.push(env.culture.listFactory.createList(col));
        }
      } else if (column.getListItemCode()) {
        const items: ContentElement[] = [];
        items.push(column);
        _columns.push(env.culture.listFactory.createList(items));
      } else {
        throw new MathError('Columns must be lists');
      }
    }
    return new WTable(_columns);
  }
}
