import { Point } from '../../js/geom/Point';
import { Rectangle } from '../../js/geom/Rectangle';
import { XMath } from './XMath';
import { XRound } from './XRound';

/**
 *
 */
export class XGeom {
  /**
   *
   */
  public static safePoint(value: Point): Point {
    return new Point(
      XRound.safeRound(value.x),
      XRound.safeRound(value.y));
  }

  /**
   *
   */
  public static envelope2(...vertices2: Point[]): Rectangle {
    return XGeom.envelope(vertices2);
  }

  /**
   * @snap: indicates that the envelope is snapped on integers
   */
  public static envelope(vertices: Point[], snap: boolean = false): Rectangle {
    let left: number = Number.MAX_VALUE;
    let right: number = -Number.MAX_VALUE;
    let top: number = Number.MAX_VALUE;
    let bottom: number = -Number.MAX_VALUE;

    for (let i: number = 0; i < vertices.length; i++) {
      const p: Point = vertices[i];
      left = Math.min(left, p.x);
      right = Math.max(right, p.x);
      top = Math.min(top, p.y);
      bottom = Math.max(bottom, p.y);
    }

    if (left === right || top === bottom) {
      return null;
    }

    if (snap) {
      left = Math.floor(left);
      top = Math.floor(top);
      right = Math.ceil(right);
      bottom = Math.ceil(bottom);
    }

    return new Rectangle(
      left,
      top,
      right - left,
      bottom - top);
  }

  /**
   *
   */
  public static edges(vertices: Point[]): number[] {
    const m: number[] = [];
    for (let i: number = 0; i < vertices.length; i++) {
      const a: Point = vertices[i];
      const b: Point = vertices[(i === vertices.length - 1) ? 0 : i + 1];
      m.push(XRound.safeRound(Point.distance(a, b)));
    }
    return m;
  }

  /**
   *
   */
  public static dotProduct(a: Point, b: Point): number {
    return a.x * b.x + a.y * b.y;
  }

  /**
   *
   */
  public static direction(
    origin: Point,
    end: Point): number {
    return Math.atan2(
      end.y - origin.y,
      end.x - origin.x);
  }

  /**
   * The rest parameter allow us to use this function with Array.map or with Vector.map.
   */
  public static degrees(value: number, ..._: any[]): number {
    return XRound.safeRound(value * 180 / Math.PI);
  }

  /**
   *
   */
  public static colinear(a: Point, b: Point, c: Point): boolean {
    // Les vecteurs sont colinéaires si et seulement si xy’ - yx’ = 0.
    return (c.x - b.x) * (b.y - a.y) - (c.y - b.y) * (b.x - a.x) === 0;
  }

  /**
   *
   */
  public static angle(
    o: Point,
    a: Point,
    b: Point,
    clockwise: boolean): number {
    let oa: number = XGeom.direction(o, a);
    let ob: number = XGeom.direction(o, b);

    if (oa < 0) {
      oa = 2 * Math.PI + oa;
    }
    if (ob < 0) {
      ob = 2 * Math.PI + ob;
    }

    if (clockwise) {
      if (oa >= ob) {
        return oa - ob;
      }
      return oa + 2 * Math.PI - ob;
    }

    // Counterclockwise
    if (ob >= oa) {
      return ob - oa;
    }
    return ob + 2 * Math.PI - oa;
  }

  /**
   * http://www.defl.ca/~gdube/applicationstrigonometriques/trianglesobliques/contenu/loicosinus.html
   */
  public static interiorAngle(a: Point, o: Point, b: Point): number {
    // o² = a² + b² - 2 * a * b * cos(C)
    // C = acos((o² - a² - b²) / (-2 * a * b))

    const mo: number = Point.distance(a, b);
    const ma: number = Point.distance(o, b);
    const mb: number = Point.distance(o, a);

    return Math.acos(
      ((mo ** 2) - (ma ** 2) - (mb ** 2))
      / (-2 * ma * mb));
  }

  /**
   * Returns null if the polygon is self-intersecting.
   */
  public static interiorAngles(
    vertices: Point[],
    radian: boolean = true): number[] {
    const expectedSum: number = (vertices.length - 2) * Math.PI;
    let clockwiseSum: number = 0;
    let counterclockwiseSum: number = 0;

    const clockwise: number[] = [];
    const counterclockwise: number[] = [];

    for (let o: number = 0; o < vertices.length; o++) {
      let ia: number = o - 1;
      let ib: number = o + 1;

      while (ia < 0) {
        ia += vertices.length;
      }
      while (ib >= vertices.length) {
        ib -= vertices.length;
      }

      const clockwiseAngle: number
        = XGeom.angle(
          vertices[o],
          vertices[ia],
          vertices[ib],
          true);

      const counterclockwiseAngle: number
        = XGeom.angle(
          vertices[o],
          vertices[ia],
          vertices[ib],
          false);

      clockwise.push(radian ? clockwiseAngle : XGeom.degrees(clockwiseAngle));
      counterclockwise.push(radian ? counterclockwiseAngle : XGeom.degrees(counterclockwiseAngle));

      clockwiseSum += clockwiseAngle;
      counterclockwiseSum += counterclockwiseAngle;
    }

    if (XMath.safeEquals(clockwiseSum, expectedSum)) {
      return clockwise;
    }
    if (XMath.safeEquals(counterclockwiseSum, expectedSum)) {
      return counterclockwise;
    }
    return null;
  }

  /**
   *
   */
  public static pointOnSegment(
    p: Point,
    a: Point,
    b: Point): boolean {
    if (XGeom.colinear(p, a, b)) {
      if (a.x === b.x) {
        return p.y >= Math.min(a.y, b.y)
          && p.y <= Math.max(a.y, b.y);
      }

      return p.x >= Math.min(a.x, b.x) && p.x <= Math.max(a.x, b.x);
    }

    return false;
  }

  /**
   * If the point lies on the perimeter this function returns false
   * http://www.blackpawn.com/texts/pointinpoly/default.html
   */
  public static triangleContainsPoint(a: Point, b: Point, c: Point, p: Point): boolean {
    const v0: Point = c.subtract(a);
    const v1: Point = b.subtract(a);
    const v2: Point = p.subtract(a);

    const dot00: number = XGeom.dotProduct(v0, v0);
    const dot01: number = XGeom.dotProduct(v0, v1);
    const dot02: number = XGeom.dotProduct(v0, v2);
    const dot11: number = XGeom.dotProduct(v1, v1);
    const dot12: number = XGeom.dotProduct(v1, v2);

    const invDenom: number = 1 / (dot00 * dot11 - dot01 * dot01);
    const u: number = (dot11 * dot02 - dot01 * dot12) * invDenom;
    const v: number = (dot00 * dot12 - dot01 * dot02) * invDenom;

    return (u > 0) && (v > 0) && (u + v < 1);
  }

  /**
   * Rectangle.containsPoint function returns false if the point
   * is lying on the border. This function treat that case differently.
   */
  public static rectangleContainsPoint(rect: Rectangle, p: Point): boolean {
    return p.x >= rect.left
      && p.x <= rect.right
      && p.y >= rect.top
      && p.y <= rect.bottom;
  }

  /**
   *
   */
  public static linesIntersection(
    a1: Point,
    a2: Point,
    b1: Point,
    b2: Point,
    ua_minArg: number = NaN,
    ua_maxArg: number = NaN,
    ub_minArg: number = NaN,
    ub_maxArg: number = NaN): Point {
    let ua_min = ua_minArg;
    let ua_max = ua_maxArg;
    let ub_min = ub_minArg;
    let ub_max = ub_maxArg;

    if (isNaN(ua_min)) {
      ua_min = -Number.MAX_VALUE;
    }
    if (isNaN(ua_max)) {
      ua_max = Number.MAX_VALUE;
    }
    if (isNaN(ub_min)) {
      ub_min = -Number.MAX_VALUE;
    }
    if (isNaN(ub_max)) {
      ub_max = Number.MAX_VALUE;
    }

    const ua_t: number = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
    const ub_t: number = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
    const u_b: number = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);

    let i: Point;

    if (u_b !== 0) {
      const ua: number = ua_t / u_b;
      const ub: number = ub_t / u_b;

      if (ua_min <= ua && ua <= ua_max && ub_min <= ub && ub <= ub_max) {
        i = new Point(
          a1.x + ua * (a2.x - a1.x),
          a1.y + ua * (a2.y - a1.y));
      } else {
        // No intersection
      }
    } else if (ua_t === 0 || ub_t === 0) {
      // Coincident
    } else {
      // Parallel
    }

    return i;
  }

  /**
   *
   */
  public static segmentsIntersection(
    a1: Point,
    a2: Point,
    b1: Point,
    b2: Point): Point {
    return XGeom.linesIntersection(
      a1,
      a2,
      b1,
      b2,
      0,
      1,
      0,
      1);
  }

  /**
   * Segments are on the same line and touches.
   */
  public static segmentsOverlap(
    s1a: Point,
    s1b: Point,
    s2a: Point,
    s2b: Point): boolean {
    return XGeom.colinear(s1a, s2a, s2b)
      && XGeom.colinear(s1b, s2a, s2b)
      && XGeom.colinear(s2a, s1a, s1b)
      && XGeom.colinear(s2b, s1a, s1b)
      && (XGeom.pointOnSegment(s1a, s2a, s2b)
        || XGeom.pointOnSegment(s1b, s2a, s2b)
        || XGeom.pointOnSegment(s2a, s1a, s1b)
        || XGeom.pointOnSegment(s2b, s1a, s1b));
  }
}
