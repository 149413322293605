import { Point } from '../../../js/geom/Point';

import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FxLine } from '../../elements/effofeks/FxLine';
import { IEval } from '../../elements/effofeks/IEval';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { ILine } from '../../elements/markers/ILine';
import { ILinearEquation } from '../../elements/markers/ILinearEquation';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { MathMLWriter2 } from '../../expr/conversion/writers/MathMLWriter2';
import { MathWriter } from '../../expr/conversion/writers/MathWriter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WNumber } from '../../elements/tokens/WNumber';
import { WRational } from '../../elements/tokens/WRational';
import { WPoint } from '../../elements/tokens/WPoint';

/**
 * General form
 * Ax + By + C = 0
 */
export class WLine extends TokenElement implements ILinearEquation, ILine {
  private _A: RealElement;

  private _B: RealElement;

  private _C: RealElement;

  private _formatter: BaseLinearEquationFormatter;

  private _precision: number;

  private _p0: WPoint;

  private _p1: WPoint;

  private _xLabel: string;

  private _yLabel: string;

  public get A(): RealElement {
    return this._A;
  }

  public get B(): RealElement {
    return this._B;
  }

  public get C(): RealElement {
    return this._C;
  }

  public get formatter(): BaseLinearEquationFormatter {
    return this._formatter;
  }

  public get precision(): number {
    return this._precision;
  }

  public get p0(): WPoint {
    return this._p0;
  } // defined only when line is created using two points.

  public get p1(): WPoint {
    return this._p1;
  } // defined only when line is created using two points.

  public get yLabel(): string {
    return this._yLabel;
  }

  public get xLabel(): string {
    return this._xLabel;
  }

  /**
   * Performs calculation on real numbers elements.
   */
  private realsCalc: RealsImpl;

  constructor(
    A: RealElement,
    B: RealElement,
    C: RealElement,
    formatter: BaseLinearEquationFormatter,
    precision: number = Number.MAX_SAFE_INTEGER,
    p0: WPoint = null,
    p1: WPoint = null,
    yLabel: string = 'y',
    xLabel: string = 'x') {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._A = A;
    this._B = B;
    this._C = C;
    this._formatter = formatter;
    this._precision = precision;
    this._p0 = p0;
    this._p1 = p1;
    this._yLabel = yLabel;
    this._xLabel = xLabel;
    this.realsCalc = new RealsImpl(formatter.culture, true);
  }

  /**
   *
   */
  public applyLabels(yLabel: string, xLabel: string): WLine {
    return new WLine(this.A, this.B, this.C, this.formatter, this.precision, this.p0, this.p1, yLabel, xLabel);
  }

  /**
   *
   */
  public round(precision: number): ContentElement {
    return new WLine(this.A, this.B, this.C, this.formatter.round(precision), Number.MAX_SAFE_INTEGER, this.p0, this.p1, this.yLabel, this.xLabel);
  }

  /**
   * Returns NaN if the line is vertical
   */
  public get slope(): RealElement {
    if (this.B.toNumber() === 0) {
      return null; // NaN
    }
    return this.realsCalc.divideR(this.A.toOpposite(), this.B, true); // -A / B
  }

  /**
   * Returns NaN if the line is horizontal
   */
  public get xintercept(): RealElement {
    if (this.A.toNumber() === 0) {
      return null; // NaN
    }
    return this.realsCalc.divideR(this.C.toOpposite(), this.A, true); // -C / A
  }

  /**
   * Returns NaN if the line is vertical.
   */
  public get yintercept(): RealElement {
    if (this.B.toNumber() === 0) {
      return null; // NaN
    }
    return this.realsCalc.divideR(this.C.toOpposite(), this.B, true); // -C / B
  }

  /**
   * Orthogonal projection.
   */
  public orthogonalProjection(p: Point): Point {
    // Ax + By + C = 0
    if (this.A.toNumber() === 0) {
      return new Point(p.x, this.yintercept.toNumber());
    }
    if (this.B.toNumber() === 0) {
      return new Point(this.xintercept.toNumber(), p.y);
    }

    const a: Point = new Point(0, this.yintercept.toNumber());
    const b: Point = new Point(1, this.slope.toNumber() + this.yintercept.toNumber());

    const t: number = -1 / ((b.y - a.y) / (b.x - a.x));
    const x: number = (b.x * (p.x * t - p.y + a.y) + a.x * (p.x * -t + p.y - b.y)) / (t * (b.x - a.x) + a.y - b.y);
    const y: number = t * x - t * p.x + p.y;

    return new Point(x, y);
  }

  /**
   * Ax + By + C = 0.
   * Returns null if lines are parallel or overlapping.
   */
  public intersection(line: WLine): Point {
    /*
    The calculator uses Cramer's rule
    x = (c e - f b) / D and y = (a f - d c) / D
    to solve the system.
    D is the coefficient determinant given by D = a e - b d.
    */

    const a: number = this.A.toNumber();
    const b: number = this.B.toNumber();
    const c: number = -this.C.toNumber();
    const d: number = line.A.toNumber();
    const e: number = line.B.toNumber();
    const f: number = -line.C.toNumber();

    const D: number = a * e - b * d;

    if (D === 0) {
      return null;
    }

    const i: Point
      = new Point(
        (c * e - f * b) / D,
        (a * f - d * c) / D);

    i.x = XRound.safeRound(i.x);
    i.y = XRound.safeRound(i.y);

    return i;
  }

  /**
   *
   */
  public isParallelTo(otherLine: WLine): boolean {
    if (this.B.toNumber() === 0 && otherLine.B.toNumber() === 0) {
      return true;
    }
    if (this.B.toNumber() !== 0 && otherLine.B.toNumber() !== 0) {
      let sa: number = NaN;
      let sb: number = NaN;
      if (this.slope) {
        sa = this.slope.toNumber();
      }
      if (otherLine.slope) {
        sb = otherLine.slope.toNumber();
      }
      return XMath.safeEquals(sa, sb);
    }
    return false;
  }

  /**
   *
   */
  public isPerpendicularTo(otherLine: WLine): boolean {
    // slope == (-1 / other_slope)
    let sa: number = NaN;
    let sb: number = NaN;
    if (this.slope) {
      sa = this.slope.toNumber();
    }
    if (otherLine.slope) {
      sb = otherLine.slope.toNumber();
    }
    if (sa === 0 && isNaN(sb) || isNaN(sa) && sb === 0) {
      return true;
    }
    if (sa === 0 || isNaN(sa) || sb === 0 || isNaN(sb)) {
      return false;
    }
    return XMath.safeEquals(sa, -1 / sb);
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (!(value instanceof WLine)) {
      return false;
    }

    const line: WLine = value;

    let sa: number = NaN;
    let ya: number = NaN;
    let xa: number = NaN;

    let sb: number = NaN;
    let yb: number = NaN;
    let xb: number = NaN;

    if (this.slope) {
      sa = this.slope.toNumber();
      ya = this.yintercept.toNumber();
    } else {
      xa = this.xintercept.toNumber();
    }

    if (line.slope) {
      sb = line.slope.toNumber();
      yb = line.yintercept.toNumber();
    } else {
      xb = line.xintercept.toNumber();
    }

    return isNaN(sa) && isNaN(sb)
      ? XMath.safeEquals(xa, xb)
      : XMath.safeEquals(sa, sb)
        && XMath.safeEquals(ya, yb);
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (!(value instanceof WLine)) {
      return false;
    }
    return this.A.strictlyEqualsTo(value.A)
      && this.B.strictlyEqualsTo(value.B)
      && this.C.strictlyEqualsTo(value.C);
  }

  /**
   * Do not make A positive at this point because we would need to change the
   * operator of an inequality if this line is used inside WHalfPlane.
   * A, B, C integers if possible
   */
  public toNormalized(): WLine {
    if (this.isIntegerOrRational(this.A)
      && this.isIntegerOrRational(this.B)
      && this.isIntegerOrRational(this.C)) {
      return this.normalizeMixedIntegerRational(this.A, this.B, this.C);
    }

    return this;
  }

  private rationalize(value: RealElement): WRational {
    if (value instanceof WRational) {
      return value.cleanNegativeSign();
    }

    if (value.isInteger()) {
      return this.formatter.culture.createRational(value.toNumber(), 1);
    }

    return null;
  }

  public tryRemoveRationalsCoefficients(): WLine {
    const rA: WRational = this.rationalize(this.A);
    const rB: WRational = this.rationalize(this.B);
    const rC: WRational = this.rationalize(this.C);

    if (rA && rB && rC) {
      const lcm: WNumber = this.formatter.culture.createNumber(XMath.lcm(XMath.lcm(rA.denominator, rB.denominator), rC.denominator));
      return new WLine(
        this.realsCalc.times(rA, lcm),
        this.realsCalc.times(rB, lcm),
        this.realsCalc.times(rC, lcm),
        this.formatter,
        this.precision,
        this.p0,
        this.p1,
        this.yLabel,
        this.xLabel);
    }

    return this;
  }

  private isIntegerOrRational(value: RealElement): boolean {
    if (value instanceof WRational) {
      return true;
    }
    return value.isInteger();
  }

  private normalizeMixedIntegerRational(A: RealElement, B: RealElement, C: RealElement): WLine {
    if (A.toNumber() === 1 || A.toNumber() === -1
      || B.toNumber() === 1 || B.toNumber() === -1
      || C.toNumber() === 1 || C.toNumber() === -1) {
      return this;
    }

    const dA: number = this.denominator(A);
    const dB: number = this.denominator(B);
    const dC: number = this.denominator(C);

    let c: number = 1;

    if (dA > 0) {
      c = XMath.lcm(dA, c);
    }
    if (dB > 0) {
      c = XMath.lcm(dB, c);
    }
    if (dC > 0) {
      c = XMath.lcm(dC, c);
    }

    let iA: number = XMath.safeTimes(A.toNumber(), c);
    let iB: number = XMath.safeTimes(B.toNumber(), c);
    let iC: number = XMath.safeTimes(C.toNumber(), c);

    const k: number = XMath.gcd(XMath.gcd(iA, iB), iC);

    if (k > 1) {
      iA /= k;
      iB /= k;
      iC /= k;
    }

    return new WLine(
      this.formatter.culture.createNumber(iA),
      this.formatter.culture.createNumber(iB),
      this.formatter.culture.createNumber(iC),
      this.formatter,
      Number.MAX_SAFE_INTEGER,
      this.p0,
      this.p1,
      this.yLabel,
      this.xLabel);
  }

  private denominator(value: RealElement): number {
    if (value instanceof WRational) {
      return Math.abs(value.denominator);
    }
    return Math.floor(Math.abs(value.toNumber()));
  }

  public toText(strict: boolean): string {
    if (strict) {
      return null;
    }
    return this.formatter.toLocaleString(this, '=');
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      const w: MmlWriter = exporter.writer;
      w.beginRow();
      this._formatter.flushTo(new MathWriter(new MathMLWriter2(w, this.formatter.culture)), this, '=');
      w.endRow();
    }
    return true;
  }

  /**
   *
   */
  public toEval(): IEval {
    return new FxLine(this);
  }

  public map(x: number): number {
    if (this.B.toNumber() === 0) {
      return NaN;
    }
    return this.slope.toNumber() * x + this.yintercept.toNumber();
  }

  public hashCode(): string {
    return this.toText(false);
  }

  public getType(): string {
    return 'line';
  }

  /**
   * TODO: can be a breaking change, but maybe should preserve variable name for x.
   *
   * @param value
   * @param format
   * @returns {any}
   */
  public static parsePolynomial(
    value: WPolynomial,
    format: BaseLinearEquationFormatter): WLine {
    if (!value.linear) {
      return null;
    }

    // y = ax + b
    let a: RealElement;
    let b: RealElement;
    for (let i: number = 0; i < value.numMonomials; i++) {
      switch (value.power(i, 0)) {
        case 0:
          b = value.coefs[i];
          break;
        case 1:
          a = value.coefs[i];
          break;
      }
    }

    return new WLine(
      a || format.culture.createNumber(0),
      format.culture.createNumber(-1),
      b || format.culture.createNumber(0),
      format).toNormalized();
  }

  /**
   *
   */
  public static parsePoints2(
    a: Point,
    b: Point,
    realsImpl: RealsImpl,
    format: BaseLinearEquationFormatter): WLine {
    return WLine.parsePoints(
      format.culture.parsePoint(a),
      format.culture.parsePoint(b),
      realsImpl,
      format,
    );
  }

  /**
   *
   */
  public static parsePoints(
    a: WPoint,
    b: WPoint,
    realsImpl: RealsImpl,
    format: BaseLinearEquationFormatter): WLine {
    if (a.equalsTo(b)) {
      return null;
    }

    const slopeNumerator: RealElement = realsImpl.subtract(b.y, a.y);
    const slopeDenominator: RealElement = realsImpl.subtract(b.x, a.x);

    // simplifying the slope help prevent overflow for C calculation.
    const simplifiedSlope: RealElement = realsImpl.divideR(slopeNumerator, slopeDenominator, true);

    let A: RealElement;
    let B: RealElement;
    let C: RealElement;

    const N_one: RealElement = format.culture.createNumber(1);
    const N_zero: RealElement = format.culture.createNumber(0);
    const N_minus1: RealElement = format.culture.createNumber(-1);

    if (slopeDenominator.toNumber() === 0) {
      A = N_one;
      B = N_zero;
      C = realsImpl.times(a.x, N_minus1);
    } else if (simplifiedSlope instanceof WRational) {
      const f: WRational = simplifiedSlope;
      A = format.culture.createNumber(-f.numerator);
      B = format.culture.createNumber(f.denominator);
      C = realsImpl.add(realsImpl.times(realsImpl.times(a.y, N_minus1), format.culture.createNumber(f.denominator)), realsImpl.times(a.x, format.culture.createNumber(f.numerator)));
    } else if (XMath.isInteger(simplifiedSlope.toNumber())) {
      A = format.culture.createNumber(-simplifiedSlope.toNumber());
      B = N_one;
      C = realsImpl.add(realsImpl.times(a.y, N_minus1), realsImpl.times(a.x, format.culture.createNumber(simplifiedSlope.toNumber())));
    } else {
      A = realsImpl.subtract(a.y, b.y);
      B = realsImpl.subtract(b.x, a.x);
      C = realsImpl.subtract(realsImpl.times(a.x, b.y), realsImpl.times(b.x, a.y));
    }

    return new WLine(A, B, C, format, Number.MAX_SAFE_INTEGER, a, b);
  }
}
