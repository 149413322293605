import { action, observable } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { CurriculumTreeNodeId } from '../ICurriculumTreeHeader';
import { IStandard } from '../IStandard';

@Store('AddAlignmentModalStore.ts/AddAlignmentModalStore')
export class AddAlignmentModalStore {
  @observable
  public accessor searchStandards: ReadonlyArray<IStandard>;

  @observable
  public accessor curriculumsOpenedNodes: ReadonlyArray<string>;

  @observable
  public accessor selectedNode: string;

  @observable
  public accessor curriculumsFilterText: string;

  @observable
  public accessor curriculumsFilterSubjects: ReadonlyArray<number>;

  @observable
  public accessor codeFilter: string;

  @observable
  public accessor descriptionFilter: string;

  @observable
  public accessor selectedCourseId: number;

  @observable
  public accessor selectedCurriculumId: number;

  @observable
  public accessor coursesFilterSubjects: ReadonlyArray<number>;

  constructor() {
    action(() => {
      this.curriculumsOpenedNodes = [
        `/${CurriculumTreeNodeId.curriculums}`,
      ];
      this.curriculumsFilterText = '';
      this.curriculumsFilterSubjects = [];
      this.searchStandards = null;
      this.codeFilter = null;
      this.descriptionFilter = null;
      this.selectedCourseId = null;
      this.selectedCurriculumId = null;
      this.coursesFilterSubjects = [];
    })();
  }

  @action.bound
  public toggleCurriculumsOpenNode(nodeId: string): void {
    if (this.curriculumsOpenedNodes.includes(nodeId)) {
      this.curriculumsOpenedNodes = this.curriculumsOpenedNodes.filter(n => n !== nodeId);
    } else {
      this.curriculumsOpenedNodes = this.curriculumsOpenedNodes.concat([nodeId]);
    }
  }

  @action.bound
  public openCurriculumsNodes(nodeIds: ReadonlyArray<string>): void {
    if (nodeIds.some((nodeId) => {
      return !this.curriculumsOpenedNodes.includes(nodeId);
    })) {
      this.curriculumsOpenedNodes = this.curriculumsOpenedNodes.concat(nodeIds.filter((nodeId) => {
        return !this.curriculumsOpenedNodes.includes(nodeId);
      }));
    }
  }

  @action.bound
  public setCurriculumsFilterText(value: string): void {
    this.curriculumsFilterText = value;
  }

  @action.bound
  public setCurriculumsFilterSubjects(value: ReadonlyArray<number>): void {
    this.curriculumsFilterSubjects = value;
  }

  @action.bound
  public setSelectedNode(nodeId: string): void {
    this.selectedNode = nodeId;
  }

  public get getSelectedNode(): string {
    return this.selectedNode;
  }

  @action
  public setSearchStandardsList(standards: ReadonlyArray<IStandard>): void {
    this.searchStandards = standards;
  }

  public get searchStandardsList(): ReadonlyArray<IStandard> {
    return this.searchStandards;
  }

  @action
  public setCodeFilter(code: string): void {
    this.codeFilter = code;
  }

  @action
  public setDescriptionFilter(description: string): void {
    this.descriptionFilter = description;
  }

  @action
  public setSelectedCurriculumId(id: number): void {
    this.selectedCurriculumId = id;
  }

  @action
  public setSelectedCourseId(id: number): void {
    this.selectedCourseId = id;
  }

  @action.bound
  public setCoursesFilterSubjects(value: ReadonlyArray<number>): void {
    this.coursesFilterSubjects = value;
  }
}
