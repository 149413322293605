import {
  IAltModel,
  IContextModel,
  IFormatModel,
  IOutputModel,
  ITextModel,
  IVariableModel,
  IVariantModel,
} from '@scolab/content-model';
import { IDictionary } from '../../js/utils/IDictionary';
import { XObject } from '../core/XObject';
import { IPrng } from '../core/prng/IPrng';
import { Prng } from '../core/prng/Prng';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Expression } from '../elements/abstract/Expression';
import { Node } from '../elements/abstract/Node';
import { FormProvider } from '../elements/factories/FormProvider';
import { FormatProvider } from '../elements/factories/FormatProvider';
import { NameFactory } from '../elements/factories/NameFactory';
import { OptionsProvider } from '../elements/factories/OptionsProvider';
import { OutputProvider } from '../elements/factories/OutputProvider';
import { WBoolean } from '../elements/tokens/WBoolean';
import { WMarkup } from '../elements/tokens/WMarkup';
import { WVariable } from '../elements/tokens/WVariable';
import { StringImporter } from '../expr/conversion/input/StringImporter';
import { MarkupFactory } from '../expr/types/MarkupFactory';
import { TextsFactory } from '../expr/types/TextsFactory';
import { CultureInfo } from '../localization/CultureInfo';
import { Variable } from '../expr/Variable';
import { ContextModelUtil } from '../expr/ContextModelUtil';
import { SymbolsFactory } from '../expr/SymbolsFactory';
import { Environment } from '../expr/Environment';
import { IExtensionsMethods } from '../expr/IExtensionsMethods';
import { IVariablesResolver } from '../expr/IVariablesResolver';

const defaultStaticSeed: number = Math.max(1, Math.floor(Math.random() * 0xFFFFFF));

/**
 *
 */
export class Context implements IVariablesResolver {
  /**
   * Number of times the global validation can
   * fail until we simply skip that validation.
   */
  private static contextValidationSkipThreshold: number = 10;

  /**
   * The number of times a declaration can be regenerated
   * in order to satisfy the postcondition. Past this value,
   * the system simply don't try to satisfy the postcondition
   * and go with the last value generated.
   */
  private variableValidationSkipThreshold: number = 10;

  /**
   * Context id.
   */
  private _id: string;

  public get id(): string {
    return this._id;
  }

  /**
   * Parent context, optional
   */
  private _parent: Context;

  public get parent(): Context {
    return this._parent;
  }

  public set parent(value: Context) {
    this._parent = value;
  }

  /**
   * Returns the top-level context.
   *
   * Some setting cannot be overriden by children contexts.
   */
  public get origin(): Context {
    return this._parent ? this._parent.origin : this;
  }

  /**
   *
   */
  private _extensions: IExtensionsMethods;

  public get extensions(): IExtensionsMethods {
    return this._extensions;
  }

  /**
   * Local random number generator.
   */
  private _prng: IPrng;

  public get prng(): IPrng {
    return this._prng;
  }

  /**
   *
   */
  private _radians: boolean;

  public get radians(): boolean {
    return this._radians;
  }

  /**
   * Indicates that debug variables must be created.
   * That kind of variables is usefull when creating
   * variables that depends on other variables that are not yet created.
   * For example, active values inside the authoring tool.
   */
  private _debug: boolean;

  public get debug(): boolean {
    return this._debug;
  }

  /**
   * Random number generator that will be used for internal
   * initilization and that is independent (but initially seeded)
   * from the random number generator used to randomize user
   * variables.
   *
   * Use this random number generator for initilization purpose.
   * We need independent number generator so that initilization
   * code changes won't interfere with replayability of authored
   * variables.
   */
  private _initPrng: IPrng;

  public get initPrng(): IPrng {
    return this._initPrng;
  }

  /**
   * Random number generator seeded using a static random seed, either
   * set here internally, or set by the client application through
   * extension methods.
   *
   * Each context instance manage it's own static random number generator.
   */
  private _staticPrngCopy: IPrng;

  public get staticPrngCopy(): IPrng {
    return this._staticPrngCopy;
  }

  /**
   *
   */
  private _names: NameFactory;

  public get names(): NameFactory {
    return this._names;
  }

  /**
   *
   */
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  /**
   *
   */
  private _hasDuplicateVariables: boolean = false;

  public get hasDuplicateVariables(): boolean {
    return this._hasDuplicateVariables;
  }

  /**
   *
   */
  private _hasValidationWarning: boolean = false;

  public get hasValidationWarning(): boolean {
    return this._hasValidationWarning;
  }

  /**
   *
   */
  public setValidationWarning(value: boolean): void {
    this._hasValidationWarning = value;
  }

  /**
   *
   */
  public getEnvironment(): Environment {
    return this.getEnvironmentWithOptions(OptionsProvider.DEFAULT_FLAGS);
  }

  /**
   *
   */
  private getEnvironmentWithOptions(options: number): Environment {
    return new Environment(
      this.culture,
      this.prng,
      this.staticPrngCopy,
      this.radians,
      new FormatProvider(this.culture),
      new OptionsProvider(options, null),
      this.names,
      this.extensions);
  }

  /**
   * Dictionary of previous declarations.
   */
  private declarations: IDictionary = {};

  /**
   * Validation status for every variable.
   */
  private validationStatus: Record<string, VariableValidationStatus | null> = {};

  /**
   * Validation status for every variable.
   */
  private variablesThatFailedDiffCheck: ReadonlyArray<string> = [];

  /**
   *
   */
  private generationError: any;

  /**
   * This is a map of every Variable object created by the RowWorker...this:any[] is helpfull
   * when times come to make variable substitution
   */
  public getVariable(name: string): WVariable {
    return this.variablesFactory.create(name);
  }

  public getVariableValidationStatus(name: string): VariableValidationStatus {
    return this.validationStatus[name];
  }

  public getVariablesValidationStatus(): Record<string, VariableValidationStatus | null> {
    return this.validationStatus;
  }

  public setVariablesValidationStatus(validationStatus: Record<string, VariableValidationStatus | null>): void {
    this.validationStatus = validationStatus;
  }

  public didVariableFailDiffCheck(name: string): boolean {
    return this.variablesThatFailedDiffCheck.includes(name);
  }

  public setVariablesThatFailedDiffCheck(variablesThatFailedDiffCheck: ReadonlyArray<string>): void {
    this.variablesThatFailedDiffCheck = variablesThatFailedDiffCheck;
  }

  private markupFactory: MarkupFactory = new MarkupFactory();

  private textsFactory: TextsFactory = new TextsFactory();

  private _variablesFactory: SymbolsFactory = null;

  private get variablesFactory(): SymbolsFactory {
    if (this.parent) {
      return this.parent.variablesFactory;
    }
    if (!this._variablesFactory) {
      this._variablesFactory = new SymbolsFactory(this.culture.numberFormatter);
    }
    return this._variablesFactory;
  }

  /**
   *
   */
  constructor(
    id: string,
    parent: Context,
    culture: CultureInfo,
    prng: IPrng,
    radians: boolean,
    debug: boolean,
    extensions: IExtensionsMethods) {
    this._id = id;
    this._parent = parent;
    this._culture = culture;
    this._extensions = extensions;

    if (parent) {
      this._prng = new Prng(parent.prng.currentSeed); // Do not consume a seed from the parent.
      this._initPrng = new Prng(parent.initPrng.currentSeed);
      this._staticPrngCopy = new Prng(parent.staticPrngCopy.currentSeed);
      this._radians = parent.radians;
      this._debug = parent.debug;
      this._names = parent.names.clone(); // Do not consume a name from parent, but start where the parent was.
    } else {
      this._prng = prng || new Prng(1);
      this._initPrng = new Prng(this._prng.randomSeed());
      this._staticPrngCopy = new Prng(isNaN(extensions.staticSeed) ? defaultStaticSeed : extensions.staticSeed);
      this._radians = radians;
      this._debug = debug;
      this._names = NameFactory.createFromResources(this.culture, this.initPrng);
    }
  }

  /**
   * Creates variables from xml model.
   */
  public static generate(
    id: string,
    value: IContextModel,
    culture: CultureInfo,
    extensions: IExtensionsMethods,
    catchErrors: boolean = true,
    parent: Context = null,
    prng: IPrng = null,
    debug: boolean = false,
    withSourceMap: boolean = false): Context {
    let context: Context;
    try {
      const failed: IDictionary = {}; /* map of every validation variable with it's failed validation count */
      let valid: boolean;

      do {
        context = new Context(id, parent, culture, prng, value.radians, debug, extensions);
        context.beginGeneration();

        valid = true;

        if (value.variables) {
          for (let i: number = 0; i < value.variables.length; i++) {
            const variable: IVariableModel = value.variables[i];
            context.addDeclarationFromModel(variable, withSourceMap);
            if (variable.type === 'validation') {
              valid = context.getEnvironment().expressions.toBoolean(variable.id as string, context, true);
              if (!valid) {
                if (!failed.hasOwnProperty(variable.id as string)) {
                  failed[variable.id as string] = 0;
                }
                failed[variable.id as string]++;
                break;
              }
            }
          }
        }
      } while (!valid && Context.checkSkipThreshold(failed));
      context._hasValidationWarning = !valid;
      context.endGeneration(value.randomSymbols);
    } catch (e) {
      if (catchErrors) {
        if (context) {
          context.setGenerationError(e);
        }
      } else {
        throw e;
      }
    }

    return context;
  }

  /**
   *
   */
  public validateVariables(model: IContextModel): boolean {
    if (model.variables) {
      for (let i: number = 0; i < model.variables.length; i++) {
        const variable: IVariableModel = model.variables[i];
        if (Context.validId(variable.id as string)) {
          if (!this.getDeclaration(variable.id as string)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Returns true if the skip threshold is not yet reached.
   */
  private static checkSkipThreshold(failed: IDictionary): boolean {
    const props: any[] = XObject.getProps(failed);
    for (let i: number = 0; i < props.length; i++) {
      if (failed[props[i]] >= Context.contextValidationSkipThreshold) {
        return false;
      }
    }
    return true;
  }

  /**
   * This function is not 100% sure. It needs to be improved.
   * What we try to do with that function is to try to avoid
   * two identical contexts.
   *
   * variablesThatHaveToBeDifferent: defines a list of variables to compare. Otherwise, compare the random variables.
   *
   * Returns:
   *  a) null: when one Context is a null reference
   *  b) names: when the two context does not declare the same names
   *  c) static: one of the context is all static
   *  d) error: an unexpected error occured
   *  e) nodiff: no explicit difference has been noticed, that doesn't mean there's none, it just mean that maybe the algorithm needs to be improved
   *  f) failedExplicitVariableDiffCheck: One or more variables included variablesThatHaveToBeDifferent had no differences.
   *  g) diff: at least one difference has been noticed
   *  h) undefined: unknown
   */
  public static diff(
    contextA: Context,
    contextB: Context,
    variablesThatHaveToBeDifferent: ReadonlyArray<string> = []): IDiffResult {
    try {
      if (!contextA || !contextB) {
        return { type: ContextDiffType.null };
      }

      const idsA: ReadonlyArray<string> = contextA.ids;
      const idsB: ReadonlyArray<string> = contextB.ids;

      if (idsA.length !== idsB.length) {
        return { type: ContextDiffType.names };
      }
      for (let i = 0; i < idsA.length; i++) {
        const idA = idsA[i];
        if (!idsB.includes(idA)) {
          return { type: ContextDiffType.names };
        }
      }

      if (contextA.isStatic && contextB.isStatic) {
        return { type: ContextDiffType.static };
      }

      let numDiff = 0; // Count the random variables that are different
      const failedExplicitCheckIds: string[] = [];
      for (let i = 0; i < idsA.length; i++) {
        const id = idsA[i];
        const da: Variable = contextA.getDeclaration(id, true);
        const db: Variable = contextB.getDeclaration(id, true);

        // If a list of names are specified, we check
        // those variables without taking into account
        // whether or not they are randomized. Otherwise,
        // we count the number of differences among random
        // variables.
        const hasExplicitVariableCheck = variablesThatHaveToBeDifferent.includes(id);
        const shouldCheckVariable = hasExplicitVariableCheck || (da.apply.isRandom && db.apply.isRandom);
        if (!shouldCheckVariable) {
          continue;
        }

        const va = da.toLeaf();
        const vb = db.toLeaf();

        if (!va || !vb) {
          continue;
        }

        if (va.equalsTo(vb)) {
          if (hasExplicitVariableCheck) {
            failedExplicitCheckIds.push(id);
          }
        } else {
          numDiff++;
        }
      }

      if (failedExplicitCheckIds.length > 0) {
        return {
          failedExplicitCheckIds,
          type: ContextDiffType.failedExplicitVariableDiffCheck,
        };
      }
      if (numDiff === 0) {
        return { type: ContextDiffType.nodiff };
      }
      if (numDiff > 0) {
        return { type: ContextDiffType.diff };
      }
    } catch (e) {
      return { type: ContextDiffType.error };
    }
    return { type: ContextDiffType.undefined };
  }

  /**
   * Link a list of contexts by setting the parent property.
   *
   * Returns the leaf context.
   */
  public static chain(contexts: Context[]): Context {
    if (contexts.length > 0) {
      contexts[0].parent = null;
      for (let i: number = 1; i < contexts.length; i++) {
        contexts[i].parent = contexts[i - 1];
      }
      return contexts[contexts.length - 1];
    }
    return null;
  }

  /**
   * Unchain a leaf context and return the list
   * of contexts that were unchained.
   *
   * The top-level context is first on the
   * list, the leaf context is last.
   */
  public static unchain(leafContextArg: Context): Context[] {
    let leafContext: Context = leafContextArg;
    const o: Context[] = [];
    while (leafContext) {
      o.unshift(leafContext);
      const parent: Context = leafContext.parent;
      leafContext.parent = null;
      leafContext = parent;
    }
    return o;
  }

  /**
   *
   */
  public getSubstitution(id: string): Node {
    const declaration: Variable = this.getDeclaration(id, false);
    return declaration ? declaration.substitutionValue : null;
  }

  /**
   *
   */
  public getDeclaration(
    id: string,
    localOnly: boolean = true): Variable {
    let current: Context = this;
    while (current) {
      if (current.declarations.hasOwnProperty(id)) {
        return current.declarations[id];
      }
      if (localOnly) {
        return null;
      }
      current = current.parent;
    }
    return null;
  }

  /**
   * Returns -1 if not defined,
   * 0 if defined in this context,
   * 1 if defined in parent context,
   * ect...
   */
  public getDeclarationDepth(id: string): number {
    let current: Context = this;
    let depth: number = 0;
    while (current) {
      if (current.declarations.hasOwnProperty(id)) {
        return depth;
      }
      depth++;
      current = current.parent;
    }
    return -1;
  }

  /**
   * Return the list of local ids.
   */
  public get ids(): any[] {
    return XObject.getProps(this.declarations);
  }

  /**
   *
   */
  public get isStatic(): boolean {
    const ids: any[] = this.ids;
    for (let i: number = 0; i < ids.length; i++) {
      if (this.getDeclaration(ids[i], true).apply.isRandom) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   */
  public result(id: string): Node {
    if (this.isDefined(id)) {
      let current: Context = this;
      while (current) {
        if (current.getDeclaration(id) != null) {
          return current.getDeclaration(id).result.root.clone();
        }
        current = current.parent;
      }
    }
    return null;
  }

  public beginGeneration(): void {
    return undefined;
  }

  public addDeclarationFromModel(variable: IVariableModel, withSourceMap: boolean): boolean {
    if (variable.id == null) {
      return false;
    }

    if (this.isDefined(variable.id as string)) {
      this._hasDuplicateVariables = true;
    }

    const id: string = variable.id as string;
    const type: string = variable.type;
    let declared: Variable;

    if (type === 'debug' && !this.debug) {
      return false;
    }

    if (type === 'markup') {
      if (Context.validId(id)) {
        const markup: WMarkup
          = MarkupFactory.parse(
            variable,
            this.culture);

        declared
          = new Variable(
            id,
            this.expressionFromElement(markup),
            null,
            null,
            null,
            this.getEnvironment());

        this.declarations[id] = declared;
        if (variable.markup) {
          if (variable.markup.replacement === 0) {
            this.markupFactory.addBoundMarkup(id, markup, this);
          }
        }
      }

      return true;
    }
    if (type === 'texts') {
      if (Context.validId(id)) {
        const useList: boolean = variable.texts ? variable.texts.useList : false;
        const texts: ReadonlyArray<ITextModel> = variable.texts.values;
        const textList: ContentElement = this.textsFactory.addTexts(id, texts, useList, this);

        declared
          = new Variable(
            id,
            this.expressionFromElement(textList),
            null,
            null,
            null,
            this.getEnvironment());

        this.declarations[id] = declared;
      }

      return true;
    }

    let value: string;
    let piecewiseRange: string = null;

    if (variable.type == null
      || type === 'debug'
      || type === 'variant'
      || type === 'validation') {
      value = variable.value ? variable.value as string : '';
    } else if (type === 'function') {
      value = variable.piecewise ? ContextModelUtil.createPiecewiseExpression(variable.piecewise) : '';
      piecewiseRange = variable.piecewise ? variable.piecewise.range : null;
    } else if (type === 'matrix') {
      value = variable.matrix ? ContextModelUtil.createMatrixExpression(variable.matrix) : '';
    } else if (type === 'table') {
      value = variable.table ? ContextModelUtil.createTableExpression(variable.table) : '';
    }

    if (type === 'variant') {
      const variants = this.culture.configuration.variants;
      if (variants) {
        for (let i: number = 0; i < variants.length; i++) {
          const variant: IVariantModel = this.getVariant(variable, variants[i]);
          if (!variant) {
            continue;
          }
          value = variant.value as string;
          break;
        }
      }
    }

    if (value != null) {
      // Clear generation status attributes
      this.validationStatus[variable.id as string] = null;

      const format: FormatProvider
        = this.createFormatProvider(variable.format);

      const options: OptionsProvider
        = new OptionsProvider(
          variable.options
            ? variable.options.flags
            : OptionsProvider.DEFAULT_FLAGS,
          piecewiseRange);

      let loops: number = 0;

      do {
        const varEnv: Environment
          = new Environment(
            this.culture,
            this.prng,
            this.staticPrngCopy,
            this.radians,
            format,
            options,
            this.names,
            this.extensions);

        declared
          = new Variable(
            id,
            this.expressionFromString(value, varEnv, withSourceMap),
            format,
            this.createOutputProvider(variable.output),
            this.createFormProvider(variable.alt),
            varEnv);

        if (Context.validId(id)) {
          this.declarations[id] = declared;
        }

        if (variable.validation) {
          try {
            const r: ContentElement = varEnv.expressions.toContentElement(variable.validation as string, this);
            if (r) {
              if (r instanceof WBoolean) { // The postcondition checking produced a boolean value
                if (r.toBoolean() === false) { // Post condition failed, this is the only case that makes us try to regenerate again
                  loops++;
                  if (loops > this.variableValidationSkipThreshold) {
                    this.validationStatus[variable.id as string] = VariableValidationStatus.skipped;
                    break;
                  } else {
                    // Try another generation
                    continue;
                  }
                }
              }
            } else {
              this.validationStatus[variable.id as string] = VariableValidationStatus.error;
            }
            break; // postcondition checking is assumed to be successfull
          } catch (e) {
            // post condition expression failed to compile, we will skip postcondition checking
            this.validationStatus[variable.id as string] = VariableValidationStatus.error2;
            break;
          }
        }

        // LEAVE THIS BREAK IN PLACE, USE THE continue KEYWORD TO TRY ANOTHER GENERATION
        break;
      } while (true); // this loop allow us to try more than one generation if postcondition is not met

      return true;
    }

    return false;
  }

  /**
   *
   */
  private setGenerationError(error: any): void {
    this.generationError = error;
  }

  /**
   *
   */
  public endGeneration(
    randomSymbols: number): void {
    this.variablesFactory.randomizeSymbols(randomSymbols, this.initPrng);
    this.markupFactory.substitute(this);
    this.textsFactory.substitute(this);
  }

  /**
   *
   */
  private getVariant(variable: IVariableModel, name: string): IVariantModel {
    if (!variable.variants) {
      return null;
    }
    for (let i: number = 0; i < variable.variants.length; i++) {
      const variant: IVariantModel = variable.variants[i];
      if (variant.name === name && variant.value != null) {
        return variant;
      }
    }
    return null;
  }

  /**
   *
   */
  private createOutputProvider(output: IOutputModel): OutputProvider {
    return output
      ? new OutputProvider(output.out, output.verbose)
      : OutputProvider.getDefaultInstance();
  }

  /**
   *
   */
  private createFormProvider(form: IAltModel): FormProvider {
    return new FormProvider(
      this.culture,
      form ? form.numberForm : null,
      form ? form.polynomialForm : null);
  }

  /**
   *
   */
  private createFormatProvider(format: IFormatModel): FormatProvider {
    if (format) {
      return new FormatProvider(
        this.culture,
        format.numberFormat,
        format.minDecPlaces ? format.minDecPlaces.value : 0,
        format.maxDecPlaces ? format.maxDecPlaces.value : Number.MAX_SAFE_INTEGER,
        format.keepIntegers,
        format.encloseNegative,
        format.listFormat,
        format.listOpen,
        format.listClose,
        format.listSeparator,
        format.rationalFormat,
        format.mixed,
        format.bevelled,
        format.ratioSeparator,
        format.setEnclose,
        format.emptySetNotation,
        format.feminize,
        format.capitalize,
        format.linePrefix,
        format.noItalic);
    }

    return new FormatProvider(this.culture);
  }

  /**
   * Check if a variable name is defined, look until the toplevel context
   */
  public isDefined(id: string): boolean {
    return this.getDeclaration(id, false) != null;
  }

  /**
   * options 0 --> no options
   * options < 0 --> default options
   * options > 0 --> set options
   */
  public addDeclarationFromString(id: string, value: string, options: number = -1): boolean {
    if (!Context.validId(id)) {
      return false;
    }
    if (value == null) {
      return false;
    }

    const varEnv: Environment
      = options < 0
        ? this.getEnvironment()
        : this.getEnvironmentWithOptions(options);

    this.declarations[id]
      = new Variable(
        id,
        this.expressionFromString(value, varEnv),
        null, // Default output configuration
        null,
        null,
        varEnv);

    return true;
  }

  /**
   *
   */
  public addDeclarationFromElement(id: string, value: ContentElement): boolean {
    if (!Context.validId(id)) {
      return false;
    }
    if (!value) {
      return false;
    }

    this.declarations[id]
      = new Variable(
        id,
        this.expressionFromElement(value),
        null,
        null,
        null,
        this.getEnvironment());

    return true;
  }

  /**
   *
   */
  private expressionFromElement(element: ContentElement): Expression {
    return new Expression(new Node(element));
  }

  /**
   *
   */
  private expressionFromString(
    value: string,
    env: Environment,
    withSourceMap: boolean = false): Expression {
    return new StringImporter(
      String(value),
      this,
      env,
      withSourceMap).expr;
  }

  /**
   *
   */
  public clearDeclaration(id: string): void {
    delete this.declarations[id];
  }

  /**
   * Doit avoir une longueur minimale d'un caractère
   * Doit être composé de lettres minuscules et majuscules
   * A et a sont deux variables distinctes
   */
  public static validId(id: string): boolean {
    if (id == null) {
      return false;
    }
    if (id.length === 0) {
      return false;
    }
    const rx: RegExp = new RegExp('^[A-Za-z]+$');
    return rx.test(id);
  }
}

export enum ContextDiffType {
  null = 'null',
  names = 'names',
  static = 'static',
  error = 'error',
  nodiff = 'nodiff',
  diff = 'diff',
  undefined = 'undefined',
  failedExplicitVariableDiffCheck = 'failedExplicitVariableDiffCheck',
}

export type IDiffResult = {
  type: Exclude<ContextDiffType, ContextDiffType.failedExplicitVariableDiffCheck>;
} | {
  type: ContextDiffType.failedExplicitVariableDiffCheck;
  failedExplicitCheckIds: ReadonlyArray<string>;
};

export enum VariableValidationStatus {
  skipped = 'skipped',
  error = 'error',
  error2 = 'error2',
}
