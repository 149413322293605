import React from 'react';
import { PopoverWithTip } from '../../../../utils/Popups/PopoverWithTip/PopoverWithTip';
import AddIcon from '../../../../../assets/add.svg';
import { Button } from '../../../Button/Button';
import { ImageForm } from './ImageForm';

interface IAddImagePopoverProps {
  anchorEl: HTMLElement;
  open: boolean;
  srcInputLabel: string;
  altInputLabel: string;
  widthInputLabel: string;
  heightInputLabel: string;
  handleClose: () => void;
  onAddImage: (src: string, altText: string, width: number, height: number) => void;
}

interface IAddImagePopoverState {
  src: string;
  altText: string;
  width: number;
  height: number;
}

export class AddImagePopover extends React.Component<IAddImagePopoverProps, IAddImagePopoverState> {
  constructor(props: IAddImagePopoverProps) {
    super(props);
    this.state = {
      src: '',
      altText: '',
      width: 32,
      height: 32,
    };
  }

  public render(): React.ReactNode {
    const {
      anchorEl,
      open,
      handleClose,
      srcInputLabel,
      altInputLabel,
      widthInputLabel,
      heightInputLabel,
    } = this.props;
    const {
      src,
      altText,
      width,
      height,
    } = this.state;
    return (
      <PopoverWithTip
        tipAlignment="bottom-right"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <ImageForm
          srcInputLabel={srcInputLabel}
          altInputLabel={altInputLabel}
          widthInputLabel={widthInputLabel}
          heightInputLabel={heightInputLabel}
          src={src}
          altText={altText}
          width={width}
          height={height}
          onChangeSrc={this.onChangeSrc}
          onChangeAltText={this.onChangeAltText}
          onChangeWidth={this.onChangeWidth}
          onChangeHeight={this.onChangeHeight}
        >
          <Button
            onClick={this.onAddImage}
            icon
          >
            <AddIcon />
          </Button>
        </ImageForm>
      </PopoverWithTip>
    );
  }

  private closePopup = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  private onAddImage = () => {
    const { src, altText, width, height } = this.state;
    this.props.onAddImage(src, altText, width, height);
    this.closePopup();
  };

  private onChangeSrc = (src: string) => {
    this.setState({
      src,
    });
  };

  private onChangeAltText = (altText: string) => {
    this.setState({
      altText,
    });
  };

  private onChangeWidth = (width: number) => {
    this.setState({
      width,
    });
  };

  private onChangeHeight = (height: number) => {
    this.setState({
      height,
    });
  };
}
