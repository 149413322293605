import { IDictionary } from '../../js/utils/IDictionary';

import { Match } from '../core/Match';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { TokenElement } from '../elements/abstract/TokenElement';
import { WFactors } from '../elements/tokens/WFactors';
import { Variables } from '../expr/Variables';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { CPolynomial } from './CPolynomial';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { InvalidExpressionError } from '../expr/InvalidExpressionError';

/**
 *
 */
export class CFactors extends BaseCorrector {
  /**
   *
   */
  public parse(valueArg: string): Node {
    try {
      const value = this.translateInput(valueArg);
      const factors: string[] = [];
      let factor: string = '';
      let i: number;

      for (i = 0; i < value.length; i++) {
        const c: string = value.charAt(i);
        if (c === '(' || c === ')') {
          if (factor) {
            factors.push(factor);
          }
          factor = '';
        } else {
          factor += c;
        }
      }
      if (factor) {
        factors.push(factor);
      }
      const o: TokenElement[] = [];

      for (i = 0; i < factors.length; i++) {
        factor = factors[i];
        const token: TokenElement = this.env.expressions.toTokenElement(factor);
        if (!token) {
          return null;
        }
        o.push(token);
      }

      const node: Node = new Node(new WFactors(o));
      node.userData = 'Factors({(' + factors.join('), (') + ')})';
      return node;
    } catch (e) {
      return null;
    }
  }

  public correct(
    valueArg: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    let value = valueArg;

    this.checkDecimalSeparator(value);

    value = this.translateInput(value);

    let s: ContentElement;
    try {
      s = this.env.expressions.toContentElement(value);
    } catch (e) {
      if (e instanceof InvalidExpressionError) {
        this.raiseError(CommonError.INVALID_EXPRESSION);
      } else {
        throw e;
      }
    }
    if (s == null) {
      return false;
    }

    const factors: TokenElement[] = (target as WFactors).toFactors();
    const u: TokenElement[] = factors.concat();
    while (u.length > 1) {
      u[0] = this.env.expressions.multiply(u[0], u.pop());
    }

    if (u[0].equalsTo(s)) {
      if (this.options.acceptEquivalent) {
        return true;
      }

      // 1. Test acceptable forms

      // ^([^(]+)(\([^)]+\))([^(]+)(\([^)]+\))$
      // 2. Try to rewrite form x(y+1)+1(y+1) --> (x+1)(y+1)
      let r: RegExp = new RegExp('^([^(]+)(\\([^)]+\\))([^(]+)(\\([^)]+\\))$');
      const z: Match = Match.tryParse(r.exec(value));
      if (z) {
        if (String(z.groups[2]) === String(z.groups[4])) {
          const temp: string
            = XString.substitute('({0}{1}){2}', z.groups[1], z.groups[3], z.groups[2]);
          if (u[0].equalsTo(this.env.expressions.toContentElement(temp))) {
            value = temp;
          }
        }
      }

      value = value.replace(/^[-−]\(/, '-1(');

      // ^([^\(\)]*)(\([^\)]+\))+
      r = new RegExp('^([^\\(\\)]*)(\\([^\\)]+\\))+$');
      if (r.test(value)) {
        // [^\(\)]+
        s = this.env.expressions.toContentElement(String(new RegExp('[^\\(\\)]+').exec(value)[0]));

        // 1. Check that one of the factors match exactly, also check negative value
        const args: IDictionary = {};
        args['a'] = s;
        const s2: ContentElement = this.env.expressions.toContentElement('-a', new Variables(args, this.env));

        for (let i: number = 0; i < factors.length; i++) {
          const factor: TokenElement = factors[i];
          if (s.equalsTo(factor)) {
            return true;
          }
          if (s2.equalsTo(factor)) {
            return true;
          }
        }

        // 2. No factor match, that means the factorisation is different
        this.raiseError(CommonError.PARTIAL_FACTORIZATION);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private translateInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '^$1');
    }

    value = value.replace(/ /g, '');
    value = value.toLowerCase();
    value = value.split(this.env.culture.numberFormatter.decimalSeparator).join('.');
    value = value.replace(new RegExp('\\)(\\d+)', 'g'), ')^$1'); // (x+1)2 --> (x+1)^2

    return this.normalizeInput(value);
  }

  private normalizeInput(valueArg: string): string {
    let value = valueArg;
    // (x+1)^2 --> (x+1)(x+1)
    const r1: RegExp = new RegExp('^(\\([^)]+\\))\\^2$');
    const o1: Match = Match.tryParse(r1.exec(value));
    if (o1) {
      value = String(o1.groups[1]) + String(o1.groups[1]); // (x+1)(x+1)
    }

    // 1(x+1)^2 --> 1(x+1)(x+1)
    const r2: RegExp = new RegExp('^(\\d+)(\\([^)]+\\))\\^2$');
    const o2: Match = Match.tryParse(r2.exec(value));
    if (o2) {
      value = String(o2.groups[1]) + String(o2.groups[2]) + String(o2.groups[2]); // 1(x+1)(x+1)
    }

    return value;
  }

  public get inputCapabilities(): InputCapabilities {
    const i: InputCapabilities = super.inputWithSymbols('−+()');
    i.factors = true;
    i.superscript = true;
    return i;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.FACTORS;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const factors: TokenElement[] = (target as WFactors).getDisplayElements();

    for (let i: number = 0; i < factors.length; i++) {
      const factor: TokenElement = factors[i];
      const enclose: boolean = i > 0 || !WFactors.isSimpleElement(factor);

      if (enclose) {
        w.writeInfixOperator('(');
      }
      CPolynomial.writePolynomialOrSymbolOrReal(w, factor);
      if (enclose) {
        w.writeInfixOperator(')');
      }
    }
  }
}
