import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { TokensList } from '../../elements/factories/TokensList';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseSetFormatter } from '../../elements/formats/BaseSetFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WList } from '../../elements/tokens/WList';
import { WPoint } from '../../elements/tokens/WPoint';
import { WListOfList } from '../../elements/tokens/WListOfList';
import { WListOfListOfString } from '../../elements/tokens/WListOfListOfString';
import { WString } from '../../elements/tokens/WString';
import { WListOfString } from '../../elements/tokens/WListOfString';

export class WFiniteSet extends SetElement {
  private readonly elements: TokenElement[];

  private readonly _formatter: BaseSetFormatter;

  constructor(elements: TokenElement[], formatter: BaseSetFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this.elements = elements;
    this._formatter = formatter;
  }

  public get formatter(): BaseSetFormatter {
    return this._formatter;
  }

  public get cardinal(): number {
    return this.elements.length;
  }

  public normalize(enableMultisets: boolean, disableSetsAutoSort: boolean): WFiniteSet {
    let elements: TokenElement[] = this.elements.concat();

    if (!enableMultisets) {
      elements = new TokensList(elements).getDistincts().getItems();
    }

    if (!disableSetsAutoSort) {
      elements = elements.sort(TokenElement.compare);
    }

    return new WFiniteSet(elements, this.formatter);
  }

  public getElementAt(index: number): TokenElement {
    return this.elements[index];
  }

  /**
   * Returns true if the set has at
   * least one element and all elements
   * are numbers.
   */
  public get isNumeric(): boolean {
    for (const element of this.elements) {
      if (!(element instanceof RealElement)) {
        return false;
      }
    }
    return this.cardinal > 0;
  }

  public get isStrings(): boolean {
    for (const element of this.elements) {
      if (!(element instanceof WString)) {
        return false;
      }
    }
    return this.cardinal > 0;
  }

  public get isSetOfPoints(): boolean {
    for (const element of this.elements) {
      if (!(element instanceof WPoint)) {
        return false;
      }
    }
    return this.cardinal > 0;
  }

  public getFormat(): AbstractFormatter {
    return this.formatter;
  }

  public applyFormat(formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseSetFormatter) {
      return new WFiniteSet(this.elements, formatter);
    }
    return this;
  }

  public get hasNumericElements(): boolean {
    for (const element of this.elements) {
      if (element instanceof WFiniteSet) {
        if (element.hasNumericElements) {
          return true;
        }
      }
      if (element instanceof RealElement || element instanceof WList) {
        return true;
      }
    }
    return false;
  }

  public get hasStringElements(): boolean {
    for (const element of this.elements) {
      if (element instanceof WFiniteSet) {
        if (element.hasStringElements) {
          return true;
        }
      }
      if (element instanceof WString || element instanceof WListOfString) {
        return true;
      }
    }
    return false;
  }

  public narrow(): ContentElement {
    if (this.isNumeric) {
      return new WList(this.elements.slice(), this.formatter.culture.listFormatter);
    }
    return null;
  }

  public contains(value: TokenElement): boolean {
    for (const item of this.elements) {
      if (value.equalsTo(item)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Produce the intersection of the two sets, if the resulting set length
   * is the same as the two sets then it means that all element of a are in b
   * and all elements of b are in a.
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WFiniteSet) {
      const intersection = new WFiniteSet(this.toTokens().getIntersection(value.toTokens()).getItems(), this.formatter);
      return intersection.cardinal === this.cardinal && intersection.cardinal === value.cardinal;
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WFiniteSet) {
      return this.elements.length === value.elements.length
        && this.elements.every((element, index) => element.strictlyEqualsTo(value.elements[index]));
    }
    return false;
  }

  public toSingleton(): RealElement {
    if (this.cardinal === 1 && this.elements[0] instanceof RealElement) {
      return this.elements[0] as RealElement;
    }
    return null;
  }

  public toElements(): TokenElement[] {
    return this.elements;
  }

  public toList(): WList {
    let elements: ContentElement[] = [];
    for (let i: number = 0; i < this.cardinal; i++) {
      const element = this.getElementAt(i);
      if (element instanceof RealElement) {
        elements.push(element);
      } else if (element instanceof WList) {
        elements = elements.concat(element.items);
      } else if (element instanceof WPoint) {
        elements.push(element.x, element.y);
      }
    }
    return new WList(elements, this.formatter.culture.listFormatter);
  }

  public toListOfString(): WListOfString {
    let elements: ContentElement[] = [];
    for (let i: number = 0; i < this.cardinal; i++) {
      const element = this.getElementAt(i);
      if (element instanceof WString) {
        elements.push(element);
      } else if (element instanceof WListOfString) {
        elements = elements.concat(element.items);
      }
    }
    return new WListOfString(elements, this.formatter.culture.listFormatter);
  }

  public toListOfList(): WListOfList {
    const elements: ContentElement[] = [];
    for (let i: number = 0; i < this.cardinal; i++) {
      const element = this.getElementAt(i);
      if (element instanceof WFiniteSet) {
        if (element.isNumeric) {
          elements.push(element.toList());
        } else {
          elements.push(element.toListOfList());
        }
      } else {
        elements.push(element);
      }
    }
    return new WListOfList(elements, this.formatter.culture.listFormatter);
  }

  public toListOfListOfString(): WListOfListOfString {
    const elements: ContentElement[] = [];
    for (let i: number = 0; i < this.cardinal; i++) {
      const element = this.getElementAt(i);
      if (element instanceof WFiniteSet) {
        if (element.isStrings) {
          elements.push(element.toListOfString());
        } else {
          elements.push(element.toListOfListOfString());
        }
      } else {
        elements.push(element);
      }
    }
    return new WListOfListOfString(elements, this.formatter.culture.listFormatter);
  }

  public toTokens(): TokensList {
    return new TokensList(this.elements);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this.formatter.writeTo(exporter, this.elements);
    }
    return true;
  }

  public toText(strict: boolean): string {
    return this.formatter.toText(this.elements, strict);
  }

  public static parseElement(element: ContentElement): WFiniteSet {
    return element instanceof WFiniteSet ? element : null;
  }

  public getType(): string {
    return 'finiteSet';
  }
}
