import { Attributes } from '../../elements/abstract/Attributes';
import { SetElement } from '../../elements/abstract/SetElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { ContentElement } from '../../elements/abstract/ContentElement';

/**
 * B∖A
 */
export class WComplement extends SetElement {
  private _B: SetElement;

  public get B(): SetElement {
    return this._B;
  }

  private _A: SetElement;

  public get A(): SetElement {
    return this._A;
  }

  /**
   *
   */
  constructor(
    B: SetElement,
    A: SetElement) {
    super();
    this._B = B;
    this._A = A;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WComplement) {
      return this.B.equalsTo(value.B as WComplement)
        && this.A.equalsTo(value.A as WComplement);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WComplement) {
      return this.B.strictlyEqualsTo(value.B as WComplement)
        && this.A.strictlyEqualsTo(value.A as WComplement);
    }
    return false;
  }

  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.beginRow();
      this.B.writeTo(exporter);
      exporter.writer.appendOperator('∖');
      this.A.writeTo(exporter);
      exporter.writer.endRow();
    }
    return true;
  }

  public getType(): string {
    return 'complement';
  }
}
