import { ContentElement } from '../../elements/abstract/ContentElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';

/**
 *
 */
export class WNumberSet extends SetElement {
  private _set: string;

  private formatter: BaseIntervalFormatter;

  constructor(_set: string, formatter: BaseIntervalFormatter) {
    super();
    this._set = _set;
    this.formatter = formatter;
  }

  public toString(): string {
    return this._set;
  }

  public toSymbol(): string {
    return this._set;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WNumberSet) {
      return this._set === value._set;
    }
    return false;
  }

  public narrow(): ContentElement {
    if (this._set === 'ℝ') {
      return this.formatter.culture.intervalsFactory.createReals();
    }
    return null;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writeIdentifier(this._set);
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'numberSet';
  }
}
