import React, { Suspense, lazy, useEffect, useCallback } from 'react';
import {
  Route,
  RouteComponentProps,
  withRouter,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PageNotFound, Spinner, ThemeDefault } from '@scolab/publisher-ui-kit';
import { ErrorLogger, DatadogRUM, PublisherApiAuth } from '@scolab/common-editor';
import { Dependency, files, meta } from '@scolab/vendor';
import {
  ProtectedComponent,
  LoginApp,
} from '@scolab/login';
import { ThemeProvider } from 'styled-components';
import { linkTo, PageEditorRouteGroup, Routes } from '../config/routes';
import { GlobalStyle } from './PublisherApp.styles';
import ErrorBoundary from './ErrorBoundary';

const WorkbenchModule = lazy(() => import('../modules/WorkbenchModule'));
const PageEditorModule = lazy(() => import('../modules/PageEditorModule'));
const ActivityPreviewModule = lazy(() => import('../modules/ActivityPreviewModule'));
const VariableEditorModule = lazy(() => import('../modules/VariableEditorModule'));

const DefaultTheme = new ThemeDefault();

const workbench = () => (
  <ProtectedComponent>
    <Suspense fallback={<Spinner />}>
      <WorkbenchModule />
    </Suspense>
  </ProtectedComponent>
);

const pageEditor = () => (
  <ProtectedComponent>
    <Suspense fallback={<Spinner />}>
      <PageEditorModule />
    </Suspense>
  </ProtectedComponent>
);

const activityPreview = () => (
  <ProtectedComponent>
    <Suspense fallback={<Spinner />}>
      <ActivityPreviewModule />
    </Suspense>
  </ProtectedComponent>
);

const variableEditor = () => (
  <Suspense fallback={<Spinner />}>
    <VariableEditorModule />
  </Suspense>
);

export const PublisherAppComponent: React.FC<RouteComponentProps> = ({ location, history }) => {
  const handleUnauthorizedRequest = useCallback((): void => {
    const login = linkTo(Routes.Login);

    // Prevent infinite redirection loop in case
    // the login page is also generating 401 responses.
    if (!window.location.pathname.includes(login)) {
      // Forward using pure javascript because of possible competing
      // React Router instances with their own prefixes.
      window.location.href = history.createHref({
        pathname: `${login}/`,
        search: `Cause=unauthorizedRequestCallback&ReturnUrl=${encodeURIComponent(window.location.toString())}`,
      });
    }
  }, [history]);

  useEffect(() => {
    ErrorLogger.initialize('publisher-app');
    DatadogRUM.initialize('publisher-app', ScolabConfiguration.DATADOG_RUM_ENV.split(','), ScolabConfiguration.GIT_SHA);
    PublisherApiAuth.addUnauthorizedRequestEventListener(handleUnauthorizedRequest);

    // Load required dependencies
    const loadDependencies = async () => {
      await Promise.all([
        meta.register(Dependency.workbook),
        files.register(Dependency.workbook),
      ]);
    };

    loadDependencies();

    // Clean up listener on unmount
    return () => {
      PublisherApiAuth.removeUnauthorizedRequestEventListener(handleUnauthorizedRequest);
    };
  }, [handleUnauthorizedRequest]);

  return (
    <HelmetProvider context={{}}>
      <Helmet>
        <title>
          Publisher
        </title>
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={DefaultTheme.theme}>
        <ErrorBoundary>
          <Switch>
            <Route exact path={Routes.Home}>
              <Redirect to={{ pathname: linkTo(Routes.Workbench), search: location.search }} />
            </Route>
            <Route
              path={Routes.Login}
              component={LoginApp}
            />
            <Route
              path={Routes.Workbench}
              component={workbench}
            />
            <Route
              path={PageEditorRouteGroup}
              component={pageEditor}
            />
            <Route
              path={Routes.ActivityPreview}
              component={activityPreview}
            />
            <Route
              path={Routes.VariableEditor}
              component={variableEditor}
            />
            <Route component={PageNotFound} />
          </Switch>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default withRouter(PublisherAppComponent);
