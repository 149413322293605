import React from 'react';
import { PopoverWithTip } from '../../../../utils/Popups/PopoverWithTip/PopoverWithTip';
import { IImageElement } from '../../models/elements';
import { updateElement } from '../../utils/updateElement';
import { ICustomEditor } from '../../models/editor';
import { ImageForm } from '../../controls/Image/ImageForm';

export interface IImageElementPopoverProps {
  anchorEl: HTMLElement;
  open: boolean;
  handleClose: () => void;
  element: IImageElement;
  editor: ICustomEditor;
  srcInputLabel: string;
  altInputLabel: string;
  widthInputLabel: string;
  heightInputLabel: string;
}

export class ImageElementPopover extends React.Component<IImageElementPopoverProps> {
  constructor(props: IImageElementPopoverProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const {
      anchorEl,
      open,
      handleClose,
      element,
      srcInputLabel,
      altInputLabel,
      widthInputLabel,
      heightInputLabel,
    } = this.props;
    const { src, altText, height, width } = element;
    return (
      <PopoverWithTip
        tipAlignment="bottom-center"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <ImageForm
          srcInputLabel={srcInputLabel}
          altInputLabel={altInputLabel}
          widthInputLabel={widthInputLabel}
          heightInputLabel={heightInputLabel}
          src={src}
          altText={altText}
          width={width}
          height={height}
          onChangeSrc={this.onChangeSrc}
          onChangeAltText={this.onChangeAltText}
          onChangeWidth={this.onChangeWidth}
          onChangeHeight={this.onChangeHeight}
        />
      </PopoverWithTip>
    );
  }

  private onChangeSrc = (src: string) => {
    const newNodeValue: Partial<IImageElement> = {
      src,
    };
    this.updateNode(newNodeValue);
  };

  private onChangeAltText = (altText: string) => {
    const newNodeValue: Partial<IImageElement> = {
      altText,
    };
    this.updateNode(newNodeValue);
  };

  private onChangeWidth = (width: number) => {
    const newNodeValue: Partial<IImageElement> = {
      width,
    };
    this.updateNode(newNodeValue);
  };

  private onChangeHeight = (height: number) => {
    const newNodeValue: Partial<IImageElement> = {
      height,
    };
    this.updateNode(newNodeValue);
  };

  private updateNode = (newNodeValue: Partial<IImageElement>) => {
    const { element, editor } = this.props;
    updateElement(editor, element, newNodeValue);
  };
}
