import React from 'react';
import { Button, PlusIcon } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';
import {
  ContentTagCreationModal,
} from './ContentTagCreationModal';
import { inject } from '../../../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../../../mediators/ContentTagsMediator';

interface ITagCreationButtonProps {
  initialNameFr: string;
}

interface ITagCreationButtonState {
  isContentTagCreationModalOpen: boolean;
}

export class TagCreationButton extends React.Component<ITagCreationButtonProps, ITagCreationButtonState> {
  @inject(ContentTagsMediator)
  private accessor contentTagsMediator: ContentTagsMediator;

  private createButtonRef: React.RefObject<HTMLDivElement>;

  constructor(props: ITagCreationButtonProps) {
    super(props);
    this.state = {
      isContentTagCreationModalOpen: false,
    };
    this.createButtonRef = React.createRef<HTMLDivElement>();
  }

  public render(): React.ReactNode {
    const {
      initialNameFr,
    } = this.props;
    const {
      isContentTagCreationModalOpen,
    } = this.state;
    return (
      <>
        <Button
          primary
          onClick={this.onOpenContentTagCreationModal}
          ref={this.createButtonRef}
        >
          <PlusIcon />
          <FormattedMessage id="contentTags.tag" />
        </Button>
        <ContentTagCreationModal
          isOpen={isContentTagCreationModalOpen}
          existingNames={this.allContentTagsNames}
          initialNameFr={initialNameFr}
          onClose={this.onCloseContentTagCreationModal}
          onSave={this.onCreateContentTag}
          anchorEl={this.createButtonRef.current}
        />
      </>
    );
  }

  private onCloseContentTagCreationModal = () => {
    this.setState({
      isContentTagCreationModalOpen: false,
    });
  };

  private onOpenContentTagCreationModal = () => {
    this.setState({
      isContentTagCreationModalOpen: true,
    });
  };

  private onCreateContentTag = (subjectId: number, nameFr: string, description: string) => {
    this.contentTagsMediator.createContentTag(subjectId, nameFr, description);
    this.onCloseContentTagCreationModal();
  };

  @computed
  private get allContentTagsNames(): ReadonlyArray<string> {
    return this.contentTagsMediator.contentTags.map(contentTag => contentTag.name.fr);
  }
}
