import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { BaseTimeFormatter } from '../../elements/formats/BaseTimeFormatter';
import { SemicolonTimeOfDayFormatter } from '../../elements/formats/time/SemicolonTimeOfDayFormatter';
import { ShortTimeOfDayFormatter } from '../../elements/formats/time/ShortTimeOfDayFormatter';
import { WString } from '../../elements/tokens/WString';
import { WTimeOfDay } from '../../elements/tokens/WTimeOfDay';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CultureInfo } from '../../localization/CultureInfo';
import { DurationFormatter } from '../../elements/formats/time/DurationFormatter';

/**
 *
 */
export class FormatTime extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getTimeOfDay(0) && args.getString(1)) {
      return this.applyFormat(args.getTimeOfDay(0), args.getString(1), args.env.culture);
    }
    return null;
  }

  /**
   * format: short|short12|short24|semicolon|semicolon12|semicolon24|time|time12|time24|duration
   */
  private applyFormat(
    value: WTimeOfDay,
    format: WString,
    culture: CultureInfo): WTimeOfDay {
    const formatter: BaseTimeFormatter = this.getFormatter(format.toString(), culture);

    if (!formatter) {
      throw new MathError('Invalid time format, accepted values are (short|short12|short24|semicolon|semicolon12|semicolon24|time|time12|time24|duration)');
    }

    return value.applyFormat(formatter) as WTimeOfDay;
  }

  /**
   *
   */
  private getFormatter(format: string, culture: CultureInfo): BaseTimeFormatter {
    if (format === 'short') {
      return new ShortTimeOfDayFormatter(culture, culture.configuration.twelveHoursClock);
    }
    if (format === 'short12') {
      return new ShortTimeOfDayFormatter(culture, true);
    }
    if (format === 'short24') {
      return new ShortTimeOfDayFormatter(culture, false);
    }
    if (format === 'semicolon') {
      return new SemicolonTimeOfDayFormatter(culture, culture.configuration.twelveHoursClock);
    }
    if (format === 'semicolon12') {
      return new SemicolonTimeOfDayFormatter(culture, true);
    }
    if (format === 'semicolon24') {
      return new SemicolonTimeOfDayFormatter(culture, false);
    }
    if (format === 'time') {
      return culture.formats.timeFormatImpl;
    }
    if (format === 'time12') {
      if (culture.configuration.preferSemicolonTimeFormat) {
        return this.getFormatter('semicolon12', culture);
      }
      return this.getFormatter('short12', culture);
    }

    if (format === 'time24') {
      if (culture.configuration.preferSemicolonTimeFormat) {
        return this.getFormatter('semicolon24', culture);
      }
      return this.getFormatter('short24', culture);
    }
    if (format === 'duration') {
      return new DurationFormatter(culture);
    }
    return null;
  }
}
