import React from 'react';
import styled from 'styled-components';
import { TopLabeledInput } from '../../../TopLabeledInput/TopLabeledInput';

interface IAddImagePopoverProps {
  srcInputLabel: string;
  altInputLabel: string;
  widthInputLabel: string;
  heightInputLabel: string;
  src: string;
  altText: string;
  width: number;
  height: number;
  onChangeSrc: (src: string) => void;
  onChangeAltText: (altText: string) => void;
  onChangeWidth: (width: number) => void;
  onChangeHeight: (height: number) => void;
}

export class ImageForm extends React.Component<IAddImagePopoverProps> {
  public render(): React.ReactNode {
    const {
      srcInputLabel,
      altInputLabel,
      widthInputLabel,
      heightInputLabel,
      src,
      altText,
      width,
      height,
      children,
    } = this.props;
    return (
      <Container>
        <Column>
          <LongPopupInput
            value={src}
            label={srcInputLabel}
            onChange={this.onChangeSrc}
            onPaste={this.stopPatePropagation}
          />
          <LongPopupInput
            value={altText}
            label={altInputLabel}
            onChange={this.onChangeAltText}
            onPaste={this.stopPatePropagation}
          />
        </Column>
        <Column>
          <ShortPopupInput
            value={width}
            label={widthInputLabel}
            onChange={this.onChangeWidth}
            onPaste={this.stopPatePropagation}
          />
          <ShortPopupInput
            value={height}
            label={heightInputLabel}
            onChange={this.onChangeHeight}
            onPaste={this.stopPatePropagation}
          />
        </Column>
        {children}
      </Container>
    );
  }

  private onChangeSrc = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeSrc(event.target.value);
  };

  private onChangeAltText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeAltText(event.target.value);
  };

  private onChangeWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numericValue = Number(event.target.value);
    if (isNaN(numericValue)) {
      numericValue = 0;
    }
    this.props.onChangeWidth(numericValue);
  };

  private onChangeHeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numericValue = Number(event.target.value);
    if (isNaN(numericValue)) {
      numericValue = 0;
    }
    this.props.onChangeHeight(numericValue);
  };

  private stopPatePropagation = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };
}

const LongPopupInput = styled(TopLabeledInput)`
  width: 200px;
`;

const ShortPopupInput = styled(TopLabeledInput)`
  width: 80px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
