import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IFormats } from '../../elements/formats/IFormats';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WNumber } from '../../elements/tokens/WNumber';
import { WRadical } from '../../elements/tokens/WRadical';
import { WRatio } from '../../elements/tokens/WRatio';
import { WRational } from '../../elements/tokens/WRational';
import { WRange } from '../../elements/tokens/WRange';

/**
 *
 */
export class Formatter {
  private format: IFormats;

  /**
   *
   */
  constructor(format: IFormats) {
    this.format = format;
  }

  /**
   *
   */
  public applyFormat(valueArg: ContentElement): ContentElement {
    let value = valueArg;

    if (this.format == null) {
      return value;
    }

    if (value instanceof WNumber && this.format.numberFormatImpl) {
      return value.applyFormat(this.format.numberFormatImpl);
    }

    if (value instanceof WList) {
      if (this.format.listNFormatImpl) {
        value = value.applyFormat(this.format.listNFormatImpl);
      }

      if (this.format.numberFormatImpl || this.format.rationalFormatImpl) {
        value = WList.createFromReals(this.applyFormatReals((value as WList).toReals()), (value as WList).formatter2);
      }

      return value;
    }
    if (value instanceof ListElement) {
      if (this.format.listFormatImpl) {
        return value.applyFormat(this.format.listFormatImpl);
      }
    }

    if (value instanceof WMatrix) {
      if (this.format.matrixFormatImpl) {
        value = value.applyFormat(this.format.matrixFormatImpl);
      }

      if (this.format.numberFormatImpl || this.format.rationalFormatImpl) {
        value = new WMatrix(this.applyFormatReals((value as WMatrix).values), (value as WMatrix).columns, (value as WMatrix).formatter);
      }

      return value;
    }

    if (value instanceof WFiniteSet) {
      if (this.format.setFormatImpl) {
        value = value.applyFormat(this.format.setFormatImpl);
      }

      if (this.format.numberFormatImpl || this.format.rationalFormatImpl) {
        if ((value as WFiniteSet).hasNumericElements) {
          value = new WFiniteSet(this.applyFormatTokens((value as WFiniteSet).toElements()), (value as WFiniteSet).formatter);
        }
      }

      return value;
    }

    if (value instanceof WRational && this.format.rationalFormatImpl) {
      return value.applyFormat(this.format.rationalFormatImpl);
    }

    if (value instanceof WRatio && this.format.ratioFormatImpl) {
      return value.applyFormat(this.format.ratioFormatImpl);
    }

    if (value instanceof WRadical && this.format.radicalFormatImpl) {
      return value.applyFormat(this.format.radicalFormatImpl);
    }

    if (value instanceof WRange && this.format.numberFormatImpl) {
      return value.applyFormat(this.format.numberFormatImpl);
    }

    return value;
  }

  /**
   *
   */
  private applyFormatTokens(
    elements: TokenElement[]): TokenElement[] {
    return elements.map(this.applyFormatAsTokenElement, this);
  }

  /**
   *
   */
  private applyFormatAsTokenElement(element: TokenElement, ..._: any[]): TokenElement {
    return this.applyFormat(element) as TokenElement;
  }

  /**
   *
   */
  private applyFormatReals(
    values: RealElement[]): RealElement[] {
    return values.map(this.getRealElement, this);
  }

  /**
   *
   */
  private getRealElement(value: RealElement, ..._: any[]): RealElement {
    if (value instanceof WRational && this.format.rationalFormatImpl) {
      return value.applyFormat(this.format.rationalFormatImpl) as RealElement;
    }
    if (this.format.numberFormatImpl) {
      return new WNumber(value.toNumber(), 1, false, this.format.numberFormatImpl);
    }
    return value;
  }
}
