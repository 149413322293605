import { Match } from '../core/Match';
import { XSort } from '../core/XSort';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { ListElement } from '../elements/abstract/ListElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { WList } from '../elements/tokens/WList';
import { WListOfString } from '../elements/tokens/WListOfString';
import { WNumber } from '../elements/tokens/WNumber';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { COptions } from './COptions';
import { CString } from './CString';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CListStr extends BaseCorrector {
  private cs: CString;

  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.cs = new CString();
    super.configureOther(this.cs);
  }

  public parse(value: string): Node {
    const value2: string[] = this.parseItems(this.sanitizeInput(value));
    const list: ListElement = this.env.culture.listFactory.createFromStrings(value2);
    if (list) {
      const node: Node = new Node(list);
      node.userData = `Listify("${value2.join('", "')}")`;
      return node;
    }
    return null;
  }

  private stringify(value: ContentElement): string[] {
    if (value instanceof WListOfString) {
      return value.toStrings();
    }

    if (value instanceof WList) {
      const list: WList = value;
      const o: string[] = [];
      for (let i: number = 0; i < list.count; i++) {
        const n: RealElement = list.getTypedItemAt(i);
        if (n instanceof WNumber) {
          o.push((n).toText(true));
        } else {
          o.push(this.env.culture.formatNumber(n.toNumber()));
        }
      }
      return o;
    }

    return null;
  }

  /**
   *
   */
  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    let i: number = 0;

    let value2: string[] = this.parseItems(this.sanitizeInput(value));
    let target2: string[] = this.stringify(target);

    if (value2.length !== target2.length) {
      return false;
    }

    for (i; i < value2.length; i++) {
      value2[i] = this.cs.normalizeString(value2[i]);
      target2[i] = this.cs.normalizeString(target2[i]);
    }

    if (!this.options.ordered) {
      value2 = value2.sort(XSort.str);
      target2 = target2.sort(XSort.str);
    }

    for (i = 0; i < value2.length; i++) {
      if (value2[i] !== target2[i]) {
        return false;
      }
    }

    return true;
  }

  /**
   *
   */
  private parseItems(value: string): string[] {
    const o: string[] = [];

    const r: RegExp = new RegExp('[^,]+', 'g');
    let z: Match = Match.tryParse(r.exec(value));
    while (z) {
      o.push(XString.trim(String(z.groups[0])));
      z = Match.tryParse(r.exec(value));
    }

    return o;
  }

  public get inputCapabilities(): InputCapabilities {
    const o: InputCapabilities = new InputCapabilities();
    o.text = true;
    return o;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.STANDARD;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const l: string[] = this.stringify(target);
    w.beginText();
    for (let i: number = 0; i < l.length; i++) {
      if (i > 0) {
        w.writeRaw(',  '); // two spaces for readability
      }
      w.writeRaw(XString.stripHtmlTags(l[i]));
    }
    w.endText();
  }
}
