import { IDictionary } from '../../js/utils/IDictionary';
import { IPrng } from '../core/prng/IPrng';
import { Prng } from '../core/prng/Prng';
import { AnonymousFunction } from '../elements/abstract/AnonymousFunction';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Expression } from '../elements/abstract/Expression';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { FormatProvider } from '../elements/factories/FormatProvider';
import { NameFactory } from '../elements/factories/NameFactory';
import { OptionsProvider } from '../elements/factories/OptionsProvider';
import { WBoolean } from '../elements/tokens/WBoolean';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WVariable } from '../elements/tokens/WVariable';
import { StringImporter } from '../expr/conversion/input/StringImporter';
import { ApplyRecursive } from '../expr/manipulation/ApplyRecursive';
import { CultureInfo } from '../localization/CultureInfo';
import { Variables } from '../expr/Variables';
import { IVariablesResolver } from '../expr/IVariablesResolver';
import { Environment } from '../expr/Environment';
import { IExtensionsMethods } from '../expr/IExtensionsMethods';
import { SourceMap } from '../expr/conversion/input/SourceMap';
import { RowWorker } from '../expr/conversion/input/RowWorker';
import { FunctionsRegistry } from '../funcs/FunctionsRegistry';
import { OperatorsRegistry } from '../funcs/OperatorsRegistry';
import { XString } from '../core/XString';

/**
 *
 */
export class ExpressionsUtil {
  /**
   *
   */
  private culture: CultureInfo;

  /**
   *
   */
  private extensions: IExtensionsMethods;

  /**
   *
   * @private
   */
  private _useRadians: boolean = false;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    extensions: IExtensionsMethods) {
    this.culture = culture;
    this.extensions = extensions;
  }

  /**
   *
   */
  public getEnvironment(): Environment {
    return this.getEnvironmentWithOptions(0, 1);
  }

  /**
   * Creates a default environment with the current culture.
   */
  public getEnvironmentWithOptions(optionsArg: number, seedArg: number): Environment {
    let options: number = optionsArg;
    let seed: number = seedArg;

    if (seed < 1 || seed % 1 !== 0) {
      // seed = Math.floor(Math.random() * 0xFFFFFFFF);
      seed = 1;
    }

    const prng: IPrng = new Prng(seed);

    if (options < 0) {
      options = OptionsProvider.DEFAULT_FLAGS;
    }

    return new Environment(
      this.culture,
      prng,
      new Prng(1),
      this._useRadians,
      new FormatProvider(this.culture),
      new OptionsProvider(options, null),
      NameFactory.createFromResources(this.culture, prng),
      this.extensions);
  }

  /**
   *
   * @param env
   * @param options
   */
  private copyEnvironmentWithOptions(env: Environment, options: number): Environment {
    if (options < 0) {
      return env;
    }
    return new Environment(
      env.culture,
      env.prng,
      env.staticPrng,
      env.useRadians,
      env.format,
      new OptionsProvider(env.options.flags | options, env.options.piecewiseRange),
      env.names,
      env.extensions);
  }

  public useRadians(): void {
    this._useRadians = true;
  }

  /**
   *
   */
  public toSourceMap(value: string,
                     variablesResolver: IVariablesResolver = null,
                     options: number = -1,
                     catchErrors: boolean = false,
                     seed: number = -1): SourceMap {
    if (XString.trim(value) === '') {
      return null;
    }
    const env: Environment
      = variablesResolver
        ? this.copyEnvironmentWithOptions(variablesResolver.getEnvironment(), options)
        : this.getEnvironmentWithOptions(options, seed);
    try {
      const rowWorker: RowWorker
        = new RowWorker(
          value,
          variablesResolver,
          FunctionsRegistry.getInstance(),
          OperatorsRegistry.getInstance(),
          env,
          true,
          false);
      return rowWorker.getSourceMap();
    } catch (e) {
      return null;
    }
  }

  /**
   *
   */
  public toExpression(value: string,
                      variablesResolver: IVariablesResolver = null,
                      options: number = -1,
                      catchErrors: boolean = false,
                      seed: number = -1,
                      withSourceMap: boolean = false,
                      withValidation: boolean = true): Expression {
    const env: Environment
      = variablesResolver
        ? this.copyEnvironmentWithOptions(variablesResolver.getEnvironment(), options)
        : this.getEnvironmentWithOptions(options, seed);

    try {
      return (
        new StringImporter(
          value,
          variablesResolver,
          env,
          withSourceMap,
          withValidation).expr
      );
    } catch (e) {
      if (catchErrors) {
        return null;
      }
      throw e;
    }

    return null;
  }

  /**
   *
   */
  public toSimplified(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false,
    seed: number = -1): Node {
    const env: Environment
      = variablesResolver
        ? this.copyEnvironmentWithOptions(variablesResolver.getEnvironment(), options)
        : this.getEnvironmentWithOptions(options, seed);

    let expr: Expression = null;

    try {
      expr = new StringImporter(
        value,
        variablesResolver,
        env,
        false).expr;
    } catch (e) {
      if (catchErrors) {
        return null;
      }
      throw e;
    }

    if (!expr) {
      return null;
    }

    const node: Node
      = new ApplyRecursive(
        expr,
        env).simplify(false, false, false).root;

    if (node && node.value && !variablesResolver) {
      // If there's a variables resolver associated with the creation of this token,
      // it makes no sense to note the userData because userData is intended
      // to recreate the same ContentElement without any predefined variables.
      node.value.userData = value;
    }

    node.dependencies = expr.dependencies;
    return node;
  }

  /**
   *
   */
  public toContentElement(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): ContentElement {
    const node: Node = this.toSimplified(value, variablesResolver, options, catchErrors);
    if (node) {
      if (node.isLeaf) {
        return node.value;
      }
    }
    return null;
  }

  /**
   *
   */
  public toTokenElement(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): TokenElement {
    const result: ContentElement = this.toContentElement(value, variablesResolver, options, catchErrors);
    return result instanceof TokenElement ? result : null;
  }

  /**
   *
   */
  public toRealElement(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): RealElement {
    const result: ContentElement = this.toContentElement(value, variablesResolver, options, catchErrors);
    return result instanceof RealElement ? result : null;
  }

  /**
   *
   */
  public toPolynomial(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): WPolynomial {
    const r: ContentElement = this.toContentElement(value, variablesResolver, options, catchErrors);
    if (r instanceof WPolynomial) {
      return r;
    }
    if (r instanceof WVariable) {
      return r.widen() as WPolynomial;
    }
    return null;
  }

  /**
   *
   */
  public toFunction(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): AnonymousFunction {
    const node: Node = this.toSimplified(value, variablesResolver, options, catchErrors);
    if (node) {
      return node.value as AnonymousFunction;
    }
    return null;
  }

  /**
   *
   */
  public toNumber(
    value: string,
    variablesResolver: IVariablesResolver = null,
    options: number = -1,
    catchErrors: boolean = false): number {
    const n: RealElement = this.toRealElement(value, variablesResolver, options, catchErrors);
    return n ? n.toNumber() : NaN;
  }

  /**
   *
   */
  public toBoolean(
    value: string,
    variablesResolver: IVariablesResolver = null,
    defaultValue: boolean = false,
    options: number = -1,
    catchErrors: boolean = false): boolean {
    const r: ContentElement = this.toContentElement(value, variablesResolver, options, catchErrors);
    if (r instanceof WBoolean) {
      return r.toBoolean();
    }
    return defaultValue;
  }

  /**
   *
   */
  public add(a: TokenElement, b: TokenElement, options: number = -1, seed: number = -1): TokenElement {
    const args: IDictionary = {};
    args['a'] = a;
    args['b'] = b;
    return this.toTokenElement('a+b', new Variables(args, this.getEnvironmentWithOptions(options, seed)));
  }

  /**
   *
   */
  public multiply(a: TokenElement, b: TokenElement, options: number = -1, seed: number = -1): TokenElement {
    const args: IDictionary = {};
    args['a'] = a;
    args['b'] = b;
    return this.toTokenElement('a*b', new Variables(args, this.getEnvironmentWithOptions(options, seed)));
  }

  /**
   *
   */
  public subtract(a: TokenElement, b: TokenElement, options: number = -1, seed: number = -1): TokenElement {
    const args: IDictionary = {};
    args['a'] = a;
    args['b'] = b;
    return this.toTokenElement('a-b', new Variables(args, this.getEnvironmentWithOptions(options, seed)));
  }

  /**
   *
   */
  public divide(a: TokenElement, b: TokenElement, options: number = -1, seed: number = -1): TokenElement {
    const args: IDictionary = {};
    args['a'] = a;
    args['b'] = b;
    return this.toTokenElement('a/b', new Variables(args, this.getEnvironmentWithOptions(options, seed)));
  }
}
