import { AnonymousFunction } from '../elements/abstract/AnonymousFunction';
import { ContentElement } from '../elements/abstract/ContentElement';
import { ElementCodes } from '../elements/abstract/ElementCodes';
import { Expression } from '../elements/abstract/Expression';
import { ListElement } from '../elements/abstract/ListElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { RelationalElement } from '../elements/abstract/RelationalElement';
import { SetElement } from '../elements/abstract/SetElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { Apply } from '../elements/constructs/Apply';
import { FormProvider } from '../elements/factories/FormProvider';
import { FormatProvider } from '../elements/factories/FormatProvider';
import { TallyFormatter } from '../elements/formats/lists/TallyFormatter';
import { NumberWordsFormatter } from '../elements/formats/numbers/NumberWordsFormatter';
import { DecimalExpansionFormatter } from '../elements/formats/rationals/DecimalExpansionFormatter';
import { WBoolean } from '../elements/tokens/WBoolean';
import { WExponential } from '../elements/tokens/WExponential';
import { WExpression } from '../elements/tokens/WExpression';
import { WFactors } from '../elements/tokens/WFactors';
import { WFraction } from '../elements/tokens/WFraction';
import { WHalfPlane } from '../elements/tokens/WHalfPlane';
import { WInfinity } from '../elements/tokens/WInfinity';
import { WInterval } from '../elements/tokens/WInterval';
import { WLine } from '../elements/tokens/WLine';
import { WList } from '../elements/tokens/WList';
import { WListOfPoints } from '../elements/tokens/WListOfPoints';
import { WListOfString } from '../elements/tokens/WListOfString';
import { WLog } from '../elements/tokens/WLog';
import { WNumber } from '../elements/tokens/WNumber';
import { WNumberSet } from '../elements/tokens/WNumberSet';
import { WPi } from '../elements/tokens/WPi';
import { WPoint } from '../elements/tokens/WPoint';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WPower } from '../elements/tokens/WPower';
import { WRadical } from '../elements/tokens/WRadical';
import { WRatio } from '../elements/tokens/WRatio';
import { WRational } from '../elements/tokens/WRational';
import { WRelation } from '../elements/tokens/WRelation';
import { WRepeatingDecimal } from '../elements/tokens/WRepeatingDecimal';
import { WScientificNumber } from '../elements/tokens/WScientificNumber';
import { WSetBuilder } from '../elements/tokens/WSetBuilder';
import { WString } from '../elements/tokens/WString';
import { WTimeOfDay } from '../elements/tokens/WTimeOfDay';
import { WTimeSpan } from '../elements/tokens/WTimeSpan';
import { WTransform } from '../elements/tokens/WTransform';
import { WUnion } from '../elements/tokens/WUnion';
import { Environment } from '../expr/Environment';
import { CNegativeExponent } from './CNegativeExponent';
import { CInequality } from './CInequality';
import { CTransform } from './CTransform';
import { CTimeSpan } from './CTimeSpan';
import { CTimeOfDay } from './CTimeOfDay';
import { CScientificNumber } from './CScientificNumber';
import { CLine } from './CLine';
import { CHalfPlane } from './CHalfPlane';
import { CExponential } from './CExponential';
import { CInfinity } from './CInfinity';
import { CRelation } from './CRelation';
import { CDivision } from './CDivision';
import { CBoolean } from './CBoolean';
import { CExpression } from './CExpression';
import { CPolynomial } from './CPolynomial';
import { CPolynomialDivision } from './CPolynomialDivision';
import { CRadical } from './CRadical';
import { CDecimalExpansion } from './CDecimalExpansion';
import { CFactors } from './CFactors';
import { CLog } from './CLog';
import { CPower } from './CPower';
import { CExpFrac } from './CExpFrac';
import { CFracExp } from './CFracExp';
import { CRatio } from './CRatio';
import { CSet } from './CSet';
import { CPoint } from './CPoint';
import { CListPts } from './CListPts';
import { CList } from './CList';
import { CTally } from './CTally';
import { CListStr } from './CListStr';
import { CNumber } from './CNumber';
import { CNumberWords } from './CNumberWords';
import { CFraction } from './CFraction';
import { CString } from './CString';
import { CValidationFunction } from './CValidationFunction';
import { CItemCompare } from './CItemCompare';
import { BaseCorrector } from './BaseCorrector';
import { NumberOrPercent } from './NumberOrPercent';
import { COptions } from './COptions';

/**
 *
 */
export class CFactory {
  /**
   *
   */
  public static corrector(
    expr: Expression,
    optionsArgs: COptions,
    env: Environment,
    formArg: FormProvider = null,
    formatArg: FormatProvider = null,
    plusMinus: NumberOrPercent = null,
    useLatex: boolean = false): BaseCorrector {
    let options = optionsArgs;
    let form = formArg;
    let format = formatArg;

    if (options == null) {
      options = new COptions();
    }
    if (form == null) {
      form = new FormProvider(env.culture, null, null);
    }
    if (format == null) {
      format = new FormatProvider(env.culture);
    }

    let c: BaseCorrector;
    const node: Node = expr.root;
    const content: ContentElement = node.value;
    const itemCompare: boolean = options.listCompare === 'ItemCompare';

    if (node.isLeaf) {
      if (CFactory.isNonEmptyList(content) && itemCompare) {
        c = new CItemCompare(
          CFactory.corrector(
            new Expression(new Node((content as ListElement).getItemAt(0))),
            options,
            env,
            null,
            null));
      } else if (content instanceof AnonymousFunction) {
        c = new CValidationFunction();
      } else if (content instanceof WString) {
        c = new CString(true);
      } else if (content instanceof WNumber) {
        const _number: WNumber = content;

        const cfraction: CFraction = new CFraction();

        if (_number.formatter instanceof NumberWordsFormatter) {
          c = new CNumberWords();
        } else if (_number.formatter.isDictionaryKey()) {
          c = new CString();
        } else if (!options.inputFraction
          || !CFraction.coerceTarget(content, env.culture.formats.rationalFormatImpl)
          || form.getAlternateForm()) {
          c = new CNumber(
            form.getAlternateForm(),
            plusMinus,
            !itemCompare,
            true);
        } else {
          c = cfraction;
        }
      } else if (content instanceof WList) {
        if (CFactory.isStringList(content)) {
          c = new CListStr();
        } else if (content.formatter instanceof TallyFormatter) {
          c = new CTally();
        } else {
          c = new CList();
        }
      } else if (content instanceof WListOfPoints) {
        c = new CListPts(plusMinus);
      } else if (content instanceof WListOfString) {
        c = new CListStr();
      } else if (content instanceof WPoint) {
        c = new CPoint(plusMinus);
      } else if (content instanceof SetElement) {
        c = new CSet(
          format.setEnclose,
          content instanceof WInterval || content instanceof WUnion,
          content instanceof WSetBuilder,
          content instanceof WNumberSet,
          format.emptySetNotation);
      } else if (content instanceof WRatio) {
        c = new CRatio(content.formatter.getSeparator());
      } else if (content instanceof WPower) {
        const pow: WPower = content;
        if (pow.base instanceof WRational) {
          c = new CFracExp();
        } else if (pow.exponent instanceof WRational) {
          c = new CExpFrac();
        } else {
          c = new CPower();
        }
      } else if (content instanceof WLog) {
        c = new CLog();
      } else if (content instanceof WFactors) {
        c = new CFactors();
      } else if (content instanceof WRational) {
        if (content.formatter instanceof DecimalExpansionFormatter) {
          c = new CDecimalExpansion();
        } else {
          c = new CFraction();
        }
      } else if (content instanceof WRepeatingDecimal) {
        c = new CDecimalExpansion();
      } else if (content instanceof WRadical) {
        c = new CRadical(CFactory.rootIndices(content));
      } else if (content instanceof SymbolElement
        || content instanceof WPolynomial) {
        if (CFactory.isRationalPolynomial(content, true)) {
          c = new CPolynomialDivision(
            CFactory.hasPi(content),
            CFactory.hasNegativeCoef(content),
            CFactory.hasMultMonom(content));
        } else if (CFactory.isRationalPolynomial(content, false)) {
          // Missing input capabilities
        } else {
          c = new CPolynomial(
            CFactory.hasPi(content),
            CFactory.hasNegativeCoef(content),
            CFactory.hasMultMonom(content));
        }
      } else if (content instanceof WExpression) {
        c = new CExpression(content.restrict);
      } else if (content instanceof WBoolean) {
        c = new CBoolean();
      } else if (content instanceof WFraction) {
        const fraction: WFraction = content;
        if (CDivision.validate(node, ElementCodes.TOKEN_RADICAL)) {
          c = new CDivision(ElementCodes.TOKEN_RADICAL, CFactory.rootIndices(fraction.numerator).concat(CFactory.rootIndices(fraction.denominator)));
        } else if (CDivision.validate(node, ElementCodes.TOKEN_POWER)) {
          c = new CDivision(ElementCodes.TOKEN_POWER, null);
        } else if (CDivision.validate(node, ElementCodes.TOKEN_POLYNOMIAL)
          || CDivision.validate(node, ElementCodes.TOKEN_SYMBOL)) {
          c = new CDivision(ElementCodes.TOKEN_POLYNOMIAL, null);
        }
      } else if (content instanceof WRelation) {
        c = new CRelation();
      } else if (content instanceof WInfinity) {
        c = new CInfinity();
      } else if (content instanceof WExponential) {
        c = new CExponential();
      } else if (content instanceof WHalfPlane) {
        const { yLabel, xLabel } = content.line;
        c = new CHalfPlane(yLabel, xLabel);
      } else if (content instanceof WLine) {
        c = new CLine(content.yLabel, content.xLabel);
      } else if (content instanceof WScientificNumber) {
        c = new CScientificNumber();
      } else if (content instanceof WTimeOfDay) {
        c = new CTimeOfDay();
      } else if (content instanceof WTimeSpan) {
        c = new CTimeSpan();
      } else if (content instanceof WTransform) {
        c = new CTransform();
      }
    } else if (content instanceof Apply) {
      if (CPolynomialDivision.validate(node)) {
        const n: ContentElement = node.childs[1].value;
        const d: ContentElement = node.childs[2].value;
        c = new CPolynomialDivision(
          CFactory.hasPi(n) || CFactory.hasPi(d),
          CFactory.hasNegativeCoef(n) || CFactory.hasNegativeCoef(d),
          CFactory.hasMultMonom(n) || CFactory.hasMultMonom(d));
      } else if (CDivision.validate(node, ElementCodes.TOKEN_RADICAL)) {
        c = new CDivision(ElementCodes.TOKEN_RADICAL, null);
      } else if (CDivision.validate(node, ElementCodes.TOKEN_POWER)) {
        c = new CDivision(ElementCodes.TOKEN_POWER, null);
      } else if (CInequality.validate(node)) {
        c = new CInequality(node.childs[0].value as RelationalElement);
      } else if (CNegativeExponent.validate(node)) {
        c = new CNegativeExponent();
      }
    }

    if (c) {
      c.configure(content, options, env, useLatex);
    }

    return c;
  }

  public static isRationalPolynomial(
    content: ContentElement,
    alsoMonomial: boolean): boolean {
    if (content instanceof WPolynomial) {
      const p: WPolynomial = content;
      if (p.hasRationalCoefficients) {
        if (alsoMonomial) {
          return p.numMonomials === 1;
        }
        return true;
      }
    }
    return false;
  }

  /**
   * Indicates if a list of numbers should be treated as a list of string.
   */
  private static isStringList(value: WList): boolean {
    if (value.count === 0) {
      return false;
    }
    return value.items.every(CFactory.testDictionnaryKey, null);
  }

  private static testDictionnaryKey(n: RealElement, ..._: any[]): boolean {
    if (n instanceof WNumber) {
      return n.formatter.isDictionaryKey();
    }
    return false;
  }

  private static isNonEmptyList(content: ContentElement): boolean {
    if (content instanceof ListElement) {
      return content.count > 0;
    }
    return false;
  }

  private static hasPi(content: ContentElement): boolean {
    if (content instanceof WPi) {
      return true;
    }
    if (content instanceof WPolynomial) {
      return content.hasPi;
    }
    return false;
  }

  private static hasNegativeCoef(content: ContentElement): boolean {
    if (content instanceof WPolynomial) {
      return content.hasNegativeCoef;
    }
    return false;
  }

  private static hasMultMonom(content: ContentElement): boolean {
    if (content instanceof WPolynomial) {
      return content.numMonomials > 1;
    }
    return false;
  }

  private static rootIndices(content: ContentElement): number[] {
    const o: number[] = [];

    if (content instanceof WRadical) {
      o.push(content.index.toNumber());
    }

    return o;
  }
}
