import React, { useCallback, useEffect, useState } from 'react';
import { getPlatformUrl } from '@scolab/common-editor';
import { Spinner, Unauthorized } from '@scolab/publisher-ui-kit';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { linkTo, Routes } from '../config/routes';

/**
 * This page collects the necessary information needed to complete the redirection of an unauthenticated
 * user from somewhere in the publisher to the global login page and back to a deep link in the publisher.
 */
const GetAccessTokenComponent: React.FC<RouteComponentProps> = ({ history }) => {
  const [error, setError] = useState<Error | null>(null);

  const getAbsoluteUrlFromRouterUrl = useCallback((): URL => {
    const routerUrl = history.createHref({
      pathname: linkTo(Routes.LoginCallback),
    });

    // Convert that to an absolute URL
    return new URL(routerUrl, window.location.href);
  }, [history]);

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    if (!currentUrlParams.has('ReturnUrl')) {
      setError(new Error('"ReturnUrl" is missing from the query string.'));
      return;
    }

    // We must convert the relative ReturnUrl to an absolute URL as we are redirecting to a different domain.
    // There may be a confusing bit as to why we keep two return urls. There is:
    // - The one that Tetris should return to with a single use access token so we can complete authentication
    //   -> This is cross domain and needs to be absolute.
    // - The one that the user wants to return to after finishing logging in
    //   -> This is relative within the app and can be relative.
    const postAuthRedirection = getAbsoluteUrlFromRouterUrl();
    postAuthRedirection.searchParams.set('ReturnUrl', currentUrlParams.get('ReturnUrl'));

    const authenticationEndpoint = new URL(`${getPlatformUrl()}/login/credentials`);
    authenticationEndpoint.searchParams.set('ReturnUrl', `${postAuthRedirection}`);

    // Forward using pure javascript because of possible competing
    // React Router instances with their own prefixes.
    window.location.replace(`${authenticationEndpoint}`);
  }, [getAbsoluteUrlFromRouterUrl]);

  return error ? <Unauthorized details={error} /> : <Spinner />;
};

export const GetAccessToken = withRouter(GetAccessTokenComponent);
