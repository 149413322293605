import { Point } from '../../../js/geom/Point';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WSegment } from '../../elements/tokens/WSegment';

/**
 * Not closed by default, meaning we don't have to link the last vertex to the first.
 * The polyline can still be closed if the first and last vertices are equals.
 */
export class WPolyline extends TokenElement {
  protected _vertices: Point[] = [];

  public verticeAt(index: number): Point {
    return this._vertices[index].clone();
  }

  public get vertices(): Point[] {
    return this._vertices.concat();
  }

  /**
   *
   */
  constructor(
    vertices: Point[]) {
    super();
    this._vertices = vertices;
  }

  /**
   *
   */
  public get isClosed(): boolean {
    return this._vertices[0].equals(this._vertices[this._vertices.length - 1]);
  }

  /**
   *
   */
  public get length(): number {
    return SegmentsUtil.polylineLength(this._vertices, false);
  }

  /**
   * Returns the number of segments.
   */
  public get segments(): number {
    return this.vertices.length - 1;
  }

  public narrow(): ContentElement {
    if (this.isClosed) {
      if (this._vertices.length === 3) {
        return WSegment.tryPrase(this._vertices[0], this._vertices[1]);
      }
      return WPolygon.tryParsePolygon(this._vertices.slice(0, this._vertices.length - 1));
    }
    return null;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WPolyline) {
      return SegmentsUtil.equals(this._vertices, value._vertices);
    }
    return false;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('[Polyline]');
    }
    return true;
  }

  public getType(): string {
    return 'polyline';
  }
}
