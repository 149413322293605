import { observable, action, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { IContentObjectData } from '../IContentObjectData';

@Store('ObjectBankStore.ts/ObjectBankStore')
export class ObjectBankStore {
  @observable
  public accessor filterText: string;

  @observable
  public accessor openedGroups: ReadonlyArray<string>;

  @observable
  public accessor contentObjects: ReadonlyArray<IContentObjectData>;

  constructor() {
    runInAction(
      () => {
        this.filterText = '';
        this.contentObjects = [];
        this.openedGroups = [];
      },
    );
  }

  @action.bound
  public setFilter(filterText: string): void {
    this.filterText = filterText;
  }

  @action.bound
  public setOpenedGroups(openedGroups: ReadonlyArray<string>): void {
    this.openedGroups = openedGroups;
  }

  @action.bound
  public updateObject(contentObject: IContentObjectData): void {
    const objectIndex = this.contentObjects.findIndex(o => o.key === contentObject.key);
    if (objectIndex === -1) {
      return;
    }
    this.contentObjects = [
      ...this.contentObjects.slice(0, objectIndex),
      contentObject,
      ...this.contentObjects.slice(objectIndex + 1),
    ];
  }
}
