import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Simplify extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    // value, use rational (integers are kept as
    // rationals instead of being transformed to number)
    const useRationals: boolean
      = args.length === 2 && args.getBoolean(1)
        ? args.getBoolean(1).toBoolean()
        : false;

    if (args.getReal(0)) {
      return this.simplifyImpl(args.getReal(0), useRationals);
    }
    if (args.getReals(0)) {
      const list: WList = args.getReals(0);
      return WList.createFromReals(this.simplifyListImpl(list.toReals(), useRationals), list.formatter2);
    }
    if (args.getMatrix(0)) {
      const matrix: WMatrix = args.getMatrix(0);
      return new WMatrix(this.simplifyListImpl(matrix.values, useRationals), matrix.columns, matrix.formatter);
    }
    if (args.getTokenElement(0)) {
      return args.getTokenElement(0);
    }

    return null;
  }

  /**
   *
   */
  private simplifyListImpl(values: RealElement[], useRational: boolean): RealElement[] {
    const o: RealElement[] = [];
    for (let i: number = 0; i < values.length; i++) {
      o.push(this.simplifyImpl(values[i], useRational));
    }
    return o;
  }

  /**
   *
   */
  private simplifyImpl(value: RealElement, useRational: boolean): RealElement {
    if (value instanceof WRational) {
      return value.reduce(useRational);
    }
    return value;
  }
}
