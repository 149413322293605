import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { SubjectStore } from '../models/store/SubjectStore';
import { ISubject } from '../models/ISubject';

@ViewModel
export class SubjectMediator {
  @inject(SubjectStore)
  private accessor subjectStore: SubjectStore;

  public get subjects(): ReadonlyArray<ISubject> {
    return this.subjectStore.subjects;
  }

  public getSubjectName = (subjectId: number) => {
    return this.subjectStore.subjects.find(subject => subject.id === subjectId)?.name;
  };
}
