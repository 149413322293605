import {
  FeatureFlagsStore,
  inject,
  ViewModel,
} from '@scolab/common-editor';
import { computed } from 'mobx';

@ViewModel
export default class FeatureFlagsMediator {
  @inject(FeatureFlagsStore)
  private accessor featureFlagsStore: FeatureFlagsStore;

  @computed
  get isLoaded(): boolean {
    return Object.keys(this.featureFlagsStore.featureFlags.flags).length > 0;
  }
}
