import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';

/**
 * Wrapper class for list of points.
 */
export class WListOfPoints extends ListElement {
  /**
   *
   */
  constructor(
    points: ContentElement[],
    formatter: BaseListFormatter) {
    super(points, formatter);
  }

  /**
   *
   */
  public acceptElement(element: ContentElement): boolean {
    return element.getListItemCode() === 'point';
  }

  protected createList(
    items: ContentElement[],
    formatter: BaseListFormatter): ListElement {
    return new WListOfPoints(items, formatter);
  }

  /**
   *
   */
  public getValueAt(index: number): Point {
    return this.getTypedItemAt(index).toPoint();
  }

  /**
   *
   */
  public getTypedItemAt(index: number): WPoint {
    return this.getItemAt(index) as WPoint;
  }

  /**
   *
   */
  public toPoints(): Point[] {
    const o: Point[] = [];
    for (let i: number = 0; i < this.count; i++) {
      o.push(this.getValueAt(i));
    }
    return o;
  }

  /**
   * Returns a 2-columns matrix.
   */
  public toMatrix(): WMatrix {
    const o: RealElement[] = [];
    for (let i: number = 0; i < this.count; i++) {
      const p: WPoint = this.getTypedItemAt(i);
      o.push(p.x, p.y);
    }
    return new WMatrix(o, 2, this.formatter.culture.formats.matrixFormatImpl);
  }

  public toXs(): RealElement[] {
    const o: RealElement[] = [];
    for (let i: number = 0; i < this.count; i++) {
      o.push(this.getTypedItemAt(i).x);
    }
    return o;
  }

  public toYs(): RealElement[] {
    const o: RealElement[] = [];
    for (let i: number = 0; i < this.count; i++) {
      o.push(this.getTypedItemAt(i).y);
    }
    return o;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WListOfPoints) {
      return this.unorderedEqualsTo(value);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfPoints) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  /**
   *
   */
  protected compareElements(a: ContentElement, b: ContentElement): number {
    return WPoint.xycoordinate(a as WPoint, b as WPoint);
  }

  /**
   *
   */
  public getType(): string {
    return 'points';
  }
}
