import { Context, Variable } from '@scolab/math-core-api';
import { IMathCoreId } from '@scolab/content-model';
import { computed } from 'mobx';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { MathcoreContextsStore } from '../models/store/MathcoreContextsStore';
import { ContextModelTypes } from '../models/ContextModelTypes';

@ViewModel
export class ValuePreviewMediator {
  @inject(MathcoreContextsStore)
  private accessor mathcoreContextsStore: MathcoreContextsStore;

  public getGeneratedVariable(contextType: ContextModelTypes, variableId: IMathCoreId): Variable {
    const currentGeneratedContext = this.mathcoreContextsStore.contexts.get(contextType);
    return currentGeneratedContext
      ? currentGeneratedContext.getDeclaration(variableId as string, true)
      : null;
  }

  @computed
  public get generatedVariablesContext(): Context {
    return this.mathcoreContextsStore.contexts.get(ContextModelTypes.Variables) as Context;
  }
}
