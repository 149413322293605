import { Point } from '../../../../js/geom/Point';

import { MathError } from '../../../core/MathError';
import { XRound } from '../../../core/XRound';
import { AnonymousFunction } from '../../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { Apply } from '../../../elements/constructs/Apply';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { IntervalClosure } from '../../../elements/models/IntervalClosure';
import { WInterval } from '../../../elements/tokens/WInterval';
import { WNotANumber } from '../../../elements/tokens/WNotANumber';
import { WNumber } from '../../../elements/tokens/WNumber';
import { Environment } from '../../../expr/Environment';
import { Evaluate } from '../../../expr/manipulation/Evaluate';
import { FunctionCompiler } from '../../../expr/manipulation/optimized/FunctionCompiler';
import { IEval1 } from '../../../expr/manipulation/optimized/IEval1';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { LogN } from '../../../funcs/arithmetic/LogN';
import { Tan } from '../../../funcs/trigonometry/Tan';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { IFunctionAdapter } from '../../../elements/functions/adapters/IFunctionAdapter';

/**
 * Evaluate is feature complete, FunctionCompiler only
 * handles a subset of what Evaluate can do. In order to
 * use FunctionCompiler, we must first make sure that
 * FunctionCompiler.compileOneArgumentFunction returns a value.
 *
 * Then we have to compare the firsts results to see if
 * IEval1 is actually behaving the same as Evaluate.
 * Once we determine that IEval1 is safe to use,
 * we use it instead of Evaluate since it's much faster.
 */
export class LambdaAdapter implements IFunctionAdapter {
  /**
   * Equivalence check.
   */
  private static SAFE_SAMPLE_SIZE: number = 15;

  private static EPSILON: number = 0.00001;

  private test: number = 0;

  private error: boolean = false;

  private fn: AnonymousFunction;

  /**
   * Standard evaluator.
   */
  private evaluate: Evaluate;

  /**
   * Fast evaluation implementation (only handles a subset of functions).
   */
  private evaluate2: IEval1;

  /**
   *
   */
  private env: Environment;

  /**
   *
   */
  constructor(
    fn: AnonymousFunction,
    env: Environment,
    periodic: boolean = false) {
    this.fn = fn;
    this.env = env;
    this.evaluate = new Evaluate(fn, env);
    this.evaluate2 = FunctionCompiler.compileOneArgumentFunction(fn);
    if (!this.evaluate2) {
      this.error = true;
    }
    this.checkPossibleDiscontinuity(fn.node);
    if (periodic) {
      this.calculatePeriod();
    }
  }

  /**
   *
   */
  private _continuous: number = -1;

  public get continuous(): number {
    return this._continuous;
  }

  /**
   *
   */
  public get limit(): Point {
    return null;
  }

  /**
   * If tan or divide is found, the assume there could
   * be a discontinuity and return -1, otherwise return 0.
   */
  private checkPossibleDiscontinuity(node: Node): void {
    this._continuous = this.hasTanOrDivideOrLog(node) ? -1 : 1;
  }

  /**
   *
   */
  private hasTanOrDivideOrLog(node: Node): boolean {
    if (node.value instanceof Apply) {
      if (node.childs.length > 0) {
        if (node.childs[0].value instanceof Tan
          || node.childs[0].value instanceof LogN
          || node.childs[0].value instanceof Divide) {
          return true;
        }
      }
    }

    for (let i: number = 0; i < node.numChildren; i++) {
      if (this.hasTanOrDivideOrLog(node.childs[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   */
  public previous(value: number): number {
    return value;
  }

  public next(value: number): number {
    return value;
  }

  public map(valueArg: number): number {
    const value = this.slidex(valueArg);
    if (this.optimized) {
      return this.evaluate2.eval1(value);
    }

    let c: ContentElement = this.evaluate.evaluate1(new WNumber(value, 1, false, this.env.culture.numberFormatter));
    let n: number;
    if (c == null || c instanceof WNotANumber) {
      n = NaN;
    } else if (c instanceof RealElement) {
      n = c.toNumber();
    } else {
      c = c.narrow();
      if (c instanceof RealElement) {
        n = c.toNumber();
      } else {
        n = NaN;
      }
    }

    if (this.evaluate2 && !this.error) {
      const n2: number = this.evaluate2.eval1(value);
      if (LambdaAdapter.nearlyEqual(n, n2, LambdaAdapter.EPSILON)) {
        this.test++;
      } else if (isNaN(n2)) {
        // don't take into account that value in the test
      } else {
        // trace("Compiled function failed because of n: " + n + ", n2: " + n2)
        this.error = true;
      }
    }

    return n;
  }

  /**
   *
   */
  private static nearlyEqual(a: number, b: number, epsilon: number): boolean {
    const absA: number = Math.abs(a);
    const absB: number = Math.abs(b);
    const diff: number = Math.abs(a - b);

    if (a === b) {
      return true;
    }
    if (a === 0 || b === 0 || diff < Number.MIN_VALUE) {
      return diff < (epsilon * Number.MIN_VALUE);
    }
    return diff / Math.min((absA + absB), Number.MAX_VALUE) < epsilon;
  }

  /**
   * Convert x value to a value inside the period defined.
   * This is required before evaluation.
   */
  private slidex(value: number): number {
    let x: number = value;
    if (this._period) {
      let j: number = (x - this._period.lBoundN) / this.periodLength;
      j -= Math.floor(j);
      x = XRound.safeRound(this._period.lBoundN + this.periodLength * j);
    }
    return x;
  }

  /**
   *
   */
  private calculatePeriod(): void {
    const intervals: WInterval[] = this.evaluate.intervals;
    if (!intervals) {
      return;
    }
    if (intervals.length === 0) {
      return;
    }

    let x0: number = Number.MAX_VALUE;
    let x1: number = -Number.MAX_VALUE;

    for (let i: number = 0; i < intervals.length; i++) {
      const interval: WInterval = intervals[i];
      if (!interval.isFinite) {
        return;
      }
      x0 = Math.min(x0, interval.lBoundN);
      x1 = Math.max(x1, interval.rBoundN);
    }

    this._period = this.env.culture.intervalsFactory.createIntervalN(IntervalClosure.CLOSED, x0, x1);
    this.periodLength = XRound.safeRound(x1 - x0);
  }

  private get optimized(): boolean {
    return this.evaluate2 && this.test >= LambdaAdapter.SAFE_SAMPLE_SIZE;
  }

  public get type(): string {
    return this.optimized ? FunctionStyles.UNKNOWN : FunctionStyles.UNKNOWN_SLOW;
  }

  public get constantPiece(): WInterval {
    return null;
  }

  public piecesInRange(value: WInterval): WInterval[] {
    if (!value.isFinite) {
      throw new Error();
    }

    const pieces: WInterval[] = this.evaluate.intervals;

    if (this._period) {
      const a: number = Math.floor((value.lBoundN - this._period.lBoundN) / this.periodLength);
      const b: number = Math.ceil((value.rBoundN - this._period.rBoundN) / this.periodLength);
      const o: WInterval[] = [];
      for (let i: number = a; i <= b; i++) {
        for (let j: number = 0; j < pieces.length; j++) {
          const piece: WInterval = pieces[j];

          const piece2: WInterval = piece.translate(i * this.periodLength);

          if (value.intersects(piece2)) {
            o.push(piece2);
          }
        }
      }

      return o;
    }

    return pieces;
  }

  private periodLength: number;

  private _period: WInterval = null;

  public get period(): WInterval {
    return this._period;
  }

  /**
   *
   */
  public getHashCode(): string {
    return [
      this.fn.hashCode(),
      this.period ? this.period.toText(false) : null,
    ].join(';');
  }

  /**
   *
   */
  public get attributes(): IFunctionAttributes {
    throw new MathError('Not implemented');
  }
}
