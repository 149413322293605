import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { inject } from '../../../dependencyInjection/inject';
import { AlignmentItem } from './AlignmentItem';
import { SectionTitle } from './SectionTitle';
import { StandardsAndAlignmentsMediator } from '../../../mediators/StandardsAndAlignmentsMediator';
import { AlignmentType } from './AlignmentModel';
import { AlignmentQuality } from '../../../models/IStandardAlignment';
import { locales } from '../../../autoGenerate/locale';
import { LocaleProvider } from '../../LocaleProvider';
import { getSubjectName } from '../../../queries/subjectsQueries';
import { SubjectIcon } from '../../subject/SubjectIcon';

interface IPageAlignmentsProps {

}

interface IActivityAlignmentsState {
  openItemsIndexes: ReadonlyArray<number>;
}

@observer
export class ActivityAlignments extends React.Component<IPageAlignmentsProps, IActivityAlignmentsState> {
  @inject(StandardsAndAlignmentsMediator)
  private accessor mediator: StandardsAndAlignmentsMediator;

  constructor(props: IPageAlignmentsProps) {
    super(props);
    this.state = {
      openItemsIndexes: [],
    };
  }

  public render(): React.ReactNode {
    const { openItemsIndexes } = this.state;
    const activityAlignments = this.mediator.activityAlignments.map((alignment) => {
      return (
        <AlignmentItem
          key={alignment.standardItem.entryDisplayId}
          title={alignment.standardItem.entryDisplayId}
          rawDescription={alignment.standardItem.entryDescription}
          entryId={alignment.standardItem.entryId}
          quality={alignment.quality}
          subjectIcon={<SubjectIcon subjectName={getSubjectName(alignment.standardItem.courseSubjectId)} />}
          isActivity
          isOpen={openItemsIndexes.includes(alignment.standardItem.entryId)}
          setIsOpen={this.onItemSetIsOpen}
          remove={this.removeFromActivity}
          setToAllPages={this.setToAllPages}
          removeFromAllPages={this.removeFromAllPages}
          setAlignmentStrength={this.setAlignmentStrength}
        />
      );
    });
    const label = (<FormattedMessage id="alignmentAndStandard.activityStandards" defaultMessage="Activity Standards" />);
    return (
      <LocaleProvider locales={locales}>
        <Container>
          <SectionTitle
            Label={label}
            type={AlignmentType.activity}
          />
          <TextButtonsContainer>
            <TextButton
              role="button"
              onClick={this.onExpandAll}
            >
              <FormattedMessage id="alignmentAndStandard.expandAll" defaultMessage="Expand all" />
            </TextButton>
            &nbsp;/&nbsp;
            <TextButton
              role="button"
              onClick={this.onCollapseAll}
            >
              <FormattedMessage id="alignmentAndStandard.collapseAll" defaultMessage="Collapse all" />
            </TextButton>
          </TextButtonsContainer>
          {activityAlignments}
        </Container>
      </LocaleProvider>
    );
  }

  private onItemSetIsOpen = (id: number, isOpen: boolean) => {
    let newOpenItemsIndexes = this.state.openItemsIndexes.concat();
    if (isOpen) {
      newOpenItemsIndexes.push(id);
    } else {
      newOpenItemsIndexes = newOpenItemsIndexes.filter(itemIndex => itemIndex !== id);
    }
    this.setState({
      openItemsIndexes: newOpenItemsIndexes,
    });
  };

  private onExpandAll = () => {
    this.setState({
      openItemsIndexes: this.mediator.activityAlignments.map(_ => _.standardItem.entryId),
    });
  };

  private onCollapseAll = () => {
    this.setState({
      openItemsIndexes: [],
    });
  };

  private removeFromActivity = (id: number): void => {
    this.mediator.removeFromActivity(id);
  };

  private setAlignmentStrength = (id: number, quality: AlignmentQuality): void => {
    this.mediator.setActivityAlignmentStrength(id, quality);
  };

  private setToAllPages = (id: number, quality: number): void => {
    this.mediator.setToAllPages(id, quality);
  };

  private removeFromAllPages = (id: number): void => {
    this.mediator.removeFromAllPages(id);
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
  padding-bottom: 8px;
`;

const TextButton = styled.div`
  color: ${props => props.theme.colorset.primary};
  font-size: 14px;
  cursor: pointer;
`;

const TextButtonsContainer = styled.div`
  display: flex;
  color: ${props => props.theme.colorset.grey07};
  font-weight: bold;
  margin-bottom: 12px;
`;
