import { computed } from 'mobx';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { HistoryStore } from '../models/store/HistoryStore';
import { IHistoryNotification } from '../models/history';

@ViewModel
export default class HistoryNotificationMediator {
  @inject(HistoryStore)
  private accessor historyStore: HistoryStore;

  @computed
  public get lastNotification(): IHistoryNotification {
    return this.historyStore.lastNotification;
  }

  public async finalizeNotification(): Promise<void> {
    await this.historyStore.finalizeNotification();
  }
}
