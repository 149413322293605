import {
  Context,
  IExtensionsMethods,
  CultureInfo,
  LocaleConfigurationResolver,
  generateVariablesWithDiff,
  ILocaleConfiguration,
} from '@scolab/math-core-api';
import { Dependency, files } from '@scolab/vendor';
import { runInAction } from 'mobx';
import { generateRandomSeed } from '../utils/seed';
import { MathcoreContextsStore } from '../models/store/MathcoreContextsStore';
import { instantiate } from '../dependencyInjection/instantiate';
import { IVariablesEditor } from '../models/IVariablesEditor';
import { ContextModelTypes } from '../models/ContextModelTypes';

export const generateContextsCommand = async (variablesEditor: IVariablesEditor) => {
  return Promise.all([
    await files.register(Dependency.TeXZilla),
    await generateContexts(variablesEditor),
  ]);
};

const generateContexts = async (variablesEditor: IVariablesEditor) => {
  const localeConfiguration = await getCachedLocaleConfig(variablesEditor.debugLocale);
  runInAction(() => {
    const { debugSeedLocked } = variablesEditor;
    if (!debugSeedLocked) {
      variablesEditor.debugSeed = generateRandomSeed();
    }
    const extensionsMethods = getExtensionsMethods(variablesEditor);
    const culture = new CultureInfo(localeConfiguration);
    const editorsInfo = variablesEditor.editorsInfo;

    const mathcoreContextsStore = instantiate(MathcoreContextsStore);
    const oldVariableContext = mathcoreContextsStore.contexts.get(ContextModelTypes.Variables);
    const newVariablesContext = generateVariablesWithDiff(
      debugSeedLocked ? null : oldVariableContext,
      null,
      editorsInfo[ContextModelTypes.Variables]?.contextModel ?? null,
      culture,
      extensionsMethods,
      variablesEditor.debugSeed,
      true,
      true,
      true,
    );

    const generatedDebugLiveContext
      = editorsInfo[ContextModelTypes.DebugLive] && editorsInfo[ContextModelTypes.DebugLive].contextModel
        ? Context.generate(
          '',
          editorsInfo[ContextModelTypes.DebugLive].contextModel,
          culture,
          extensionsMethods,
          true,
          newVariablesContext,
          null,
          true,
          true)
        : null;

    const generatedActiveContext
      = editorsInfo[ContextModelTypes.Active] && editorsInfo[ContextModelTypes.Active].contextModel
        ? Context.generate(
          '',
          editorsInfo[ContextModelTypes.Active].contextModel,
          culture,
          extensionsMethods,
          true,
          generatedDebugLiveContext || newVariablesContext,
          null,
          true,
          true)
        : null;

    const generatedLateContext
      = editorsInfo[ContextModelTypes.Late] && editorsInfo[ContextModelTypes.Late].contextModel
        ? Context.generate(
          '',
          editorsInfo[ContextModelTypes.Late].contextModel,
          culture,
          extensionsMethods,
          true,
          generatedActiveContext || generatedDebugLiveContext || newVariablesContext,
          null,
          true,
          true)
        : null;

    mathcoreContextsStore.setContext(ContextModelTypes.Variables, newVariablesContext);
    mathcoreContextsStore.setContext(ContextModelTypes.DebugLive, generatedDebugLiveContext);
    mathcoreContextsStore.setContext(ContextModelTypes.Active, generatedActiveContext);
    mathcoreContextsStore.setContext(ContextModelTypes.Late, generatedLateContext);
  });
};

const getExtensionsMethods = (variablesEditor: IVariablesEditor): IExtensionsMethods => {
  const { debugEnvVariables } = variablesEditor;
  return {
    latexEnabled: true,
    latexToMarkup: (latex: string) => {
      window.TeXZilla.setItexIdentifierMode(false);
      try {
        return window.TeXZilla.toMathMLString(latex, false, false, true);
      } catch (e) {
        return '<math/>';
      }
    },
    getEnvVariable: (name: string) => {
      const envVariables = debugEnvVariables || {};
      if (name == null) return Object.keys(envVariables).join(',');
      return envVariables.hasOwnProperty(name) ? envVariables[name] : null;
    },
  };
};

const localeConfigCache: { [locale: string]: ILocaleConfiguration } = {};
const getCachedLocaleConfig = async (locale: string) => {
  let config = localeConfigCache[locale];
  if (!config) {
    config = await LocaleConfigurationResolver.resolve(locale);
    localeConfigCache[locale] = config;
  }
  return config;
};
