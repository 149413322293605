import { observable, action, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { IActivity } from '../IActivity';

@Store('ActivityStore.ts/ActivityStore')
export class ActivityStore {
  @observable // NOTE: this is ACTIVE, everytime a push occur, it is emmitting an event
  public accessor currentActivity: IActivity;

  constructor() {
    runInAction(
      () => {
        this.currentActivity = {
          activityId: null,
          name: null,
        };
      },
    );
  }

  @action.bound
  public setCurrentActivity(activity: IActivity): void {
    this.currentActivity = activity;
  }

  @action.bound
  public clear(): void {
    this.currentActivity = {
      activityId: null,
      name: null,
    };
  }
}
