import { action, computed, observable, runInAction } from 'mobx';
import { OperationToken } from './OperationToken';
import { OperationsTrackerStore } from './OperationsTrackerStore';
import { IOperation } from '../frameworks/GenericStoreUndoManager';
import { IOrchestrable } from '../frameworks/interfaces/IOrchestrable';
import { inject } from '../dependencyInjection/inject';
import { UndoRedoFastForwardStore } from '../models/store/UndoRedoFastForwardStore';

export class UndoRedoOrchestrator {
  @observable
  public accessor action: 'undo' | 'redo' | '';

  private tokenInUndoRedoSequence: OperationToken;

  @inject(OperationsTrackerStore)
  private accessor operationsTracker: OperationsTrackerStore;

  @inject(UndoRedoFastForwardStore)
  private accessor undoRedoFastForwardStore: UndoRedoFastForwardStore;

  @observable
  private accessor actionCount: number;

  @observable
  public accessor undoStack: IOperation[];

  @observable
  public accessor redoStack: IOperation[];

  public constructor() {
    runInAction(() => {
      this.action = '';
      this.actionCount = 0;
      this.undoStack = [];
      this.redoStack = [];
    });
  }

  public orchestrate = (orchestrable: IOrchestrable) => {
    // NOTE: orchestrable will emit different type of signal, and provide scope guard.
    if (orchestrable.isAvailable) {
      this.monitor(orchestrable);
    }
    orchestrable.availabilityChanged.add(this.handleAvailabilityChanged);
  };

  @action.bound
  public redo(): void {
    this.actionCount++;
    this.action = 'redo';
    this.tokenInUndoRedoSequence = this.operationsTracker.trackCurrentOperation();
    const operation = this.redoStack.pop();
    operation.operators.redo();
    this.undoStack.push(operation);

    if (this.redoStack.length > 0
      && (operation.operationToken.token === this.redoStack[this.redoStack.length - 1].operationToken.token
        || operation.isFastforwardable)) {
      this.redo();
    }
    this.actionCount--;

    this.action = '';
  }

  @action.bound
  public undo(): void {
    this.actionCount++;
    this.action = 'undo';
    this.tokenInUndoRedoSequence = this.operationsTracker.trackCurrentOperation();
    const operation = this.undoStack.pop();
    operation.operators.undo();
    this.redoStack.push(operation);
    if (this.undoStack.length > 0
      && (operation.operationToken.token === this.undoStack[this.undoStack.length - 1].operationToken.token
        || operation.isFastforwardable)) {
      this.undo();
    }
    this.actionCount--;

    this.action = '';
  }

  @computed
  public get canRedo(): boolean {
    return this.redoStack.length > 0;
  }

  @computed
  public get canUndo(): boolean {
    return this.undoStack.length > 0;
  }

  @computed
  public get isProcessing(): boolean {
    return this.actionCount !== 0 || this.action !== '';
  }

  private monitor = (orchestrable: IOrchestrable) => {
    orchestrable.operationAppended.add(this.handleOperationAppended);
  };

  private unmonitor = (orchestrable: IOrchestrable) => {
    orchestrable.operationAppended.remove(this.handleOperationAppended);
  };

  private handleAvailabilityChanged = (orchestrable: IOrchestrable, isAvailable: boolean) => {
    if (isAvailable) {
      this.monitor(orchestrable);
    } else {
      this.unmonitor(orchestrable);
    }
  };

  @action.bound
  protected handleOperationAppended(operation: IOperation): void {
    operation.operationToken = operation.operationToken || this.operationsTracker.trackCurrentOperation();
    if (this.undoRedoFastForwardStore.operationIsFastforwardable) {
      operation.isFastforwardable = true;
    }
    if (this.tokenInUndoRedoSequence && operation.operationToken === this.tokenInUndoRedoSequence) {
      return; // NOTE: when a tokenInUndoRedoSequence is executed, we do not want to create new entry in our UndoRedo history
    }
    // console.log('UndoRedoOrchestrator.ts[' + this.name + "\\" + this.scope + '] * INFO handleOperationAppended()');
    // console.log(operation, operation.operationToken);
    this.undoStack.push(operation);
    this.redoStack = [];
  }
}
