/* eslint-disable no-restricted-imports */
import { IMmlpx, modelNameSymbol } from 'mmlpx/esm/core/dependency-inject/meta';
import { instantiate } from 'mmlpx';
/* eslint-enable no-restricted-imports */

type Constructor<T> = new(...args: any[]) => T;

export const inject = <T>(InjectedClass: Constructor<T>, ...args: any[]): any => (value: any, context: ClassAccessorDecoratorContext) => {
  const symbol = typeof context.name === 'symbol' ? context.name : Symbol(context.name);

  if (context.kind !== 'accessor') {
    if (context.kind === 'field') {
      const name = String(context.name);
      throw new Error(`Please use \`@inject() accessor ${name}\` instead of \`@inject() ${name}\``);
    }
    throw new Error(`The @inject decorator can only be used on class accessor properties`);
  }

  return {
    get(this: any) {
      if (!this[symbol] || this[symbol][modelNameSymbol] !== InjectedClass[modelNameSymbol]) {
        const initializedValue = instantiate.apply(this, [InjectedClass as IMmlpx<T>, ...args]);
        this[symbol] = initializedValue;
        return initializedValue;
      }
      return this[symbol];
    },
    set() {
    },
  };
};
