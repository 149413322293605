import { XRound } from '../../../core/XRound';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { UnformattedFormatter } from '../../../elements/formats/numbers/UnformattedFormatter';
import { AudioExporter } from '../../../expr/conversion/output/AudioExporter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AlternateForm } from '../../../expr/manipulation/alt/AlternateForm';

/**
 * 2400 --> 2000 + 400
 * 2400 --> 2 x 1000 + 4 x 100
 * 2400 --> 2 x 10 ^ 3 + 4 x 10 ^ 2
 */
export class ExpandNumber extends AlternateForm {
  private _form: number;

  public get form(): number {
    return this._form;
  }

  private unformattedFormatter: UnformattedFormatter;

  private audioExporter: AudioExporter;

  /**
   *
   */
  constructor(culture: CultureInfo, form: number = 0) {
    super(culture);
    this.unformattedFormatter = new UnformattedFormatter(culture);
    this.audioExporter = new AudioExporter(culture);
    this._form = form;
  }

  /**
   *
   */
  public alt(value: ContentElement): string {
    return this.expandImpl(value, false, '');
  }

  /**
   *
   */
  public tts(value: ContentElement): string {
    return null;
    // return this.expandImpl(value, true, " ");
  }

  /**
   * value: numeric value to expand.
   * formatFunction: format function for numbers.
   * spaceChar: spacing character used around operators.
   */
  private expandImpl(
    value: ContentElement,
    isTtsContext: boolean,
    spaceChar: string): string {
    if (!(value instanceof RealElement)) {
      return null;
    }
    const n: number = value.toNumber();
    if (isNaN(n)) {
      return null;
    }
    if (n <= 0) {
      return null;
    }

    const expanded: any[] = ExpandNumber.expand(n);
    for (let i: number = 0; i < expanded.length; i++) {
      expanded[i] = this.formatTerm(expanded[i], isTtsContext, spaceChar);
    }

    return expanded.join(`${spaceChar}+${spaceChar}`);
  }

  private formatTerm(term: any[], isTtsContext: boolean, spaceChar: string): string {
    // power[0] * 10 ^ power[1] give the value at a decimal place
    const coef: number = term[0];
    const base10power: number = term[1];

    let termS: string;
    switch (this.form) {
      case 0: // 2000 + 400
        termS = this.formatNumber(XRound.safeRound(coef * (10 ** base10power)), isTtsContext);
        break;
      case 1: // 2 x 1000 + 4 x 100
        termS = `${this.formatNumber(coef, isTtsContext) + spaceChar}×${spaceChar}${this.formatNumber((10 ** base10power), isTtsContext)}`;
        break;
      case 2: // 2 x 10 ^ 3 + 4 x 10 ^ 2
        termS = `${this.formatNumber(coef, isTtsContext) + spaceChar}×${spaceChar}10^${this.formatNumber(base10power, isTtsContext)}`;
        break;
    }

    if (this.form === 1 || this.form === 2) {
      termS = `(${termS})`;
    }

    return termS;
  }

  /**
   *
   */
  private formatNumber(n: number, isTtsContext: boolean): string {
    return isTtsContext
      ? this.audioExporter.exportNumber(n)
      : this.unformattedFormatter.toLocaleString(n);
  }

  /**
   *
   */
  public lineBreakOperator(): string {
    return '+';
  }

  /**
   * Returns an array like [[2, 3], [4, 2]]
   *
   * [[a, b], [c, d], ...]
   * = a * 10 ^ b + c * 10 ^ d
   *
   * From this array, we can go easily to any of the three forms this class can handle.
   */
  public static expand(nArg: number): any[] {
    const n = Math.abs(nArg); // works with positive values only

    let i: number;
    let digit: number;

    const l: any[] = [];
    const parts: any[] = String(n).split('.');

    let integers: any[] = parts[0].split('');
    integers = integers.reverse();

    for (i = 0; i < integers.length; i++) {
      digit = Number(integers[i]);
      if (digit !== 0) {
        l.unshift([digit, i]);
      }
    }

    if (parts.length > 1) {
      const fractional: any[] = parts[1].split('');
      for (i = 0; i < fractional.length; i++) {
        digit = Number(fractional[i]);
        if (digit !== 0) {
          l.push([digit, -(i + 1)]);
        }
      }
    }

    return l;
  }
}
