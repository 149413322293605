import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';

export type TUndoRedoScope =
  'variableEditor' |
  'document';

@Store('UndoRedoScopeStore')
export class UndoRedoScopeStore {
  @observable
  public accessor scope: TUndoRedoScope;

  constructor() {
    runInAction(() => {
      this.scope = 'document';
    });
  }

  @action.bound
  public setScope(value: TUndoRedoScope): void {
    this.scope = value;
  }
}
