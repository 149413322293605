/**
 *
 */
import { IMmlProxy } from './IMmlProxy';

export class MmlXmlProxy implements IMmlProxy {
  /**
   *
   */
  public elementName(node: any): string {
    return (node as Element).localName;
  }

  /**
   *
   */
  public createNode(elementName: string): any {
    return document.createElement(elementName);
  }

  /**
   *
   */
  public setAttribute(node: any, attributeName: string, value: any): void {
    (node as HTMLElement).setAttribute(attributeName, value);
  }

  /**
   *
   */
  public setText(node: any, text: string): void {
    (node as HTMLElement).innerHTML = text;
  }

  /**
   *
   */
  public addChild(parent: any, child: any): void {
    (parent as Element).appendChild(child);
  }

  /**
   *
   */
  public getParent(child: any): any {
    return (child as Element).parentElement;
  }

  /**
   *
   */
  public numChildren(parent: any): number {
    return (parent as Element).childElementCount;
  }
}
