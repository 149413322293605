import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { XSort } from '../../../core/XSort';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Returns the list of visually distinguishable segments.
 */
export class DistinguishableSegments extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const segments: WListOfSegments = args.getSegments(0);
    if (!segments) {
      return null;
    }
    if (segments.count === 0) {
      return segments;
    }

    const s: ContentElement[] = segments.items.concat();

    this.doMerge(s);

    return args.env.culture.listFactory.createList(s);
  }

  /**
   *
   */
  private doMerge(segments: ContentElement[]): void {
    for (let i: number = 0; i < segments.length - 1; i++) {
      for (let j: number = i + 1; j < segments.length; j++) {
        const o: WSegment = this.tryMerge(segments[i] as WSegment, segments[j] as WSegment);
        if (o) {
          segments[i] = o;
          segments.splice(j, 1);
          this.doMerge(segments);
          return;
        }
      }
    }
  }

  /**
   * Attempt to merge two segments into one.
   * Returns null if not possible.
   */
  private tryMerge(s1: WSegment, s2: WSegment): WSegment {
    if (XGeom.segmentsOverlap(s1.a, s1.b, s2.a, s2.b)) {
      const p: Point[] = [];
      p.push(s1.a, s1.b, s2.a, s2.b);
      p.sort(XSort.xycoordinate);
      const a: Point = p[0];
      const b: Point = p[3];
      return new WSegment(a, b);
    }
    return null;
  }
}
