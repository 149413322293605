import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import React from 'react';
import { observer } from 'mobx-react';
import { ThemeDark, ThemeName, ThemeDefault, ITheme } from '@scolab/publisher-ui-kit';
import { inject } from '../dependencyInjection/inject';
import { ThemeMediator } from '../mediators/ThemeMediator';

const themes: Record<ThemeName, ITheme> = {
  [ThemeName.dark]: (new ThemeDark()).theme,
  [ThemeName.light]: (new ThemeDefault()).theme,
};

@observer
export class ThemeProvider extends React.Component<{}> {
  @inject(ThemeMediator)
  private accessor themeMediator: ThemeMediator;

  public render(): React.ReactNode {
    const {
      children,
    } = this.props;
    return (
      <StyledThemeProvider theme={themes[this.themeMediator.theme]}>
        {children}
      </StyledThemeProvider>
    );
  }
}
