import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WNumber } from '../../elements/tokens/WNumber';
import { WVariable } from '../../elements/tokens/WVariable';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LongRuleOfThreeImpl implements IMarkupFactory {
  // part I
  private a: TokenElement;

  private b: TokenElement;

  private c: TokenElement;

  private d: TokenElement;

  // part II
  private n: RealElement;

  private o: RealElement;

  private p: RealElement;

  private x: WVariable;

  // Result
  private r: WNumber;

  //
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  constructor(
    a: TokenElement,
    b: TokenElement,
    c: TokenElement,
    d: TokenElement,
    n: RealElement,
    o: RealElement,
    p: RealElement,
    x: WVariable,
    culture: CultureInfo) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.n = n;
    this.o = o;
    this.p = p;
    this.x = x;
    this._culture = culture;
    this.r = this.solve(n, o, p);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    w.beginRow();
    this.writePartI(w);
    w.lineBreak();
    this.writePartII(w);
    w.endRow();
  }

  /**
   *
   */
  private solve(
    n: RealElement,
    o: RealElement,
    p: RealElement): WNumber {
    return this.culture.createNumber(XRound.safeRound(n.toNumber() * o.toNumber() / p.toNumber()));
  }

  /**
   *
   */
  private writePartI(w: MmlWriter): void {
    w.beginTable();

    // row 1
    w.beginTr();
    this.writeTokenTd(w, this.a);
    w.beginTd();
    w.appendOperator(String.fromCharCode(0x2192));
    w.endTd();
    this.writeTokenTd(w, this.b);
    w.endTr();

    // row 2
    w.beginTr();
    this.writeTokenTd(w, this.c);
    w.beginTd();
    w.appendOperator(String.fromCharCode(0x2192));
    w.endTd();
    this.writeTokenTd(w, this.d);
    w.endTr();

    w.endTable();

    /*
    <mtable>
      <mtr>
        <mtd><mi>a</mi></mtd>
        <mtd><mo>&#x2192;</mo></mtd>
        <mtd><mi>b</mi></mtd>
      </mtr>
      <mtr>
        <mtd><mi>c</mi></mtd>
        <mtd><mo>&#x2192;</mo></mtd>
        <mtd><mi>d</mi></mtd>
      </mtr>
    </mtable>;
    */
  }

  /**
   *
   */
  private writePartII(w: MmlWriter): void {
    // x = n * o / p

    w.beginRow();

    w.beginFraction();
    this.writeToken(w, this.p);
    this.writeToken(w, this.o);
    w.endFraction();

    w.appendOperator('=');

    w.beginFraction();
    this.writeToken(w, this.n);
    this.writeToken(w, this.x);
    w.endFraction();

    w.lineBreak();
    this.writeToken(w, this.x);

    w.appendOperator('=');

    w.beginFraction();
    w.beginRow();
    this.writeToken(w, this.n);
    w.appendOperator('×');
    this.writeToken(w, this.o);
    w.endRow();
    this.writeToken(w, this.p);
    w.endFraction();

    w.lineBreak();

    this.writeToken(w, this.x);
    w.appendOperator('=');
    this.writeToken(w, this.r);

    w.endRow();

    /*
    <mrow>
      <mfrac>
        <mi>p</mi>
        <mi>o</mi>
      </mfrac>
      <mo>&#x003D;</mo>
      <mfrac>
        <mi>n</mi>
        <mi>x</mi>
      </mfrac>
      <mspace linebreak="newline"/>
      <mi>x</mi>
      <mo>&#x003D;</mo>
      <mfrac>
        <mrow>
          <mi>n</mi>
          <mo>×</mo>
          <mi>o</mi>
        </mrow>
        <mi>p</mi>
      </mfrac>
      <mspace linebreak="newline"/>
      <mi>x</mi>
      <mo>&#x003D;</mo>
      <mi>r</mi>
    </mrow>
    */
  }

  /**
   *
   */
  private writeTokenTd(w: MmlWriter, token: TokenElement): void {
    w.beginTd();
    this.writeToken(w, token);
    w.endTd();
  }

  /**
   *
   */
  private writeToken(w: MmlWriter, token: TokenElement): void {
    if (token instanceof WVariable) {
      w.appendIdentifier(token.getSymbol());
    } else if (token instanceof WNumber) {
      w.appendNumber(token.toText(true));
    }
  }
}
