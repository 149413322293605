import React from 'react';
import { Editor } from '../../TypedSlate';
import { AddImagePopover } from './AddImagePopover';
import { ICustomEditor } from '../../models/editor';
import { Button } from '../../../Button/Button';
import ImageIcon from '../../../../../assets/image.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';
import { insertImage } from '../../utils/imageUtils';

interface IAddImageProps {
  editor: ICustomEditor;
  label: string;
  srcInputLabel: string;
  altInputLabel: string;
  widthInputLabel: string;
  heightInputLabel: string;
}

export const AddImageControl: React.FC<IAddImageProps> = (props) => {
  const {
    editor,
    label,
    srcInputLabel,
    altInputLabel,
    widthInputLabel,
    heightInputLabel,
  } = props;
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [rangeRef, setRangeRef] = React.useState(null);

  const onOpen = () => {
    setRangeRef(Editor.rangeRef(editor, editor.selection));
    setOpen(true);
  };

  const onClose = () => {
    rangeRef.unref();
    setRangeRef(null);
    setOpen(false);
  };

  const onAddImage = (src: string, altText: string, width: number, height: number) => {
    insertImage(editor, src, altText, width, height, rangeRef);
  };

  return (
    <>
      <Tooltip
        title={label}
      >
        <Button
          ref={anchorRef}
          onMouseDown={onOpen}
          aria-label={label}
          icon
        >
          <ImageIcon />
        </Button>
      </Tooltip>
      <AddImagePopover
        open={open}
        anchorEl={anchorRef.current}
        handleClose={onClose}
        onAddImage={onAddImage}
        srcInputLabel={srcInputLabel}
        altInputLabel={altInputLabel}
        widthInputLabel={widthInputLabel}
        heightInputLabel={heightInputLabel}
      />
    </>
  );
};
