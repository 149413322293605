import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WNumber } from '../elements/tokens/WNumber';
import { WScientificNumber } from '../elements/tokens/WScientificNumber';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { ScientificNotation } from '../expr/manipulation/alt/ScientificNotation';
import { InputCapabilities } from './InputCapabilities';
import { COptions } from './COptions';
import { CNumber } from './CNumber';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CScientificNumber extends BaseCorrector {
  private base: CNumber;

  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.base = new CNumber(
      new ScientificNotation(env.culture),
      null,
      true,
      false);
    this.base.configure(origin, new COptions(), env, useLatex);
  }

  // Convert the target for the underlying corrector
  private coerce(target: ContentElement): WNumber {
    return this.env.culture.createNumber((target as WScientificNumber).toNumber());
  }

  public parse(value: string): Node {
    return this.base.parse(value);
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    return this.base.correct(value, this.coerce(target));
  }

  public get inputCapabilities(): InputCapabilities {
    return this.base.inputCapabilities;
  }

  public get mathKeyboard(): number {
    return this.base.mathKeyboard;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    this.base.writeTo(w, this.coerce(target));
  }
}
