import { Point } from '../../js/geom/Point';

import { XSort } from '../core/XSort';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WListOfPoints } from '../elements/tokens/WListOfPoints';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { COptions } from './COptions';
import { CPoint } from './CPoint';
import { NumberOrPercent } from './NumberOrPercent';
import { BaseCorrector } from './BaseCorrector';

/**
 * Correction d'une liste de points.
 */
export class CListPts extends BaseCorrector {
  private plusMinus: NumberOrPercent;

  private cpoint: CPoint;

  constructor(
    plusMinus: NumberOrPercent) {
    super();
    this.plusMinus = plusMinus;
  }

  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.cpoint = new CPoint(this.plusMinus);
    super.configureOther(this.cpoint);
  }

  public parse(value: string): Node {
    const value2: Point[] = this.parseItems(this.translateInput(value));
    if (value2 != null) {
      const node: Node = new Node(this.env.culture.listFactory.createFromPoints(value2));
      const points: string[] = [];
      for (let i: number = 0; i < value2.length; i++) {
        points.push(`(${value2[i].x}, ${value2[i].y})`);
      }
      node.userData = `Listify(${points.join(',')})`;
      return node;
    }

    return null;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    let target2: Point[] = (target as WListOfPoints).toPoints();
    let value2: Point[] = this.parseItems(this.translateInput(value));

    if (value2 == null) {
      this.raiseError(
        CommonError.POINTS_LIST_FORMAT_INVALID,
        [this.env.culture.numberFormatter.decimalSeparator]);
    }

    if (value2.length !== target2.length) {
      return false;
    }

    if (!this.options.ordered) { // L'ordre n'a pas d'importance alors on ordonner les nombres avant de les comparer par index
      value2 = value2.sort(XSort.xycoordinate);
      target2 = target2.sort(XSort.xycoordinate);
    }

    for (let i: number = 0; i < value2.length; i++) {
      if (!(value2[i].equals(target2[i]))) {
        return false;
      }
    }

    return true;
  }

  private translateInput(value: string): string {
    if (this.useLatex) {
      return this.sanitizeInput(value);
    }
    return value;
  }

  private parseItems(value: string): Point[] {
    const temp: string[] = [];
    let s: string = '';
    let i: number;

    for (i = 0; i < value.length; i++) {
      const c: string = value.charAt(i);
      if (c === '(') {
        s += c;
      } else if (c === ')') {
        s += c;
        temp.push(s);
        s = '';
      } else if (s === '') { // outside
        // accept only comma and spaces
        if (c !== ' ' && c !== ',') {
          return null;
        }
      } else {
        s += c;
      }
    }

    const value2: Point[] = [];
    for (i = 0; i < temp.length; i++) {
      s = temp[i];
      const p: Point = this.cpoint.parsePoint(s);
      if (p == null) {
        return null;
      }
      value2.push(p);
    }

    return value2;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const points: WListOfPoints = target as WListOfPoints;

    for (let i: number = 0; i < points.count; i++) {
      const point: Point = points.getValueAt(i);
      if (i > 0) {
        w.writeRaw(', ');
      }
      const x: string = this.env.culture.formatNumber(point.x);
      const y: string = this.env.culture.formatNumber(point.y);
      w.writeRaw('(');
      w.writeNumber(point.x);
      w.writeRaw(`${this.env.culture.listFormatter.outputSeparator(x + y)} `);
      w.writeNumber(point.y);
      w.writeRaw(')');
    }
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.NUMERIC;
  }
}
