import React from 'react';
import styled, { css } from 'styled-components';
import { rafThrottle } from '../../../utils/rafThrottle';

interface IResizableColumnProps {
  readonly width: number;
  readonly onChangeWidth: (width: number) => void;
  readonly anchorPosition: 'left' | 'right';
  readonly onDragStart?: (event: React.MouseEvent, containerBox: DOMRect) => void;
  readonly className?: string;
  readonly disableDrag?: boolean;
}

export class ResizableColumn extends React.Component<IResizableColumnProps> {
  private containerRef: React.RefObject<HTMLDivElement>;

  private dragAnchorRef: React.RefObject<HTMLDivElement>;

  private containerBox: DOMRect;

  private throttledPointerMove: (event: PointerEvent) => void;

  constructor(props: IResizableColumnProps) {
    super(props);
    this.containerRef = React.createRef<HTMLDivElement>();
    this.dragAnchorRef = React.createRef<HTMLDivElement>();
    this.throttledPointerMove = rafThrottle(this.onPointerMove);
  }

  public componentWillUnmount(): void {
    this.removeListeners();
  }

  public render(): React.ReactNode {
    const {
      children,
      width,
      anchorPosition,
      className,
      disableDrag = false,
    } = this.props;

    const dragAnchor = disableDrag
      ? null
      : (
        <DragAnchor
          ref={this.dragAnchorRef}
          anchorPosition={anchorPosition}
          onPointerDown={this.onPointerDown}
        />
      );

    return (
      <Container
        ref={this.containerRef}
        width={width}
        className={className}
      >
        <ContainerChildren>
          {children}
        </ContainerChildren>
        {dragAnchor}
      </Container>
    );
  }

  private onPointerDown = (event: React.PointerEvent) => {
    event.preventDefault();
    this.containerBox = this.containerRef.current.getBoundingClientRect();
    if (event.isPrimary && event.button === 0) {
      this.props.onDragStart?.(event, this.containerBox);
      document.addEventListener('pointermove', this.throttledPointerMove);
      document.addEventListener('pointerleave', this.removeListeners);
      document.addEventListener('pointerup', this.removeListeners);
      document.body.setAttribute('style', 'cursor:col-resize !important;');
      this.dragAnchorRef.current.setPointerCapture(event.pointerId);
    }
  };

  private onPointerMove = (event: PointerEvent) => {
    event.preventDefault();
    let newWidth = 0;
    if (this.props.anchorPosition === 'right') {
      newWidth = event.clientX - this.containerBox.left - 5;
    } else {
      newWidth = this.containerBox.right - event.clientX - 5;
    }
    this.props.onChangeWidth(newWidth);
  };

  private removeListeners = (event?: PointerEvent) => {
    document.removeEventListener('pointermove', this.throttledPointerMove);
    document.removeEventListener('pointerleave', this.removeListeners);
    document.removeEventListener('pointerup', this.removeListeners);
    document.body.style.cursor = 'default';
    if (event) {
      this.dragAnchorRef.current.releasePointerCapture(event.pointerId);
    }
  };
}

const ContainerChildren = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

interface IContainer {
  width: number;
}

const Container = styled.div.attrs<IContainer>(props => ({
  style: {
    width: `${props.width === -1 ? '100%' : props.width + 'px'}`,
  },
}))<IContainer>`
  position: relative;
  transition: width 50ms;

  ${props => props.width === 0
    ? css`
    ${ContainerChildren} {
      visibility: hidden;
    }
  `
    : ''}
`;

interface IDragAnchor {
  anchorPosition: 'left' | 'right';
}

const DragAnchor = styled.div<IDragAnchor>`
  position: absolute;
  top: 0;
  ${props => props.anchorPosition === 'right' ? 'right: -8px;' : 'left: -8px;'}
  cursor: col-resize;
  width: 8px;
  height: 100%;
  display: block;
  z-index: 10;
`;
