import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WExponential } from '../elements/tokens/WExponential';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CExponential extends BaseCorrector {
  public parse(value: string): Node {
    const exp: WExponential = this.parseExponential(this.translateInput(value));
    if (exp) {
      const node: Node = new Node(exp);
      node.userData = exp.userData;
      return node;
    }
    return null;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const e: WExponential = this.parseExponential(this.translateInput(value));

    if (!e) {
      this.raiseError(CommonError.EXPONENTIAL_INVALID);
    }

    return e.equalsTo(target);
  }

  private translateInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
    }
    return value;
  }

  private parseExponential(valueArg: string): WExponential {
    let value = valueArg;
    // 2(3)<sup>x</sup>
    value = value.split(this.env.culture.numberFormatter.decimalSeparator).join('.');
    value = value.split(this.env.culture.numberFormatter.thousandSeparator).join('');
    value = value.split('<sup>').join('^(');
    value = value.split('</sup>').join(')');
    const element: ContentElement = this.env.expressions.toContentElement(value);
    if (element) {
      element.userData = value;
    }
    return element instanceof WExponential ? element : null;
  }

  public get inputCapabilities(): InputCapabilities {
    const i: InputCapabilities = new InputCapabilities();
    i.superscript = true;
    return i;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.EXPONENTIAL;
  }

  /**
   *
   */
  public writeTo(w: IWriter, target: ContentElement, ...targets: any[]): void {
    const e: WExponential = target as WExponential;

    if (e.coefficient.toNumber() === -1) {
      w.writeOperator('−', 'prefix');
    } else if (e.coefficient.toNumber() !== 1) {
      w.writeNumber(e.coefficient.toNumber());
    }

    if (e.encloseBase) {
      w.openParenthesis();
    }
    w.writeNumber(e.base.toNumber());
    if (e.encloseBase) {
      w.closeParenthesis();
    }

    w.writeSuperscriptSymbol(e.exponent.getSymbol());
  }
}
