import { Point } from '../../../../js/geom/Point';

import { XTypes } from '../../../core/XTypes';
import { ScriptFormat } from '../../../core/str/ScriptFormat';
import { AnonymousFunction } from '../../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { ElementCodes } from '../../../elements/abstract/ElementCodes';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { Node } from '../../../elements/abstract/Node';
import { OperatorElement } from '../../../elements/abstract/OperatorElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { SymbolElement } from '../../../elements/abstract/SymbolElement';
import { UserFunction } from '../../../elements/abstract/UserFunction';
import { Lambda } from '../../../elements/constructs/Lambda';
import { Root } from '../../../elements/constructs/Root';
import { WEulerConstant } from '../../../elements/tokens/WEulerConstant';
import { WId } from '../../../elements/tokens/WId';
import { WInfinity } from '../../../elements/tokens/WInfinity';
import { WNotANumber } from '../../../elements/tokens/WNotANumber';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WPi } from '../../../elements/tokens/WPi';
import { WString } from '../../../elements/tokens/WString';
import { WVariable } from '../../../elements/tokens/WVariable';
import { WXRef } from '../../../elements/tokens/WXRef';
import { Environment } from '../../../expr/Environment';
import { IFunctionsResolver } from '../../../expr/IFunctionsResolver';
import { IOperatorsResolver } from '../../../expr/IOperatorsResolver';
import { IVariablesResolver } from '../../../expr/IVariablesResolver';
import { GroupClose } from '../../../expr/conversion/input/tempTokens/GroupClose';
import { GroupOpen } from '../../../expr/conversion/input/tempTokens/GroupOpen';
import { LeftBracket } from '../../../expr/conversion/input/tempTokens/LeftBracket';
import { Parameter } from '../../../expr/conversion/input/tempTokens/Parameter';
import { QuestionMark } from '../../../expr/conversion/input/tempTokens/QuestionMark';
import { RightBracket } from '../../../expr/conversion/input/tempTokens/RightBracket';
import { Separator } from '../../../expr/conversion/input/tempTokens/Separator';
import { SetClose } from '../../../expr/conversion/input/tempTokens/SetClose';
import { SetOpen } from '../../../expr/conversion/input/tempTokens/SetOpen';
import { Word } from '../../../expr/conversion/input/tempTokens/Word';
import { SourceMap } from '../../../expr/conversion/input/SourceMap';
import { Recall } from '../../../elements/constructs/Recall';
import { IExpressionDependencies } from '../../../expr/IExpressionDependencies';

/**
 *
 */
export class RowWorker {
  private static STRING_DELIMITER: string = '"';

  private static ID_DELIMITER: string = '\'';

  private static IGNORE_CHARACTERS: string[] = ['⁠'];

  private static groupClose: GroupClose = new GroupClose();

  private static groupOpen: GroupOpen = new GroupOpen();

  private static leftBracket: LeftBracket = new LeftBracket();

  private static rightBracket: RightBracket = new RightBracket();

  private static setClose: SetClose = new SetClose();

  private static setOpen: SetOpen = new SetOpen();

  // From constructor
  private value: string;

  private variablesResolver: IVariablesResolver;

  private functionsResolver: IFunctionsResolver;

  private operatorsResolver: IOperatorsResolver;

  private env: Environment;

  private withSourceMap: boolean;

  private withValidation: boolean;

  // From execution
  private tokens: any[];

  private sourceMap: Point[];

  private hasLambda: boolean = false;

  private dependencies: { variables: string[]; functions: string[]; operators: string[] };

  constructor(
    value: string,
    variablesResolver: IVariablesResolver,
    functionsResolver: IFunctionsResolver,
    operatorsResolver: IOperatorsResolver,
    env: Environment,
    withSourceMap: boolean,
    withValidation: boolean = true) {
    this.value = value;
    this.variablesResolver = variablesResolver;
    this.functionsResolver = functionsResolver;
    this.operatorsResolver = operatorsResolver;
    this.env = env;
    this.withSourceMap = withSourceMap;
    this.withValidation = withValidation;

    // **********************************************
    this.tokens = value.split(''); // At the beginning, tokens is an array of characters
    this.dependencies = { variables: [], functions: [], operators: [] };
    if (withSourceMap) {
      this.sourceMap = [];
      this.sourceMap.push(new Point(0, value.length));
      this.breakSourceMap(0);
    }
    //

    this.collapseDelimitedSequence(RowWorker.STRING_DELIMITER, RowWorker.createString);
    this.collapseDelimitedSequence(RowWorker.ID_DELIMITER, RowWorker.createId);
    this.consecutiveIntegers();
    this.collapseNumbers(); // Convert every constant number into a content element
    this.collapseWords();
    this.collapseRefs();
    this.collapseMultiCharsOperators();
    this.operators();
    this.superscriptDigits();
    this.unambiguousCharacters();
    this.regroup();
    this.collapseSpaces();
    this.functions();

    if (this.hasLambda) {
      this.flagLambdaParameters();
    }

    if (env.options.preserveWords) {
      this.preserveWords();
    } else {
      this.parseSymbols();
      this.invisibleTimes(); // must be done before the substitution of variables
      this.invisiblePowers();
      this.substitutions();
    }

    if (this.hasLambda) {
      this.parameters();
    }

    if (env.options.extractMinus) {
      this.extractMinusFromValue();
    }

    this.ignoreCharacters();
  }

  /**
   *
   * @returns {any}
   */
  public getDependencies(): IExpressionDependencies {
    return this.dependencies;
  }

  /**
   *
   */
  public getTokens(): any[] {
    return this.tokens.concat();
  }

  /**
   *
   */
  public getSourceMap(): SourceMap {
    return new SourceMap(this.value, this.sourceMap, this.tokens);
  }

  /**
   *
   */
  private collapseSourceMap(beginIndex: number, endIndex: number, forgeSourceBefore: boolean = false): void {
    if (!this.withSourceMap) {
      return;
    }
    const beginIndexInSource = this.sourceMap[beginIndex].x;
    const endIndexInSource = this.sourceMap[endIndex - 1].y;
    this.sourceMap.splice(
      beginIndex,
      endIndex - beginIndex,
      new Point(beginIndexInSource, endIndexInSource));
    if (forgeSourceBefore) {
      this.sourceMap.splice(beginIndex, 0, new Point(beginIndexInSource, beginIndexInSource));
    }
    this.validateSourceMap();
  }

  /**
   *
   */
  private breakSourceMap(atIndex: number): void {
    if (!this.withSourceMap) {
      return;
    }
    const p: Point = this.sourceMap[atIndex];
    this.sourceMap.splice(atIndex, 1);
    let k = atIndex;
    for (let i: number = p.x; i < p.y; i++) {
      this.sourceMap.splice(k, 0, new Point(i, i + 1));
      k++;
    }
    this.validateSourceMap();
  }

  /**
   *
   */
  private notInSourceMap(atIndex: number): void {
    if (!this.withSourceMap) {
      return;
    }
    this.sourceMap.splice(atIndex, 0, null);
    this.validateSourceMap();
  }

  /**
   *
   */
  private removeFromSourceMap(atIndex: number): void {
    if (!this.withSourceMap) {
      return;
    }
    this.sourceMap.splice(atIndex, 1);
    this.validateSourceMap();
  }

  /**
   *
   */
  private noChangeToSourceMap(atIndex: number): void {
    if (!this.withSourceMap) {
      return;
    }
    this.validateSourceMap();
  }

  /**
   *
   */
  private validateSourceMap(): void {
    if (!this.withSourceMap) {
      return;
    }
    if (this.tokens.length !== this.sourceMap.length) {
      throw new Error('Invalid source map, count mismatch');
    }

    let y = 0;
    for (let i = 0; i < this.sourceMap.length; i++) {
      if (!this.sourceMap[i]) {
        continue;
      }
      if (this.sourceMap[i].x < y) {
        throw new Error('Invalid source map, order mismatch');
      }
      y = this.sourceMap[i].y;
    }
  }

  /**
   *
   */
  private collapseDelimitedSequence(
    delimiterChar: string,
    factory: Function): void {
    let i: number = 0;
    let beginIndex: number = -1;
    while (i < this.tokens.length) {
      if (this.tokens[i] === delimiterChar) {
        if (beginIndex === -1) {
          beginIndex = i;
        } else {
          this.tokens.splice(i, 1); // remove closing quotes
          this.tokens.splice(beginIndex, 1); // remove open quotes
          const s: string = this.tokens.splice(beginIndex, i - beginIndex - 1).join('');
          this.tokens.splice(beginIndex, 0, factory(s));

          if (this.withSourceMap) {
            this.collapseSourceMap(beginIndex, i + 1);
          }

          i = beginIndex;
          beginIndex = -1;
        }
      }
      i++;
    }
  }

  /**
   *
   */
  private static createId(value: string): WId {
    return new WId(value);
  }

  /**
   *
   */
  private static createString(value: string): WString {
    return new WString(value);
  }

  /**
   *
   */
  private collapseNumbers(): void {
    this.collapse(this.collapseNumber.bind(this), this.continueNumber.bind(this));
  }

  /**
   *
   */
  private continueNumber(token: string): boolean {
    return String(token) >= '0' && String(token) <= '9' || String(token) === '.';
  }

  /**
   *
   */
  private collapseNumber(numberTokens: any[]): ContentElement {
    const number: number = Number(numberTokens.join('')); // WARNING: Number("") --> 0
    if (!isNaN(number)) {
      return this.env.culture.createNumber(number);
    }
    return WNotANumber.getInstance();
  }

  /**
   *
   */
  private collapseWords(): void {
    this.collapse(this.collapseWord.bind(this), this.continueWord.bind(this));
  }

  private continueWord(token: string): boolean {
    return token >= 'A' && token <= 'Z' || token >= 'a' && token <= 'z';
  }

  private collapseWord(letters: any[]): Word {
    return new Word(letters.join(''));
  }

  /**
   * Merge every # followed by a word into a WXRef object
   */
  private collapseRefs(): void {
    let i: number = 0;
    while (i < this.tokens.length - 1) {
      if (this.tokens[i] === '#') {
        if (this.tokens[i + 1] instanceof Word) {
          this.tokens.splice(i, 2, new WXRef((this.tokens[i + 1] as Word).toString()));
          if (this.withSourceMap) {
            this.collapseSourceMap(i, i + 1);
          }
          continue;
        }
      }
      i++;
    }
  }

  private consecutiveIntegers(): void {
    let i: number = 1;
    while (i < this.tokens.length) {
      if (this.tokens[i] === '.' && this.tokens[i - 1] === '.') {
        this.tokens.splice(i - 1, 2, this.operatorsResolver.resolveOperator('..'));
        this.dependencies.operators.push('..');
        if (this.withSourceMap) {
          this.collapseSourceMap(i - 1, i + 1);
        }
      } else {
        i++;
      }
    }
  }

  private createOperator(opArg: string): Object {
    let op = opArg;
    if (this.operatorsResolver) {
      if (op === '*') {
        op = this.env.culture.configuration.asteriskMultiplicationSign;
      }

      const operatorImpl: OperatorElement = this.operatorsResolver.resolveOperator(op);
      if (operatorImpl) {
        this.dependencies.operators.push(op);
        return operatorImpl;
      }
    }

    if (op === 'λ') {
      this.hasLambda = true;
    }

    switch (op) {
      case ',': return Separator.COMMA;
      case ';': return Separator.SEMICOLON;
      case '√': return new Root('2');
      case '∛': return new Root('3');
      case '∜': return new Root('4');
      case 'λ': return new Lambda();
      case '?': return new QuestionMark();
      case '@': return new Recall();
    }

    return null;
  }

  private collapseMultiCharsOperators(): void {
    let i: number = 1;
    while (i < this.tokens.length) {
      if (this.tokens[i] === '=' && this.tokens[i - 1] === '<') {
        this.tokens.splice(i - 1, 2, this.createOperator('≤'));
        if (this.withSourceMap) {
          this.collapseSourceMap(i - 1, i + 1);
        }
      } else if (this.tokens[i] === '=' && this.tokens[i - 1] === '>') {
        this.tokens.splice(i - 1, 2, this.createOperator('≥'));
        if (this.withSourceMap) {
          this.collapseSourceMap(i - 1, i + 1);
        }
      } else {
        i++;
      }
    }
  }

  private operators(): void {
    for (let i: number = 0; i < this.tokens.length; i++) {
      if (!XTypes.isString(this.tokens[i])) {
        continue;
      }
      const operator: Object = this.createOperator(this.tokens[i]);
      if (operator) {
        this.tokens.splice(i, 1, operator);
        if (this.withSourceMap) {
          this.noChangeToSourceMap(i);
        }
      }
    }
  }

  private superscriptDigits(): void {
    this.collapse(
      this.collapseSuperscript.bind(this),
      ScriptFormat.isSuperscript,
      this.operatorsResolver.resolveOperator('^'));
  }

  private collapseSuperscript(
    superscriptTokens: any[]): WNumber {
    const n: any[] = [];
    for (let i: number = 0; i < superscriptTokens.length; i++) {
      n.push(ScriptFormat.getDigitFromSuperscript(superscriptTokens[i]));
    }
    return this.env.culture.createNumber(Number(n.join('')));
  }

  private unambiguousCharacters(): void {
    for (let i: number = 0; i < this.tokens.length; i++) {
      if (!XTypes.isString(this.tokens[i])) {
        continue;
      }
      let hasChanged: boolean = true;
      switch (this.tokens[i]) {
        case 'π':
        case 'Π':
          this.tokens.splice(i, 1, new WPi(this.env.culture.createNumber(Math.PI)));
          break;
        case 'ℇ': // U+2107
          this.tokens.splice(i, 1, new WEulerConstant(this.env.culture.createNumber(Math.E)));
          break;
        case '∞':
          this.tokens.splice(i, 1, WInfinity.POSITIVE);
          break;
        case '∅':
          this.tokens.splice(i, 1, this.env.culture.createEmptySet());
          break;
        default:
          if (this.env.culture.createNumbers(this.tokens[i])) {
            this.tokens.splice(i, 1, this.env.culture.createNumbers(this.tokens[i]));
          } else {
            hasChanged = false;
          }
          break;
      }
      if (hasChanged) {
        if (this.withSourceMap) {
          this.noChangeToSourceMap(i);
        }
      }
    }
  }

  private regroup(): void {
    try {
      const stack: any[] = [];

      for (let i: number = 0; i < this.tokens.length; i++) {
        switch (this.tokens[i]) {
          case '(':
            stack.push(RowWorker.groupOpen);
            this.tokens[i] = stack[stack.length - 1];
            break;
          case '{':
            stack.push(RowWorker.setOpen);
            this.tokens[i] = stack[stack.length - 1];
            break;
          case ')':
            if (stack.length === 0) {
              throw new Error();
            }
            if (!(stack[stack.length - 1] instanceof GroupOpen)) {
              throw new Error();
            }
            stack.pop();
            this.tokens[i] = RowWorker.groupClose;
            break;
          case '}':
            if (stack.length === 0) {
              throw new Error();
            }
            if (!(stack[stack.length - 1] instanceof SetOpen)) {
              throw new Error();
            }
            stack.pop();
            this.tokens[i] = RowWorker.setClose;
            break;
          case '[':
          case ']':

            this.tokens[i]
              = this.tokens[i] === '['
                ? RowWorker.leftBracket
                : RowWorker.rightBracket;

            if (stack.length === 0) {
              stack.push(this.tokens[i]);
            } else if (stack[stack.length - 1] instanceof LeftBracket
              || stack[stack.length - 1] instanceof RightBracket) {
              stack.pop();
            } else {
              stack.push(this.tokens[i]);
            }

            break;
        }
      }

      if (stack.length > 0) {
        throw new Error(); // unclosed group(s)
      }

      let k: number = 0;
      while (k < this.tokens.length - 1) { // insert invisible times between )( --> )&it;(
        if (this.tokens[k] instanceof GroupClose && this.tokens[k + 1] instanceof GroupOpen) {
          this.tokens.splice(k + 1, 0, this.operatorsResolver.resolveOperator('\u2062'));
          this.dependencies.operators.push('\u2062');
          if (this.withSourceMap) {
            this.notInSourceMap(k + 1);
          }
          k += 2;
        } else {
          k++;
        }
      }
    } catch (e) {
      if (this.withValidation) {
        throw e;
      }
    }
  }

  /**
   * Look for every Word, followed by GroupOpen, if the word is found in the
   * functions dictionary then it is converted to an actual function reference.
   */
  private functions(): void {
    for (let i: number = 0; i < this.tokens.length - 1; i++) {
      if (this.tokens[i] instanceof Word && this.tokens[i + 1] instanceof GroupOpen) {
        const word: Word = this.tokens[i] as Word;

        // User functions
        if (this.variablesResolver) {
          try {
            const wordDefinition: Node = this.variablesResolver.getSubstitution(word.toString());
            if (wordDefinition) {
              const anonymousFunction: AnonymousFunction
                = wordDefinition.value instanceof AnonymousFunction
                  ? wordDefinition.value
                  : null;

              if (anonymousFunction) {
                const userFunction: UserFunction
                  = new UserFunction(
                    word.toString(),
                    anonymousFunction.node.clone(),
                    anonymousFunction.range);
                this.tokens.splice(i, 1, userFunction);
                this.dependencies.variables.push(word.toString());
                if (this.withSourceMap) {
                  this.noChangeToSourceMap(i);
                }
                continue;
              }
            }
          } catch (e) {
            // console.log(e.message);
          }
        }

        if (this.functionsResolver) {
          // Built-in functions
          const builtInFunction: FunctionElement = this.functionsResolver.resolveFunction(word.toString());
          if (builtInFunction != null) {
            this.tokens.splice(i, 1, builtInFunction);
            this.dependencies.functions.push(word.toString());
            if (this.withSourceMap) {
              this.noChangeToSourceMap(i);
            }
          }
        }
      }
    }
  }

  /**
   *
   */
  private flagLambdaParameters(): void {
    // Lambda, GroupOpen, word-separator pair, body GroupClose
    let i: number = 0;
    let parameter: Word;
    const parameters: string[] = [];
    const nParameters: number[] = []; // number of parameters for the current lambda.
    const nesting: number[] = []; // count GroupOpen/GroupClose to detect the lambda closing token.
    while (i < this.tokens.length - 1) {
      if (this.tokens[i] instanceof Lambda && this.tokens[i + 1] instanceof GroupOpen) {
        nParameters.push(0);
        nesting.push(0);
        i += 2; // jump over lambda and (
        while (i < this.tokens.length - 1) {
          if (this.tokens[i] instanceof Word && this.tokens[i + 1] instanceof Separator) {
            parameter = this.tokens[i] as Word;
            this.tokens.splice(i, 1, new Parameter(parameter.toString()));
            parameters.push(parameter.toString());
            nParameters[nParameters.length - 1]++;
          } else {
            break;
          }
        }
      } else if (this.tokens[i] instanceof GroupOpen) {
        if (nesting.length > 0) {
          nesting[nesting.length - 1]++;
        }
        i++;
      } else if (this.tokens[i] instanceof GroupClose) {
        if (nesting.length > 0) {
          nesting[nesting.length - 1]--;
          if (nesting[nesting.length - 1] === 0) {
            // close lambda
            nesting.pop();
            let k: number = nParameters.pop();
            while (k > 0) {
              parameters.pop();
              k--;
            }
          }
        }
        i++;
      } else if (this.tokens[i] instanceof Word) {
        parameter = this.tokens[i] as Word;

        const pName: string = parameter.toString();

        if (parameters.indexOf(pName) !== -1) {
          // match one of the parameter names, treat as a parameter value
          this.tokens.splice(i, 1, new Parameter(pName));
          i++;
        } else if (pName === 'pi') {
          // leave as a Word, will be replaced with PI constant later
          i++;
        } else if (this.variablesResolver && this.variablesResolver.isDefined(pName)) {
          // match a defined variable, do not treat as a parameter
          i++;
        } else {
          // split the word into characters, if one of the letter match a parameter
          // then treat as a parameter, otherwise leave it as a Word to be treated
          // later.

          let doSplit: boolean = false;
          let j: number;
          for (j = 0; j < pName.length; j++) {
            if (parameters.indexOf(pName.charAt(j)) !== -1) {
              doSplit = true;
              break;
            }
          }

          if (doSplit) {
            this.tokens.splice(i, 1);
            for (j = 0; j < pName.length; j++) {
              if (parameters.indexOf(pName.charAt(j)) !== -1) {
                this.tokens.splice(i, 0, new Parameter(pName.charAt(j)));
              } else {
                this.tokens.splice(i, 0, new Word(pName.charAt(j)));
              }
              i++;
            }

            this.breakSourceMap(i - pName.length);
          } else {
            i++;
          }
        }
      } else {
        i++;
      }
    }
  }

  /**
   *
   */
  private preserveWords(): void {
    for (let i: number = 0; i < this.tokens.length; i++) {
      if (this.tokens[i] instanceof Word) {
        this.tokens.splice(i, 1, new WString((this.tokens[i] as Word).toString()));
      }
    }
  }
  // ***********************************

  private parseSymbols(): void {
    let i: number = 0;
    let k: number;
    while (i < this.tokens.length) {
      if (this.tokens[i] instanceof Word) {
        const w: string = (this.tokens[i] as Word).toString();
        let def: boolean = false;
        if (this.variablesResolver) {
          def = this.variablesResolver.isDefined(w);
        }

        if (def) {
          if (this.env.options.preserveVariables) {
            this.tokens[i] = this.variablesResolver.getVariable(w);
          } else {
            this.tokens[i] = this.env.culture.createVariable(w);
          }
        } else if (w.toLowerCase() === 'pi') {
          this.tokens[i] = new WPi(this.env.culture.createNumber(Math.PI));
        } else {
          const variables: any[] = [];

          for (k = 0; k < w.length; k++) {
            const c: string = w.charAt(k);
            if (this.variablesResolver) {
              // The choice between new Variable and context.variable()
              // is important here, use context.variable when we are
              // sure the variable won't be substituted.
              if (this.variablesResolver.isDefined(c)) {
                variables.push(this.env.culture.createVariable(c));
              } else {
                variables.push(this.variablesResolver.getVariable(c));
              }
            } else {
              variables.push(this.env.culture.createVariable(c));
            }
          }

          variables.unshift(i, 1);
          this.tokens.splice.apply(this.tokens, variables);
          if (this.withSourceMap) {
            this.breakSourceMap(i);
          }
        }
      }

      i++;
    }
  }

  /**
   * Insert invisible times at some places
   *
   * a) Between symbols/parameters.
   * b) Between a number followed by a symbol/parameter.
   * c) Between number or symbol/parameter followed by groupopen.
   * d) Between groupclose followed by number or symbol/parameter.
   */
  private invisibleTimes(): void {
    let i: number = 1; // we check the previous element so start at 1
    while (i < this.tokens.length) {
      const previous: Object = this.tokens[i - 1]; // the previous token
      if (this.tokens[i] instanceof SymbolElement
        || this.tokens[i] instanceof Parameter
        || this.tokens[i] instanceof GroupOpen
        || this.tokens[i] instanceof Root) {
        if (previous instanceof SymbolElement || previous instanceof Parameter || previous instanceof WNumber) {
          this.tokens.splice(i, 0, this.operatorsResolver.resolveOperator('\u2062'));
          this.dependencies.operators.push('\u2062');
          if (this.withSourceMap) {
            this.notInSourceMap(i);
          }
        }
      }

      if (previous instanceof GroupClose) {
        if (this.tokens[i] instanceof SymbolElement || this.tokens[i] instanceof Parameter || this.tokens[i] instanceof WNumber) {
          this.tokens.splice(i, 0, this.operatorsResolver.resolveOperator('\u2062'));
          this.dependencies.operators.push('\u2062');
          if (this.withSourceMap) {
            this.notInSourceMap(i);
          }
        }
      }

      i++;
    }
  }

  /**
   * Insert power element at strategic places.
   *
   * a) Between a symbol followed by an integer.
   */
  private invisiblePowers(): void {
    let i: number = 1; // we check the previous element so start at 1
    while (i < this.tokens.length) {
      if (this.tokens[i] instanceof WNumber) { // the current token
        if (this.tokens[i - 1] instanceof SymbolElement || this.tokens[i - 1] instanceof Parameter) { // the previous token
          this.tokens.splice(i, 0, this.operatorsResolver.resolveOperator('^'));
          this.dependencies.operators.push('^');
          this.notInSourceMap(i);
        }
      }
      i++;
    }
  }

  private substitutions(): void {
    if (this.env.options.preserveVariables) {
      return;
    }

    for (let i: number = 0; i < this.tokens.length; i++) {
      const token: Object = this.tokens[i];
      if (this.variablesResolver && token instanceof WVariable) {
        const variableName: string = token.getSymbol();
        if (this.variablesResolver.isDefined(variableName)) {
          const substitution = this.variablesResolver.getSubstitution(variableName);
          this.tokens.splice(i, 1, substitution);
          this.dependencies.variables.push(variableName);
          if (this.withSourceMap) {
            substitution.dependencies = {
              variables: [variableName],
            };
            this.noChangeToSourceMap(i);
          }
        }
      }
    }
  }

  private parameters(): void {
    for (let i: number = 0; i < this.tokens.length; i++) {
      if (this.tokens[i] instanceof Parameter) {
        const parameter: Parameter = this.tokens[i] as Parameter;
        this.tokens.splice(i, 1, new WVariable(parameter.name, this.env.culture.numberFormatter));
        if (this.withSourceMap) {
          this.noChangeToSourceMap(i);
        }
      }
    }
  }

  private extractMinusFromValue(): void {
    if (this.tokens.length > 2) {
      for (let i: number = 0; i < this.tokens.length - 1; i++) {
        if (this.checkIfMinusOrPlusAndNegativeNumber(this.tokens[i], this.tokens[i + 1])) {
          const op = (this.tokens[i] as ContentElement).getElementCode() === ElementCodes.OP_ADDITION ? '-' : '+';
          this.tokens[i] = this.operatorsResolver.resolveOperator(op);
          this.dependencies.operators.push(op);
          const node: Node = this.tokens[i + 1] as Node;
          node.value = (node.value as RealElement).toAbsoluteValue();
        }
      }
    }
  }

  private checkIfMinusOrPlusAndNegativeNumber(currentToken: Object, nextToken: Object): boolean {
    if (!this.isMinusOrPlus(currentToken)) {
      return false;
    }
    return this.isNegativeToken(nextToken);
  }

  private isMinusOrPlus(token: Object): boolean {
    const element: ContentElement = token instanceof ContentElement ? token : null;
    if (!element) {
      return false;
    }
    return element.getElementCode() === ElementCodes.OP_ADDITION
      || element.getElementCode() === ElementCodes.OP_SUBTRACTION;
  }

  private isNegativeToken(token: Object): boolean {
    if (!(token instanceof Node)) {
      return false;
    }
    const node: Node = token;
    if (!(node.value instanceof RealElement)) {
      return false;
    }
    return node.value.toNumber() < 0;
  }

  /**
   * Collapse the extra spaces and non-breaking spaces.
   */
  private collapseSpaces(): void {
    let i: number = 0;
    while (i < this.tokens.length) {
      const c: boolean
        = XTypes.isString(this.tokens[i])
        && (String(this.tokens[i]) === ' '
          || String(this.tokens[i]) === '\u00A0');
      if (c) {
        this.tokens.splice(i, 1);
        if (this.withSourceMap) {
          this.removeFromSourceMap(i);
        }
      } else {
        i++;
      }
    }
  }

  /**
   * Ignore specific characters:
   * - WordJoiner (U+2060)
   */
  private ignoreCharacters(): void {
    let i: number = 0;
    while (i < this.tokens.length) {
      const c: boolean
        = XTypes.isString(this.tokens[i])
        && RowWorker.IGNORE_CHARACTERS.includes(String(this.tokens[i]));
      if (c) {
        this.tokens.splice(i, 1);
        if (this.withSourceMap) {
          this.removeFromSourceMap(i);
        }
      } else {
        i++;
      }
    }
  }

  /**
   * Generic function for collapsing
   * collapser: function that perform the collapse operation
   * function(tokens):void
   * filter:
   * function(token):Boolean
   * tokenToPrepend: token to be added before the new token obtained from collapsing.
   */
  private collapse(
    collapser: Function,
    whileFunction: Function,
    tokenToPrepend: Object = null): void {
    let beginIndex: number = -1;
    let k: number = 0;

    let oldTokens: any[];
    let newToken: Object;
    while (k < this.tokens.length) {
      let collapseBoundary: boolean = false;
      if (this.tokens[k] instanceof ContentElement || !whileFunction(String(this.tokens[k]))) {
        collapseBoundary = true;
      }

      if (!collapseBoundary) {
        if (beginIndex === -1) {
          beginIndex = k;
        }
        k++;
      } else if (beginIndex !== -1) {
        oldTokens = this.tokens.slice(beginIndex, k);
        newToken = collapser(oldTokens);
        if (tokenToPrepend) {
          this.tokens.splice(beginIndex, oldTokens.length, tokenToPrepend, newToken);
        } else {
          this.tokens.splice(beginIndex, oldTokens.length, newToken);
        }

        if (this.withSourceMap) {
          this.collapseSourceMap(beginIndex, k, tokenToPrepend !== null);
        }
        k = beginIndex + 1;
        beginIndex = -1;
      } else {
        k++;
      }
    }
    if (beginIndex !== -1) {
      oldTokens = this.tokens.slice(beginIndex, k);
      newToken = collapser(oldTokens);
      if (tokenToPrepend) {
        this.tokens.splice(beginIndex, oldTokens.length, tokenToPrepend, newToken);
      } else {
        this.tokens.splice(beginIndex, oldTokens.length, newToken);
      }
      if (this.withSourceMap) {
        this.collapseSourceMap(beginIndex, k, tokenToPrepend !== null);
      }
    }
  }
}
