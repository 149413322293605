import { MathError } from '../../core/MathError';
import { XMath } from '../../core/XMath';
import { XNumber } from '../../core/XNumber';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { RealElement } from '../../elements/abstract/RealElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { BaseRationalFormatter } from '../../elements/formats/BaseRationalFormatter';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 *
 */
export class WRational extends RealElement {
  /**
   * NOTE: All theses properties are exposed as readonly, we never want to change an instance of ContentElement after instanciation.
   * NOTE: It's a design decision that prevent a lot of little mistakes.
   */
  private _numerator: number;

  public get numerator(): number {
    return this._numerator;
  }

  private _denominator: number;

  public get denominator(): number {
    return this._denominator;
  }

  private _formatter: BaseRationalFormatter;

  public get formatter(): BaseRationalFormatter {
    return this._formatter;
  }

  constructor(
    numerator: number,
    denominator: number,
    formatter: BaseRationalFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    if (!XMath.isInteger(numerator)) {
      throw new MathError('Integer numerator required');
    }
    if (!XMath.isInteger(denominator)) {
      throw new MathError('Integer denominator required');
    }
    this._numerator = numerator;
    this._denominator = denominator;
    this._formatter = formatter;
  }

  public get isNegative(): boolean {
    return this.toNumber() < 0;
  }

  public applyFormat(formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseRationalFormatter) {
      return new WRational(
        this.numerator,
        this.denominator,
        formatter);
    }
    return this;
  }

  public getFormat(): AbstractFormatter {
    return this._formatter;
  }

  public get isImproper(): boolean {
    if (Math.abs(this.toNumber()) >= 1) {
      if (this.formatter instanceof FractionFormatter) {
        const notation: FractionFormatter = this.formatter;
        return !notation.mixed && Math.abs(this.toNumber()) - notation.integer >= 1;
      }
    }
    return false;
  }

  public get isMixed(): boolean {
    if (Math.abs(this.toNumber()) >= 1) {
      if (this.formatter instanceof FractionFormatter) {
        const notation: FractionFormatter = this.formatter;
        return notation.mixed || notation.integer > 0;
      }
    }
    return false;
  }

  public toNumber(): number {
    return this.numerator / this.denominator;
  }

  public toAbsoluteValue(): RealElement {
    return new WRational(
      Math.abs(this.numerator),
      Math.abs(this.denominator),
      this.formatter);
  }

  /**
   *
   */
  public toOpposite(): RealElement {
    return new WRational(
      -1 * this.numerator,
      this.denominator,
      this.formatter);
  }

  /**
   *
   */
  public narrow(): ContentElement {
    return new WNumber(this.toNumber(), 1, false, this.formatter.culture.numberFormatter);
  }

  /**
   *
   */
  public setNumerator(numerator: number): WRational {
    if (!XMath.isInteger(numerator)) {
      throw new MathError('WRational FATAL setNumerator() expected integer received float');
    }
    return new WRational(
      numerator,
      this.denominator,
      this.formatter);
  }

  /**
   *
   */
  public setDenominator(denominator: number): WRational {
    if (!XMath.isInteger(denominator)) {
      throw new MathError('Expected integer denominator');
    }
    return new WRational(
      this.numerator,
      denominator,
      this.formatter);
  }

  /**
   *
   */
  public get reverse(): WRational {
    const n: boolean = this.toNumber() < 0;
    return new WRational(
      Math.abs(this.denominator) * (n ? -1 : 1),
      Math.abs(this.numerator),
      this.formatter);
  }

  /**
   *
   */
  public normalize(simplify: boolean): RealElement {
    return simplify
      ? this.reduce()
      : new WRational(
        this.numerator,
        this.denominator,
        this.formatter);
  }

  /**
   * Returns a simplified rational. If the rational can be represented
   * as an integer, will return a number unless useRational is set to true.
   *
   * -useRational:
   */
  public reduce(useRational: boolean = false): RealElement {
    let n: number = this.numerator;
    let d: number = this.denominator;

    const nf: BaseNumberFormatter = this.formatter.culture.numberFormatter;

    if (n === 0) {
      return useRational ? this : new WNumber(0, 1, false, nf);
    }
    if (n === d) {
      return useRational ? this : new WNumber(1, 1, false, nf); // 2/2 --> 1
    }

    const k: number = XMath.gcd(n, d);

    if (Math.abs(d / k) === 1) {
      return useRational ? this : new WNumber(n, d, false, nf); // n/1 --> n
    }

    if (k !== 1) { // need simplification
      n /= k;
      d /= k;
    }

    if (n < 0 && d < 0) { // -1/-2 --> 1/2
      n = -1 * n;
      d = -1 * d;
    }

    return new WRational(n, d, this.formatter);
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WRational) {
      return XMath.safeEquals(this.numerator, value.numerator)
        && XMath.safeEquals(this.denominator, value.denominator);
    }
    return false;
  }

  /**
   *
   */
  public cleanNegativeSign(): WRational {
    if ((this.numerator < 0 && this.denominator < 0) || this.denominator < 0) {
      return new WRational(-this.numerator, -this.denominator, this.formatter);
    }
    return this;
  }

  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      if (!this.formatter.writeTo(exporter, this.numerator, this.denominator)) {
        exporter.writeText(this.formatter.toLocaleString(this.numerator, this.denominator));
      }
    }
    return true;
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    if (strict) {
      return null;
    }
    return this.formatter.toLocaleString(this.numerator, this.denominator);
  }

  /**
   *
   * @returns {null}
   */
  public toSpeakText(): string {
    return this.formatter.toSpeakText(this.numerator, this.denominator);
  }

  /**
   *
   */
  public toString(): string {
    return `${String(this.numerator)}/${String(this.denominator)}`;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.TOKEN_RATIONAL;
  }

  /**
   *
   */
  public static parseElement(element: ContentElement): WRational {
    return element instanceof WRational ? element : null;
  }

  /**
   * Parse two decimal numbers and creates and
   */
  public static parseNumbers(
    nArg: number,
    dArg: number,
    format: BaseRationalFormatter): WRational {
    let n = nArg;
    let d = dArg;
    const k: number = XNumber.decimals(n, d);

    if (k > 0) {
      n *= (10 ** k);
      d *= (10 ** k);
    }

    if (d < 0) {
      n *= -1;
      d *= -1;
    }

    n = Math.round(n);
    d = Math.round(d);

    return new WRational(n, d, format);
  }

  /**
   *
   */
  public static rationalize(
    value: number,
    maxden: number,
    format: BaseRationalFormatter): WRational {
    const c: number = value < 0 ? -1 : 1;
    const m: any[] = [[1, 0], [0, 1]];
    let x: number = Math.abs(value);
    let ai: number = Math.floor(x);

    while (m[1][0] * ai + m[1][1] <= maxden) {
      let t: number = m[0][0] * ai + m[0][1];
      m[0][1] = m[0][0];
      m[0][0] = t;
      t = m[1][0] * ai + m[1][1];
      m[1][1] = m[1][0];
      m[1][0] = t;

      if (x === ai) {
        break;
      }
      x = 1 / (x - ai);
      if (x > 0x7FFFFFFF) {
        break;
      }
      ai = Math.floor(x);
    }

    if (m[1][0] !== 0 && m[0][0] !== 0) {
      return new WRational(c * m[0][0], m[1][0], format);
    }

    ai = (maxden - m[1][1]) / m[1][0];
    m[0][0] = m[0][0] * ai + m[0][1];
    m[1][0] = m[1][0] * ai + m[1][1];

    if (m[1][0] !== 0) {
      return new WRational(c * m[0][0], m[1][0], format);
    }

    return new WRational(0, 1, format);
  }

  /**
   *
   */
  public getType(): string {
    return 'rational';
  }
}
