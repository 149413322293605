import { Point } from '../../js/geom/Point';

import { Delegate } from '../../js/utils/Delegate';

import { Match } from '../core/Match';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { SetElement } from '../elements/abstract/SetElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { IntervalsFactory } from '../elements/factories/IntervalsFactory';
import { IntervalClosure } from '../elements/models/IntervalClosure';
import { WComplement } from '../elements/tokens/WComplement';
import { WFiniteSet } from '../elements/tokens/WFiniteSet';
import { WInfinity } from '../elements/tokens/WInfinity';
import { WInterval } from '../elements/tokens/WInterval';
import { WList } from '../elements/tokens/WList';
import { WNumber } from '../elements/tokens/WNumber';
import { WNumberSet } from '../elements/tokens/WNumberSet';
import { WPoint } from '../elements/tokens/WPoint';
import { WSetBuilder } from '../elements/tokens/WSetBuilder';
import { WString } from '../elements/tokens/WString';
import { WUnion } from '../elements/tokens/WUnion';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { COptions } from './COptions';
import { CList } from './CList';
import { BaseCorrector } from './BaseCorrector';
import { IntervalsNotations } from '../localization/IntervalsNotations';

export class CSet extends BaseCorrector {
  //
  private expectEnclosed: boolean;

  private acceptIntervals: boolean;

  private acceptSetBuilders: boolean;

  private acceptSetNumbers: boolean;

  private emptySetNotation: string;

  //
  private intervalsFactory: IntervalsFactory;

  private clist: CList;

  constructor(
    expectEnclosed: boolean,
    acceptIntervals: boolean,
    acceptSetBuilders: boolean,
    acceptSetNumbers: boolean,
    emptySetNotation: string) {
    super();
    this.expectEnclosed = expectEnclosed;
    this.acceptIntervals = acceptIntervals;
    this.acceptSetBuilders = acceptSetBuilders;
    this.acceptSetNumbers = acceptSetNumbers;
    this.emptySetNotation = emptySetNotation;
  }

  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.intervalsFactory = new IntervalsFactory(env.culture);
    this.clist = new CList();
    super.configureOther(this.clist);
  }

  public parse(value: string): Node {
    const interval: WInterval = this.parseInterval(this.translateInput(value));
    if (interval) {
      const node: Node = new Node(interval);
      node.userData
        = (interval.closure.lower ? '[' : ']')
        + String(interval.lBoundN) + ', '
        + String(interval.rBoundN)
        + (interval.closure.upper ? ']' : '[');
      return node;
    }
    return null;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    return this.checkSet(this.translateInput(value), target as SetElement);
  }

  /**
   *
   */
  private checkSet(value: string, target: SetElement): boolean {
    if (target instanceof WFiniteSet) {
      return this.checkFiniteSet(value, target);
    }
    if (target instanceof WInterval) {
      return this.checkInterval(value, target);
    }
    if (target instanceof WUnion) {
      return this.checkUnion(value, target);
    }
    if (target instanceof WSetBuilder) {
      return this.checkSetBuilder(value, target);
    }
    if (target instanceof WNumberSet) {
      return this.checkNumberSet(value, target);
    }
    if (target instanceof WComplement) {
      return this.checkComplement(value, target);
    }
    return false;
  }

  private translateInput(value: string): string {
    if (this.useLatex) {
      return this.sanitizeInput(value);
    }
    return value;
  }

  private checkNumberSet(
    value: string,
    target: WNumberSet): boolean {
    return XString.trim(value) === target.toSymbol();
  }

  private checkSetBuilder(
    valueArg: string,
    target: WSetBuilder): boolean {
    /*
    {x∈ℝ|1<x<10}
    {x∈ℝ|x>2}
    {x∈ℝ|2>x}
    */

    // {([a-z])∈([ℂℕℙℚℝℤ])\|([^}]+)}

    const value = valueArg.split(' ').join('');

    const r: RegExp = new RegExp(/^{([a-z])∈([ℂℕℙℚℝℤ])\|([^\}]+)}$/);
    const o: Match = Match.tryParse(r.exec(value));

    if (o) {
      if (target.symbol.getSymbol() !== String(o.groups[1])
        || target.numbers.toString() !== String(o.groups[2])) {
        return false;
      }

      const condition: SetElement = this.setBuilderCondition(String(o.groups[3]), String(o.groups[1]));
      if (condition instanceof WInterval) {
        if (target.condition instanceof WInterval) {
          if (target.condition.equalsTo(condition)) {
            return true;
          }
          return false;
        }
        return false;
      }
      if (condition instanceof WComplement) {
        if (target.condition instanceof WComplement) {
          if (target.condition.A instanceof WInterval && condition.A instanceof WInterval) {
            const excluded1: WInterval = target.condition.A;
            const excluded2: WInterval = condition.A;
            return excluded1.equalsTo(excluded2);
          }
          return false;
        }
        return false;
      }
    }

    this.raiseError(CommonError.SET_BUILDER_INVALID);
    return false;
  }

  private setBuilderCondition(
    value: string,
    symbol: string): SetElement {
    // n<x<n, x>n, n>x
    let a: WInterval;
    let b: WInterval;

    // Don't check the format of the number early
    let n: number;
    let r: RegExp = new RegExp(`([0-9\\.,-−]+)([<>≤≥])${symbol}`);
    let o: Match = Match.tryParse(r.exec(value));
    if (o) {
      // n<x
      n = this.numberParser.parseNumber(String(o.groups[1]));
      if (!isNaN(n)) {
        a = this.parseInequality(
          this.reverseRelOperator(String(o.groups[2])),
          this.env.culture.createNumber(n));
      }
    }

    r = new RegExp(`${symbol}([<>≤≥])([0-9\\.,-−]+)`);
    o = Match.tryParse(r.exec(value));
    if (o) {
      // x<n
      n = this.numberParser.parseNumber(String(o.groups[2]));
      if (!isNaN(n)) {
        b = this.parseInequality(
          String(o.groups[1]),
          this.env.culture.createNumber(n));
      }
    }

    if (a && b) {
      if (a.intersection(b)) {
        return a.intersection(b);
      }
      const u: SetElement[] = [];
      u.push(a);
      u.push(b);
      return new WUnion(u, a.formatter).toComplement();
    }
    if (a) {
      return a;
    }
    if (b) {
      return b;
    }
    return null;
  }

  private reverseRelOperator(op: string): string {
    if (op === '<') {
      return '>';
    }
    if (op === '>') {
      return '<';
    }
    if (op === '≤') {
      return '≥';
    }
    if (op === '≥') {
      return '≤';
    }
    return null;
  }

  private parseInequality(
    op: string,
    bound: RealElement): WInterval {
    // Return the interval of an inequality as if the inequality
    // was defined with the variable on the left side.

    // x op bound
    // x > 10
    switch (op) {
      case '<':
        return this.intervalsFactory.createInterval(IntervalClosure.OPEN, null, bound);
      case '>':
        return this.intervalsFactory.createInterval(IntervalClosure.OPEN, bound, null);
      case '≤':
        return this.intervalsFactory.createInterval(IntervalClosure.OPEN_CLOSED, null, bound);
      case '≥':
        return this.intervalsFactory.createInterval(IntervalClosure.CLOSED_OPEN, bound, null);
    }

    return null;
  }

  private checkComplement(value: string, target: WComplement): boolean {
    const r: RegExp = new RegExp(/^([^∖]+)∖([^∖]+)$/);
    const o: Match = Match.tryParse(r.exec(value));

    if (o != null) {
      // B∖A
      const B: string = String(o.groups[1]);
      const A: string = String(o.groups[2]);
      if (this.checkSet(B, target.B) && this.checkSet(A, target.A)) {
        return true;
      }
    }

    // test equivalent expression
    const source = this.parseUnion(value);
    if (source != null && source.length > 0) {
      const union = new WUnion(source, source[0].formatter);
      const complement = union.toComplement();
      if (complement != null) {
        if (complement.equalsTo(target)) {
          return true;
        }

        const singleton1 = complement.A.toSingleton();
        const singleton2 = target.A.toSingleton();

        if (singleton1 !== null && singleton2 !== null && singleton1.toNumber() === singleton2.toNumber()) {
          return true;
        }
      }
    }

    return false;
  }

  private checkUnion(value: string, target: WUnion): boolean {
    let source: WInterval[] = [];
    const intervals: WInterval[] = [];

    let i: number;

    for (i = 0; i < target.parts.length; i++) {
      const part: SetElement = target.parts[i];
      if (!(part instanceof WInterval)) {
        return true; // unhandled case
      }
      intervals.push(part);
    }

    if (!this.tryParseDifference(value, source)) {
      source = this.parseUnion(value);
      if (source == null) {
        this.raiseError(CommonError.UNION_INVALID);
      }
    }

    return this.checkIntervals(source, intervals);
  }

  /**
   *
   */
  private parseUnion(value: string): WInterval[] {
    const intervals: WInterval[] = [];
    const parts: string[] = value.split('∪');
    for (let i = 0; i < parts.length; i++) {
      const interval: WInterval = this.parseInterval(parts[i]);
      if (!interval) {
        return null;
      }
      intervals.push(interval);
    }
    return intervals;
  }

  /**
   *
   * @param source
   * @param target
   */
  private checkIntervals(sourceArg: WInterval[], targetArg: WInterval[]): boolean {
    if (sourceArg.length !== targetArg.length) {
      return false;
    }
    const source = sourceArg.concat();
    const target = targetArg.concat();

    while (source.length > 0) {
      const s: WInterval = source.pop();
      let j: number = 0;
      while (j < target.length) {
        if (s.equalsTo(target[j])) {
          target.splice(j, 1);
          break;
        } else {
          j++;
        }
      }
      if (source.length !== target.length) {
        return false;
      }
    }

    return true;
  }

  /**
   * Convert a difference of reals numbers and interval into union.
   */
  private tryParseDifference(
    value: string,
    union: WInterval[]): boolean {
    // ℝ∖...

    const o: string[] = value.split('∖');
    if (o.length !== 2) {
      return false;
    }

    o[0] = XString.trim(o[0]);
    o[1] = XString.trim(o[1]);

    if (!this.isRealNumbersSet(o[0])) {
      return false;
    }

    const e: WInterval = this.parseInterval(o[1]);
    if (!e) {
      this.raiseError(CommonError.INTERVAL_INVALID);
    }

    if (e.lBound != null) {
      union.push(this.intervalsFactory.createInterval(
        (!e.closure.lower)
          ? IntervalClosure.OPEN_CLOSED
          : IntervalClosure.OPEN,
        null,
        e.lBound));
    }

    if (e.rBound != null) {
      union.push(
        this.intervalsFactory.createInterval(
          (!e.closure.upper)
            ? IntervalClosure.CLOSED_OPEN
            : IntervalClosure.OPEN,
          e.rBound,
          null));
    }

    return true;
  }

  /**
   *
   * @param value
   */
  private isRealNumbersSet(value: string): boolean {
    if (value === 'ℝ' || value === 'R') {
      return true;
    }
    const interval = this.parseInterval(value);
    if (interval === null) {
      return false;
    }

    if (interval.isReals) {
      return true;
    }

    return false;
  }

  private checkInterval(
    value: string,
    target: WInterval): boolean {
    const o: WInterval[] = [];

    if (this.tryParseDifference(value, o)) {
      if (o.length === 1) {
        return o[0].equalsTo(target);
      }
      return false;
    }

    const source: WInterval = this.parseInterval(value);

    if (!source) {
      this.raiseError(CommonError.INTERVAL_INVALID);
    }

    if (!source.equalsTo(target)) {
      const source2: WInterval
        = this.intervalsFactory.createInterval(
          source.closure,
          source.rBound,
          source.lBound);

      if (source2.equalsTo(target)) {
        this.raiseError(CommonError.INTERVAL_BOUNDS_ORDER);
      }

      return false;
    }

    return true;
  }

  private parseInterval(
    valueArg: string): WInterval {
    let value = XString.trim(valueArg);
    if (value === 'ℝ') {
      return this.intervalsFactory.createReals();
    }

    value = value.split(', ').join(';');

    // Single comma, don't expect space
    if (value.indexOf(';') === -1
      && value.indexOf(',') === value.lastIndexOf(',')) {
      value = value.split(',').join(';');
    }

    value = value.split(' ').join('');

    if (value.length < 2) {
      return null;
    }

    const startsWith: string = value.charAt(0);
    const endsWith: string = value.charAt(value.length - 1);

    if (startsWith === '{' && endsWith === '}') {
      const n: number = this.numberParser.parseNumber(value.substring(1, value.length - 1));
      if (isNaN(n)) {
        return null;
      }
      return this.intervalsFactory.createInterval(
        IntervalClosure.CLOSED,
        this.env.culture.createNumber(n),
        this.env.culture.createNumber(n));
    }

    let noFenceOnLeft = false;
    let noFenceOnRight = false;

    if (['(', '[', ']'].includes(startsWith)) {
      value = value.substring(1, value.length);
    } else {
      noFenceOnLeft = true;
    }
    if ([')', '[', ']'].includes(endsWith)) {
      value = value.substring(0, value.length - 1);
    } else {
      noFenceOnRight = true;
    }

    const bounds: string[] = value.split(';');

    if (bounds.length !== 2) {
      return null;
    }

    const lower: number = this.numberParser.parseNumber(bounds[0]);
    const upper: number = this.numberParser.parseNumber(bounds[1]);

    if (isNaN(lower) || isNaN(upper)) {
      return null;
    }
    if (lower === Number.POSITIVE_INFINITY) {
      return null;
    }
    if (upper === Number.NEGATIVE_INFINITY) {
      return null;
    }

    if (upper === Number.NEGATIVE_INFINITY) {
      return null;
    }
    if (noFenceOnLeft && lower !== Number.NEGATIVE_INFINITY) {
      return null;
    }
    if (noFenceOnRight && lower !== Number.POSITIVE_INFINITY) {
      return null;
    }

    let notations: IntervalsNotations[] = [
      'bracketsAndParentheses',
      'bracketsAndParenthesesExceptInfinity',
      'bracketsAndReversedBrackets',
      'bracketsAndReversedBracketsExceptInfinity',
    ];

    const excludeNotation = (notationToExclude: string) => {
      notations = notations.filter(notation => notation !== notationToExclude);
    };

    // si noFenceOnLeft ou noFenceOnRight, c'est qu'on utilise la variation de notation short
    // si des parenthèses c'est qu'on utilise la notation standard
    // si des crochets inversés c'est qu'on utilise la notation non-standard
    if (noFenceOnLeft || noFenceOnRight) {
      excludeNotation('bracketsAndParentheses');
      excludeNotation('bracketsAndReversedBrackets');
    }
    if (startsWith === '(' || endsWith === ')') {
      excludeNotation('bracketsAndReversedBrackets');
      excludeNotation('bracketsAndReversedBracketsExceptInfinity');
    }
    if (startsWith === ']' || endsWith === '[') {
      excludeNotation('bracketsAndParentheses');
      excludeNotation('bracketsAndParenthesesExceptInfinity');
    }

    if (notations.length === 0) {
      // inconsistent notation
      return null;
    }

    if (notations.length === 1) {
      // unambiguous notation
      if (!this.env.culture.configuration.acceptIntervalsNotations.includes(notations[0])) {
        return null;
      }
    } else if (notations.length > 1) {
      // ambiguous notation
      if (!this.env.culture.configuration.acceptIntervalsNotations.some((notation) => {
        return notations.includes(notation);
      })) {
        return null;
      }
    }

    const closure
      = IntervalClosure.parse(
        startsWith === '[',
        endsWith === ']',
      );

    if (lower === Number.NEGATIVE_INFINITY && closure.lower) {
      return null; // infinity must be open
    }

    if (upper === Number.POSITIVE_INFINITY && closure.upper) {
      return null; // infinity must be open
    }

    return this.intervalsFactory.createInterval(
      closure,
      lower === Number.NEGATIVE_INFINITY ? null : this.env.culture.createNumber(lower),
      upper === Number.POSITIVE_INFINITY ? null : this.env.culture.createNumber(upper));
  }

  private checkFiniteSet(
    valueArg: string,
    target: WFiniteSet): boolean {
    let value = valueArg.replace(/−/g, '-');

    let items: string[];

    if (value === '∅') {
      items = [];
    } else {
      if (this.expectEnclosed && !XString.isEnclosed(value, '{', '}')) {
        this.raiseError(CommonError.SET_BRACES_MISSING);
      }

      value = XString.trimBraces(value); // remove the fences

      items = this.parseInnerItems(value);
      items = items.map(Delegate.partial(this.formatAndValidate, target), this);
    }

    let i: number;
    let j: number;

    for (i = 0; i < items.length - 1; i++) {
      for (j = i + 1; j < items.length; j++) {
        if (items[i] === items[j]) {
          this.raiseError(CommonError.SET_DUPLICATES_ITEMS);
        }
      }
    }

    const target2: string[] = this.parseTarget(target, false);
    if (!target2) {
      return false; // Can't do correction of this kind of set
    }
    if (items.length !== target2.length) {
      return false;
    }

    for (i = 0; i < items.length; i++) {
      j = 0;
      while (j < target2.length) {
        if (items[i] === target2[j]) {
          target2.splice(j, 1);
          break;
        } else {
          j++;
        }
      }
    }

    return target2.length === 0;
  }

  private formatAndValidate(target: WFiniteSet, item: string, ..._: any[]): string {
    if (!item) {
      this.raiseError(CommonError.SET_ITEM_EMPTY);
    }

    if (item.charAt(0) === '(' && item.charAt(item.length - 1) === ')') {
      if (target.isNumeric) {
        this.raiseError(CommonError.SET_NOT_NUMERIC);
      }
      return `(${this.clist.parseItems(item, this.env.culture).join(', ')})`;
    }

    const s = item.replace(/,/g, '.');
    const n = Number(s);
    if (!isNaN(n)) {
      this.checkDecimalSeparator(item);
    }

    if (isNaN(n) && target.isNumeric) {
      if (s.indexOf('.') !== s.lastIndexOf('.')) {
        this.raiseError(CommonError.SET_INCONSISTENT_SPACES_DEC_SEP);
      }

      this.raiseError(CommonError.SET_NOT_NUMERIC);
    }

    if (isNaN(n)) {
      return item;
    }
    return String(n);
  }

  private parseInnerItems(valueArg: string): string[] {
    let value = XString.trim(valueArg);
    if (!value) {
      return [];
    }

    if (value.indexOf('(') !== -1 && value.indexOf(')') !== -1) {
      // Replace items separators (, and ;) inside nested lists
      // so that they will not be treated by the top level call
      // to CList.parseItems.

      // \([^\(]+\)
      value = value.replace(/\([^\(]+\)/g, this.encodeSeparators);
    }
    let items: any[] = this.clist.parseItems(`(${value})`, this.env.culture);
    items = items.map(this.decodeSeparators, null);
    return items as string[];
  }

  private encodeSeparators(item: string, ..._: any[]): string {
    let s: string = item;
    s = s.replace(/,/g, 'ý');
    s = s.replace(/;/g, 'ÿ');
    return s;
  }

  private decodeSeparators(item: string, ..._: any[]): string {
    let s: string = item;
    s = s.replace(/ý/g, ',');
    s = s.replace(/ÿ/g, ';');
    return s;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    this.convert2(w, target as SetElement);
  }

  private convert2(
    w: IWriter,
    target: SetElement): void {
    let i: number;

    if (target instanceof WFiniteSet) {
      const elements: string[] = this.parseTarget(target, true);
      if (elements.length === 0) {
        if (this.emptySetNotation === '{}') {
          w.openBrace();
          w.closeBrace();
        } else {
          w.writeRaw(this.emptySetNotation);
        }
      } else {
        const itemsSeparator: string = this.env.culture.listFormatter.outputSeparator(elements.join('\n'));
        if (this.expectEnclosed) {
          w.openBrace();
        }
        for (i = 0; i < elements.length; i++) {
          if (i > 0) {
            w.writeRaw(`${itemsSeparator} `);
          }
          w.writeRaw(elements[i]);
        }
        if (this.expectEnclosed) {
          w.closeBrace();
        }
      }
    } else if (target instanceof WNumberSet) {
      w.writeSymbol(target.toSymbol());
    } else if (target instanceof WInterval) {
      const wi: WInterval = target;

      w.writeOperator(wi.getLeftFence(), null);

      let ns: string = '';
      if (wi.lBound) {
        ns += this.env.culture.formatNumber(wi.lBoundN);
      }
      if (wi.rBound) {
        ns += this.env.culture.formatNumber(wi.rBoundN);
      }

      if (wi.lBound == null) {
        w.writeSymbol('−∞');
      } else {
        w.writeNumber(wi.lBound.toNumber());
      }
      w.writeRaw(`${this.env.culture.listFormatter.outputSeparator(ns)} `);
      if (wi.rBound == null) {
        w.writeSymbol('∞');
      } else {
        w.writeNumber(wi.rBound.toNumber());
      }
      w.writeOperator(wi.getRightFence(), null);
    } else if (target instanceof WUnion) {
      const u: WUnion = target;
      for (i = 0; i < u.parts.length; i++) {
        const part: SetElement = u.parts[i];
        if (i > 0) {
          w.writeInfixOperator('∪');
        }
        this.convert2(w, part);
      }
    } else if (target instanceof WSetBuilder) {
      const sb: WSetBuilder = target;
      w.openBrace();
      w.writeSymbol(sb.symbol.getSymbol());
      w.writeInfixOperator('∈');
      w.writeSymbol(sb.numbers.toString());

      if (sb.condition instanceof WInterval) {
        const sbi: WInterval = sb.condition;
        const a: TokenElement = sbi.lBoundT;
        const b: TokenElement = sbi.rBoundT;
        const oa: boolean = !sbi.closure.lower;
        const ob: boolean = !sbi.closure.upper;
        if (a instanceof WInfinity && b instanceof WInfinity) {
          // no need to write an inequality
        } else {
          w.writeInfixOperator('|');
          if (a instanceof RealElement && b instanceof RealElement) {
            w.writeNumber(a.toNumber());
            w.writeInfixOperator(oa ? '<' : '≤');
            w.writeSymbol(sb.symbol.getSymbol());
            w.writeInfixOperator(ob ? '<' : '≤');
            w.writeNumber(b.toNumber());
          } else if (a instanceof RealElement) {
            w.writeSymbol(sb.symbol.getSymbol());
            w.writeInfixOperator(oa ? '>' : '≥');
            w.writeNumber(a.toNumber());
          } else if (b instanceof RealElement) {
            w.writeSymbol(sb.symbol.getSymbol());
            w.writeInfixOperator(ob ? '<' : '≤');
            w.writeNumber(b.toNumber());
          }
        }
      } else if (sb.condition instanceof WComplement) {
        const complement: WComplement = sb.condition;

        if (complement.B instanceof WNumberSet
          && complement.B.toSymbol() === 'ℝ'
          && complement.A instanceof WInterval
          && complement.A.isFinite) {
          const excluded: WInterval = complement.A;

          w.writeInfixOperator('|');
          w.writeNumber(excluded.lBoundN);
          w.writeInfixOperator(excluded.closure.lower ? '>' : '≥');
          w.writeSymbol(sb.symbol.getSymbol());
          w.writeInfixOperator(excluded.closure.upper ? '>' : '≥');
          w.writeNumber(excluded.rBoundN);
        }
      }

      w.closeBrace();
    }
  }

  /**
   * withLocalization: indicates whether elements are outputed with the correct localization.
   */
  private parseTarget(value: WFiniteSet, withLocalization: boolean): string[] {
    const o: string[] = [];
    for (let i: number = 0; i < value.cardinal; i++) {
      const token: TokenElement = value.getElementAt(i);
      if (token instanceof WNumber) {
        if (withLocalization) {
          o.push(token.toText(true));
        } else {
          o.push(String(token.toNumber()));
        }
      } else if (token instanceof SymbolElement) {
        o.push(token.getSymbol());
      } else if (token instanceof WString) {
        o.push(token.getString());
      } else if (token instanceof WPoint) {
        const p: Point = token.toPoint();
        if (withLocalization) {
          o.push(`(${this.env.culture.formatNumber(p.x)}, ${this.env.culture.formatNumber(p.y)})`);
        } else {
          o.push(`(${p.x}, ${p.y})`);
        }
      } else if (token instanceof WList) {
        const list: WList = token;
        const z: string[] = [];
        for (let j: number = 0; j < list.count; j++) {
          if (withLocalization) {
            z.push(this.env.culture.formatNumber(list.getValueAt(j)));
          } else {
            z.push(String(list.getValueAt(j)));
          }
        }
        o.push(`(${z.join(', ')})`);
      } else {
        return null;
      }
    }
    return o;
  }

  public get mathKeyboard(): number {
    if (this.acceptSetNumbers) {
      return KeyboardConfiguration.NUMBER_SET;
    }
    if (this.acceptIntervals) {
      return KeyboardConfiguration.INTERVAL;
    }
    if (this.acceptSetBuilders) {
      return KeyboardConfiguration.SET_BUILDER;
    }
    return KeyboardConfiguration.FINITE_SET;
  }

  public get inputCapabilities(): InputCapabilities {
    return super.inputWithSymbols(this.inputSymbols);
  }

  private get inputSymbols(): string {
    if (this.acceptSetNumbers) {
      return this.env.culture.acceptIntervalNotationWithParenthesis
        ? '()[]{}+−∞∪∖ℕℚℝℤ'
        : '[]{}+−∞∪∖ℕℚℝℤ';
    }

    if (this.acceptIntervals) {
      return this.env.culture.acceptIntervalNotationWithParenthesis
        ? (this.env.culture.configuration.useSimplifiedIntervalKeyboard ? '()[]+−∞' : '()[]{}+−∞∪∖ℝ')
        : (this.env.culture.configuration.useSimplifiedIntervalKeyboard ? '[]+−∞' : '[]{}+−∞∪∖ℝ');
    }

    if (this.acceptSetBuilders) {
      return '{∈ℕℚℝℤ|−.,<>≤≥}';
    }

    // Always offer braces even when it's optional.
    return this.emptySetNotation;
  }
}
