import React from 'react';
import {
  Route,
  RouteComponentProps,
  withRouter,
  Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PageNotFound, Spinner } from '@scolab/publisher-ui-kit';
import { useMediator } from '@scolab/common-editor';
import { Routes } from '../config/routes';
import { GetAccessToken } from '../pages/GetAccessToken';
import { StartSessionFromAccessToken } from '../pages/StartSessionFromAccessToken';
import FeatureFlagsMediator from '../mediators/FeatureFlagsMediator';

const LoginAppComponent: React.FC<RouteComponentProps> = observer(() => {
  const flags = useMediator(FeatureFlagsMediator);
  if (!flags.isLoaded) {
    return (<Spinner />);
  }

  return (
    <HelmetProvider context={{}}>
      <Helmet>
        <title>
          Authentification
        </title>
      </Helmet>
      <Switch>
        <Route exact path={Routes.Login}>
          <GetAccessToken />
        </Route>
        <Route exact path={Routes.LoginCallback}>
          <StartSessionFromAccessToken />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </HelmetProvider>
  );
});

export const LoginApp = withRouter(LoginAppComponent);
