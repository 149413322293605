import { observer } from 'mobx-react';
import React from 'react';
import { Select, SelectItem, TopLabeledSelectedItem } from '@scolab/publisher-ui-kit';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import { inject } from '../dependencyInjection/inject';
import { Locales } from '../models/ILocales';
import { LocaleMediator } from '../mediators/LocaleMediator';
import { LocaleProvider } from './LocaleProvider';
import { locales } from '../autoGenerate/locale';

@observer
class LocaleSelectorComponent extends React.Component<WrappedComponentProps> {
  @inject(LocaleMediator)
  private accessor localeMediator: LocaleMediator;

  public render(): JSX.Element {
    const SelectedComponent = (
      <TopLabeledSelectedItem
        label={<FormattedMessage id="localeSelector.label" />}
        selectedLabel={<FormattedMessage id={getLocaleIntlKey(this.localeMediator.locale)} />}
      />
    );
    return (
      <Select
        SelectedComponent={SelectedComponent}
        value={this.localeMediator.locale}
        onChange={this.onChange}
      >
        <StyledSelectItem value={Locales.fr_CA}>
          <FormattedMessage id={getLocaleIntlKey(Locales.fr_CA)} />
        </StyledSelectItem>
        <StyledSelectItem value={Locales.en_CA}>
          <FormattedMessage id={getLocaleIntlKey(Locales.en_CA)} />
        </StyledSelectItem>
        <StyledSelectItem value={Locales.en_US}>
          <FormattedMessage id={getLocaleIntlKey(Locales.en_US)} />
        </StyledSelectItem>
      </Select>
    );
  }

  private onChange = (locale: Locales) => {
    this.localeMediator.setLocale(locale);
  };
}

const getLocaleIntlKey = (locale: Locales) => {
  switch (locale) {
    case Locales.fr_CA: return 'localeSelector.fr_CA';
    case Locales.en_CA: return 'localeSelector.en_CA';
    case Locales.en_US: return 'localeSelector.en_US';
  }
};

const StyledSelectItem = styled(SelectItem)`
  padding: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const IntlLocaleSelector = injectIntl(LocaleSelectorComponent);

const ProvidedLocaleSelector = () => (
  <LocaleProvider locales={locales}>
    <IntlLocaleSelector />
  </LocaleProvider>
);
export const LocaleSelector = React.memo(ProvidedLocaleSelector);
