import { Point } from '../../../js/geom/Point';

import { XGeom } from '../../core/XGeom';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RandomPointGenerator } from '../../elements/factories/RandomPointGenerator';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Triangle aléatoire.
 */
export class RandomTriangle extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getPoint(0) && args.getPoint(1)) {
      return this.fromPoints(args.getPoint(0), args.getPoint(1), args.prng);
    }
    return null;
  }

  /**
   * Minimum interior angle and maximum number of attempts
   * to generate a triangle that respect that constraint.
   */
  private static MINIMUM_INTERIOR_ANGLE: number = 20 * Math.PI / 180; // 20;

  private static SKIP_CONSTRAINT_THRESHOLD: number = 8;

  /**
   * Random triangle in the specified rectangle that envelopes the two points.
   * The coordinates are integers.
   *
   * Retourne un triangle dont les sommets sont des coordonnées entières
   * qui se trouvent dans une région rectangulaire définie par deux points.
   */
  private fromPoints(a: WPoint, b: WPoint, prng: IPrng): WPolygon {
    const v: Point[] = RandomTriangle.createTriangle(a.toPoint(), b.toPoint(), prng);
    return v ? new WPolygon(v) : null;
  }

  /**
   *
   */
  public static createTriangle(aArg: Point, bArg: Point, prng: IPrng): Point[] {
    let a: Point = aArg;
    let b: Point = bArg;
    const rpg: RandomPointGenerator = new RandomPointGenerator(a, b, 2, prng);
    let c: Point;

    let attemps: number = 0;

    do {
      do {
        a = rpg.next();
        b = rpg.next();
        c = rpg.next();
      } while (XGeom.colinear(a, b, c));

      attemps++;
    } while (attemps <= RandomTriangle.SKIP_CONSTRAINT_THRESHOLD
      && (XGeom.interiorAngle(c, a, b) < RandomTriangle.MINIMUM_INTERIOR_ANGLE
        || XGeom.interiorAngle(a, b, c) < RandomTriangle.MINIMUM_INTERIOR_ANGLE
        || XGeom.interiorAngle(b, c, a) < RandomTriangle.MINIMUM_INTERIOR_ANGLE));

    return [a, b, c] as Point[];
  }
}
