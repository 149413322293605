import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { FactoredQuadraticFormatter } from '../../elements/formats/quad/FactoredQuadraticFormatter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { WVariable } from '../../elements/tokens/WVariable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * A(x - x1)(x - x2)
 */
export class FactoredForm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      const quadratic: WQuadratic = args.getQuadraticOrPoly(0);
      if (quadratic) {
        return quadratic.applyFormat(new FactoredQuadraticFormatter(args.env.culture));
      }
    } else if (args.length === 3) {
      if (args.getReal(0) && args.getReal(1) && args.getReal(2)) {
        return this.fromParameters(args.getReal(0), args.getReal(1), args.getReal(2), args.env);
      }
    }

    return null;
  }

  /**
   * A(x - x1)(x - x2)
   */
  private fromParameters(
    A: RealElement,
    x1: RealElement,
    x2: RealElement,
    env: Environment): ContentElement {
    if (A.toNumber() === 0) {
      return env.culture.createNumber(0);
    }

    const polyX1: WPolynomial = this.createFactor(x1, env);
    const polyX2: WPolynomial = this.createFactor(x2, env);
    const result: WPolynomial = env.polynomials.multiplyR(env.polynomials.multiply(polyX1, polyX2), A);

    const orderHint: number[] = [];
    orderHint.push(x1.toNumber(), x2.toNumber());

    return WQuadratic.parsePolynomial(result, new FactoredQuadraticFormatter(env.culture, orderHint));
  }

  /**
   *
   */
  private createFactor(value: RealElement, env: Environment): WPolynomial {
    const symbols: SymbolElement[]
      = [new WVariable('x', env.culture.numberFormatter)];

    return new WPolynomial(
      symbols,
      [
        env.culture.createNumber(1),
        value.toOpposite(),
      ] as RealElement[],
      [1, 0],
      env.culture.numberFormatter);
  }
}
