import { computed, observable, runInAction } from 'mobx';
import { lazyObservable, ILazyObservable } from 'mobx-utils';
import { Store } from '../../dependencyInjection/Store';
import { IFeatureFlags } from '../IFeatureFlags';
import { getFeatureFlags } from '../../requests/FeatureFlagsRequest';

@Store('FeatureFlagsStore.ts/FeatureFlagsStore')
export class FeatureFlagsStore {
  @observable
  private accessor _featureFlags: ILazyObservable<IFeatureFlags>;

  constructor() {
    runInAction(() => {
      this._featureFlags = lazyObservable(sink => getFeatureFlags().then(featureFlags => sink(featureFlags)));
    });
  }

  @computed
  public get featureFlags(): IFeatureFlags {
    return this._featureFlags.current() || { flags: {} };
  }
}
