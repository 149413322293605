import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../elements/tokens/WListOfSegments';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WPolyline } from '../../elements/tokens/WPolyline';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de quadrilatère.
 */
export class Quadrilateral extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 4) {
      return args.expectingArguments(1, 4);
    }

    if (args.length === 1) {
      if (args.getPolygon(0)) {
        return this.pass(args.getPolygon(0));
      }
      if (args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
      if (args.getSegments(0)) {
        return this.segments(args.getSegments(0));
      }
      if (args.getPolyline(0)) {
        return this.polyline(args.getPolyline(0));
      }
    } else if (args.length === 4) {
      if (args.getPoint(0) && args.getPoint(1) && args.getPoint(2) && args.getPoint(3)) {
        return this.quad(args.getPoint(0), args.getPoint(1), args.getPoint(2), args.getPoint(3));
      }
    }

    return null;
  }

  /**
   *
   */
  private pass(value: WPolygon): WPolygon {
    if (value.vertices.length === 4) {
      return value;
    }
    throw new MathError('Not a quadrilateral');
  }

  /**
   *
   */
  private quad(
    a: WPoint,
    b: WPoint,
    c: WPoint,
    d: WPoint): WPolygon {
    const quadrilateral = WPolygon.tryParsePolygon([a.toPoint(), b.toPoint(), c.toPoint(), d.toPoint()]);

    if (quadrilateral) {
      return quadrilateral;
    }

    throw new MathError('Not a quadrilateral');
  }

  /**
   *
   */
  private points(
    value: WListOfPoints): WPolygon {
    if (value.count !== 4) {
      throw new MathError('Quadrilateral require four points');
    }

    const quadrilateral = WPolygon.tryParsePolygon(value.toPoints());

    if (quadrilateral) {
      return quadrilateral;
    }

    throw new MathError('Not a quadrilateral');
  }

  /**
   *
   */
  private segments(
    value: WListOfSegments): WPolygon {
    const temp: TokenElement = SegmentsUtil.toPoly(value.toSegments());
    if (temp instanceof WPolygon) {
      const poly: WPolygon = temp;
      if (poly.vertices.length === 4) {
        return poly;
      }
      throw new MathError('Quadrilateral require four segments');
    }

    throw new MathError('Not a quadrilateral');
  }

  /**
   *
   */
  private polyline(
    value: WPolyline): WPolygon {
    if (value.vertices.length === 5 && value.isClosed) {
      const quadrilateral: WPolygon = WPolygon.tryParsePolygon(value.vertices.slice(0, value.vertices.length - 1));
      if (quadrilateral) {
        return quadrilateral;
      }
    }

    throw new MathError('Not a quadrilateral');
  }
}
