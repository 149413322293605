import { action, computed } from 'mobx';
import { ITreeDataDescriptor } from '@scolab/publisher-ui-kit';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { CurriculumTreeNodeType, CurriculumTreeDataDescriptor } from '../models/CurriculumsTreeDataDescriptor';
import { ActivityPagesDependentsStore } from '../models/store/ActivityPagesDependentsStore';
import { AddAlignmentModalStore } from '../models/store/AddAlignmentModalStore';
import { CurriculumsStore } from '../models/store/CurriculumsStore';
import { ICurriculum } from '../models/ICurriculum';
import { CurriculumTreeNodeId } from '../models/ICurriculumTreeHeader';
import { IStandard } from '../models/IStandard';
import { getCourses } from '../api/curriculums/getCourses';
import { getStandards } from '../api/curriculums/getStandards';
import { ICourse } from '../models/ICourse';
import { ICourseRef } from '../models/ICourseRef';

@ViewModel
export default class AddAlignmentModalMediator {
  @inject(ActivityPagesDependentsStore)
  private accessor activityPagesDependentsStore: ActivityPagesDependentsStore;

  @inject(AddAlignmentModalStore)
  private accessor addAlignmentModalStore: AddAlignmentModalStore;

  @inject(CurriculumsStore)
  private accessor curriculumsStore: CurriculumsStore;

  public fetchCurriculums = async () => {
    await this.curriculumsStore.fetchCurriculums();
  };

  @computed
  public get currentActivityId(): number {
    return this.activityPagesDependentsStore.currentActivityId;
  }

  @action
  public async setSelectedNode(choice: string): Promise<void> {
    const curriculumId = Number(choice.match(new RegExp(`(\/${CurriculumTreeNodeId.curriculum}\/)(\\d+)`))?.pop());
    const courseId = Number(choice.match(new RegExp(`(\/${CurriculumTreeNodeId.course}\/)(\\d+)`))?.pop());

    this.addAlignmentModalStore.setSelectedNode(choice);
    this.addAlignmentModalStore.setSelectedCourseId(courseId);
    this.addAlignmentModalStore.setSelectedCurriculumId(curriculumId);

    if (curriculumId && this.coursesList === null) {
      await this.fetchCourses(curriculumId);
    }

    if (courseId && this.standardsList === null) {
      const courseSubjectId = this.coursesList.find(course => course.id === courseId)?.subjectId;
      await this.fetchStandards(courseId, courseSubjectId);
    }
  }

  public ensureNodeOpen = (nodeIds: ReadonlyArray<string>) => {
    this.addAlignmentModalStore.openCurriculumsNodes(nodeIds);
  };

  @computed
  public get selectedNode(): string {
    return this.addAlignmentModalStore.getSelectedNode;
  }

  @computed
  public get selectedNodeType(): CurriculumTreeNodeId {
    return this.getNodeType(this.selectedNode);
  }

  @computed
  public get curriculums(): ReadonlyArray<ICurriculum> {
    return this.curriculumsStore.curriculums;
  }

  @computed
  public get filteredCurriculumsList(): ReadonlyArray<ICurriculum> {
    let activeCurriculums = this.curriculums;
    if (!activeCurriculums) return null;

    if (this.curriculumsFilterSubjects.length > 0) {
      activeCurriculums = activeCurriculums.filter((curriculum) => {
        return curriculum.courses.some((course) => {
          return this.curriculumsFilterSubjects.includes(course.subjectId);
        });
      });
    }

    return activeCurriculums;
  }

  @computed
  public get coursesList(): ReadonlyArray<ICourse> {
    const { selectedCurriculumId } = this.addAlignmentModalStore;
    const coursesList = this.curriculumsStore.getCourses(selectedCurriculumId);
    if (coursesList) {
      return coursesList;
    }

    return null;
  }

  @computed
  public get filteredCoursesList(): ReadonlyArray<ICourse> {
    let coursesList = this.coursesList;
    if (!coursesList) return null;

    if (this.coursesFilterSubjects.length > 0) {
      coursesList = coursesList.filter(course => this.coursesFilterSubjects.includes(course.subjectId));
    }
    return coursesList;
  }

  @computed
  public get standardsList(): ReadonlyArray<IStandard> {
    const { selectedCourseId } = this.addAlignmentModalStore;
    const standardList = this.curriculumsStore.getStandards(selectedCourseId);
    if (standardList) {
      return standardList.filter(this.filterStandards);
    }

    return null;
  }

  @computed
  public get curriculumsTreeData(): ITreeDataDescriptor<CurriculumTreeNodeType> {
    return this.curriculums
      ? new CurriculumTreeDataDescriptor(this.curriculums)
      : null;
  }

  public toggleCurriculumsOpenNode = (nodeId: string): void => {
    this.addAlignmentModalStore.toggleCurriculumsOpenNode(nodeId);
  };

  public isNodeOpened = (nodeId: string): boolean => {
    return this.addAlignmentModalStore.curriculumsOpenedNodes.includes(nodeId);
  };

  @computed
  public get curriculumsOpenedNodes(): ReadonlyArray<string> {
    return this.addAlignmentModalStore.curriculumsOpenedNodes;
  }

  public fetchCourses = async (curriculumId: number): Promise<void> => {
    const courses = await getCourses(curriculumId);
    this.curriculumsStore.setCourses(curriculumId, courses.concat());
  };

  public fetchStandards = async (courseId: number, subjectId: number): Promise<void> => {
    const standards = await getStandards(courseId);
    const mappedStandards = this.associateSubjectWithStandard(standards, subjectId);
    this.curriculumsStore.setStandards(courseId, mappedStandards.concat());
  };

  public setSearchResults(result: ReadonlyArray<IStandard>): void {
    this.addAlignmentModalStore.setSearchStandardsList(result);
  }

  @computed
  public get searchResults(): ReadonlyArray<IStandard> {
    const searchStandardsList = this.addAlignmentModalStore.searchStandardsList;

    if (searchStandardsList) {
      return searchStandardsList.filter(this.filterStandards);
    }

    return null;
  }

  public setCodeFilter(code: string) {
    this.addAlignmentModalStore.setCodeFilter(code);
  }

  public get codeFilter() {
    return this.addAlignmentModalStore.codeFilter;
  }

  public setDescriptionFilter(description: string) {
    this.addAlignmentModalStore.setDescriptionFilter(description);
  }

  public get descriptionFilter() {
    return this.addAlignmentModalStore.descriptionFilter;
  }

  public get selectedCourseId() {
    return this.addAlignmentModalStore.selectedCourseId;
  }

  @computed
  public get selectedCourse(): ICourseRef {
    const { selectedCourseId } = this.addAlignmentModalStore;

    if (!selectedCourseId) return null;
    return this.selectedCurriculum
      .courses.find(course => course.id === selectedCourseId);
  }

  @computed
  public get selectedCurriculum(): ICurriculum {
    const { selectedCurriculumId } = this.addAlignmentModalStore;

    if (!selectedCurriculumId) return null;
    return this.curriculumsStore.curriculums
      .find(curriculum => curriculum.id === selectedCurriculumId);
  }

  public setCurriculumsFilterSubjects = (value: ReadonlyArray<number>): void => {
    this.addAlignmentModalStore.setCurriculumsFilterSubjects(value);
  };

  @computed
  public get curriculumsFilterSubjects(): ReadonlyArray<number> {
    return this.addAlignmentModalStore.curriculumsFilterSubjects;
  }

  public setCoursesFilterSubjects = (value: ReadonlyArray<number>): void => {
    this.addAlignmentModalStore.setCoursesFilterSubjects(value);
  };

  @computed
  public get coursesFilterSubjects(): ReadonlyArray<number> {
    return this.addAlignmentModalStore.coursesFilterSubjects;
  }

  private associateSubjectWithStandard(standard: ReadonlyArray<IStandard>, subjectId: number): ReadonlyArray<IStandard> {
    return standard.map((standard) => {
      standard.subjectId = subjectId;
      return standard;
    });
  }

  private getNodeType(nodeId: string): CurriculumTreeNodeId {
    if (!nodeId) {
      return CurriculumTreeNodeId.curriculums;
    }

    if (nodeId.indexOf(`/${CurriculumTreeNodeId.course}/`) >= 0) {
      return CurriculumTreeNodeId.course;
    }

    if (nodeId.indexOf(`/${CurriculumTreeNodeId.curriculum}/`) >= 0) {
      return CurriculumTreeNodeId.curriculum;
    }

    return CurriculumTreeNodeId.curriculums;
  }

  private filterStandards = (standard: IStandard): boolean => {
    if (this.codeFilter && this.descriptionFilter) {
      return standard.displayId.toUpperCase().indexOf(this.codeFilter.toUpperCase()) !== -1
        && standard.description.toUpperCase().indexOf(this.descriptionFilter.toUpperCase()) !== -1;
    }
    if (this.codeFilter) {
      return standard.displayId.toUpperCase().indexOf(this.codeFilter.toUpperCase()) !== -1;
    }
    if (this.descriptionFilter) {
      return standard.description.toUpperCase().indexOf(this.descriptionFilter.toUpperCase()) !== -1;
    }
    return true;
  };
}
