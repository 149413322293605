import { Point } from '../../js/geom/Point';

import { XRound } from '../core/XRound';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { DefaultFormats } from '../elements/formats/DefaultFormats';
import { WRatio } from '../elements/tokens/WRatio';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CRatio extends BaseCorrector {
  private separator: string;

  /**
   *
   */
  constructor(separator: string) {
    super();
    this.separator = separator;
  }

  /**
   *
   */
  private validateSeparator(value: string): string {
    const a1: number = value.indexOf(':');
    const a2: number = value.lastIndexOf(':');
    const b1: number = value.indexOf('/');
    const b2: number = value.lastIndexOf('/');
    if (a1 !== -1 && b1 === -1 && a1 === a2) {
      return ':';
    }
    if (b1 !== -1 && a1 === -1 && b1 === b2) {
      return '/';
    }
    return null;
  }

  /**
   *
   */
  private parseRatio(value: string): Point {
    const separator: string = this.validateSeparator(value);
    if (separator) {
      return null;
    }
    const ratio: any[] = value.split(separator);
    const r: number = this.numberParser.parseNumber(ratio[0]);
    const s: number = this.numberParser.parseNumber(ratio[1]);
    return new Point(r, s);
  }

  /**
   *
   */
  public parse(valueArg: string): Node {
    let value = this.sanitizeInput(valueArg);
    value = value.replace(/∕/g, '/'); // Replace division slash (U+2215) by slash.

    const ratio: Point = this.parseRatio(value);
    if (!ratio) {
      return null;
    }

    const formats: DefaultFormats = new DefaultFormats(this.env.culture);
    const node: Node = new Node(new WRatio(ratio.x, ratio.y, formats.ratioFormatImpl));
    node.userData = `${ratio.x}:${ratio.y}`;
    return node;
  }

  /**
   *
   */
  public correct(
    valueArg: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    let value = this.sanitizeInput(valueArg);
    value = value.replace(/∕/g, '/'); // Replace division slash (U+2215) by slash.

    const separator: string = this.validateSeparator(value);
    let target2: WRatio = target as WRatio;

    if (separator !== target2.formatter.getSeparator()) {
      this.raiseError(CommonError.RATIO_FORMAT_INVALID, [target2.formatter.getSeparator()]);
    } else {
      const temp: any[] = value.split(separator);

      this.checkDecimalAndThousandSeparator(temp[0]);
      this.checkDecimalAndThousandSeparator(temp[1]);

      const r: number = this.numberParser.parseNumber(temp[0]);
      const s: number = this.numberParser.parseNumber(temp[1]);

      if (isNaN(r) || isNaN(s)) {
        this.raiseError(CommonError.NAN);
      } else if (XRound.safeRound(r / s) === XRound.safeRound(target2.ratio())) {
        if (this.options.expectReduced) {
          target2 = target2.reduced();
          if (r === target2.r && s === target2.s) {
            return true;
          }
          this.raiseError(CommonError.RATIO_NOT_SIMPLIFIED);
        } else {
          return true;
        }
      }
    }

    return false;
  }

  /**
   *
   */
  public writeTo(w: IWriter, target: ContentElement, ...targets: any[]): void {
    let ratio: WRatio = target as WRatio;

    if (this.options.expectReduced) {
      ratio = ratio.reduced();
    }

    w.writeNumber(ratio.r);
    w.writeRaw(
      XString.substitute(
        '{0}{1}{0}',
        ratio.formatter.useSpaces() ? ' ' : '',
        ratio.formatter.getSeparator()));
    w.writeNumber(ratio.s);
  }

  /**
   *
   */
  public get mathKeyboard(): number {
    return KeyboardConfiguration.RATIO;
  }

  /**
   *
   */
  public get inputCapabilities(): InputCapabilities {
    const divisionSlash = '∕'; // U+2215
    const symbol: string = (this.separator === '/' && this.useLatex) ? divisionSlash : this.separator;
    const i: InputCapabilities = super.inputWithSymbols(symbol);
    i.bevelled = true;
    return i;
  }
}
