import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  IIMultiSelectResources,
  IMultiselectFilterResult,
  IMultiselectValues,
  normalizeFilterValue,
  TagLabel,
  TopLabeledMultiSelect,
  TopLabeledSelectedItem,
} from '@scolab/publisher-ui-kit';
import { inject } from '../../dependencyInjection/inject';
import { SubjectMediator } from '../../mediators/SubjectMediator';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';
import { SubjectIcon } from './SubjectIcon';
import { getSubjectName } from '../../queries/subjectsQueries';

export interface ISubjectFilterProps {
  selectedOptions: ReadonlyArray<number>;
  onChange: (options: ReadonlyArray<number>) => void;
  className?: string;
}

@observer
class SubjectFilterComponent extends React.Component<ISubjectFilterProps & WrappedComponentProps> {
  @inject(SubjectMediator)
  private accessor subjectMediator: SubjectMediator;

  public render(): React.ReactNode {
    const {
      selectedOptions,
      className,
    } = this.props;

    return (
      <TopLabeledMultiSelect
        resources={this.resources}
        allValues={this.allValues}
        selectedIds={selectedOptions}
        SelectedComponent={this.selectedComponent}
        onSelect={this.onSelect}
        onDeselect={this.onDeselect}
        onSelectAll={this.onSelectAll}
        onDeselectAll={this.onDeselectAll}
        listItemLabelRenderFunction={this.listItemLabelRenderFunction}
        dataTestId="SubjectFilter"
        className={className}
      />
    );
  }

  private get resources(): IIMultiSelectResources {
    return {
      dropdownLabel: <FormattedMessage id="subject.subject" />,
      selectAllLabel: <FormattedMessage id="common.selectAllLabel" />,
      deselectAllLabel: <FormattedMessage id="common.unselectAllLabel" />,
      emptyStateLabel: <FormattedMessage id="common.noSearchResults" />,
    };
  }

  private get allValues(): ReadonlyArray<IMultiselectValues<number>> {
    const { formatMessage } = this.props.intl;
    const { subjects } = this.subjectMediator;
    return subjects.map(subject => ({
      id: subject.id,
      displayValue: formatMessage({ id: `subject.${subject.name}` }),
      filterValue: normalizeFilterValue(formatMessage({ id: `subject.${subject.name}` })),
    }));
  }

  private get selectedComponent(): React.ReactNode {
    const { selectedOptions } = this.props;

    if (selectedOptions.length === 0) {
      return null;
    }

    const separator = (<>,&nbsp;</>);

    const SelectedLabel = this.allValues.filter(value => selectedOptions.includes(value.id))
      .map((value, index, array) => (
        <SubjectValue key={value.id}>
          <StyledSubjectIcon subjectName={getSubjectName(value.id)} />
          {value.displayValue}
          {(index !== array.length - 1) && separator}
        </SubjectValue>
      ));

    return (
      <TopLabeledSelectedItem
        label={this.resources.dropdownLabel}
        selectedLabel={SelectedLabel}
      />
    );
  }

  private listItemLabelRenderFunction = (value: IMultiselectFilterResult<number>): React.ReactNode => (
    <SubjectValue>
      <StyledSubjectIcon subjectName={getSubjectName(value.value.id)} />
      <StyledTagLabel
        capitalize
        tagName={value.value.displayValue}
        emphasis={value.emphasis}
      />
    </SubjectValue>
  );

  private onSelect = (subjectFilter: number) => {
    const { selectedOptions, onChange } = this.props;
    onChange(selectedOptions.concat(subjectFilter));
  };

  private onDeselect = (subjectFilter: number) => {
    const { selectedOptions, onChange } = this.props;
    onChange(selectedOptions.filter(id => id !== subjectFilter));
  };

  private onSelectAll = () => {
    const { onChange } = this.props;
    onChange(this.allValues.map(value => value.id));
  };

  private onDeselectAll = () => {
    const { onChange } = this.props;
    onChange([]);
  };
}

const SubjectFilterWithIntl = injectIntl(SubjectFilterComponent);

const ProvidedSubjectFilter = (props: ISubjectFilterProps) => (
  <LocaleProvider locales={locales}>
    <SubjectFilterWithIntl
      {...props}
    />
  </LocaleProvider>
);
export const SubjectFilter = React.memo(ProvidedSubjectFilter);

const SubjectValue = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 2px;
`;

const StyledSubjectIcon = styled(SubjectIcon)`
  height: 18px;
  width: 18px;
`;

const StyledTagLabel = styled(TagLabel)`
  padding: 0;
`;
