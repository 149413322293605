import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { ConstantAdapter } from '../../elements/functions/adapters/ConstantAdapter';
import { FunctionAdapter } from '../../elements/functions/adapters/FunctionAdapter';
import { IFunctionAdapter } from '../../elements/functions/adapters/IFunctionAdapter';
import { LambdaAdapter } from '../../elements/functions/adapters/LambdaAdapter';
import { LineAdapter } from '../../elements/functions/adapters/LineAdapter';
import { PolynomialAdapter } from '../../elements/functions/adapters/PolynomialAdapter';
import { QuadraticAdapter } from '../../elements/functions/adapters/QuadraticAdapter';
import { WFunction } from '../../elements/tokens/WFunction';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WInterval } from '../../elements/tokens/WInterval';
import { WLine } from '../../elements/tokens/WLine';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { WRelation } from '../../elements/tokens/WRelation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { MathError } from '../../core/MathError';

/**
 *
 */
export class Plot extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }
    if (args.getContentElement(0) == null) {
      return null;
    }
    const domain = args.length > 1 ? args.getInterval(1) : null;
    if (args.length > 1 && !domain) {
      throw new MathError('Domain argument must be an interval');
    }
    return Plot.tryParse(args.getContentElement(0), domain, args.env);
  }

  /**
   *
   */
  public static tryParse(
    value: ContentElement,
    domain: WInterval,
    env: Environment): WFunctionGraph {
    let op: string = null;
    let axis: string = null;

    let adapter: IFunctionAdapter = Plot.polynomialAdapter(value, env); // try polynomial adapter first

    if (value instanceof WLine) {
      adapter = new LineAdapter(value);
    }
    if (value instanceof WQuadratic) {
      adapter = new QuadraticAdapter(value);
    }
    if (value instanceof AnonymousFunction) {
      adapter = new LambdaAdapter(value, env);
    }
    if (value instanceof WFunction) {
      adapter = new FunctionAdapter(value);
    }

    if (value instanceof WRelation) {
      const relation: WRelation = value;
      if (Plot.isVar(relation.lhs, null)) {
        op = String(relation.rel);
        if (Plot.isVar(relation.lhs, 'x')) {
          axis = 'y';
        }
        if (Plot.isUnivariante(relation.rhs, null)) {
          adapter = Plot.polynomialAdapter(relation.rhs, env);
        }
      } else if (Plot.isVar(relation.rhs, null)) {
        op = String(relation.rel.reverse);
        if (Plot.isVar(relation.rhs, 'x')) {
          axis = 'y';
        }
        if (Plot.isUnivariante(relation.lhs, null)) {
          adapter = Plot.polynomialAdapter(relation.lhs, env);
        }
      }
    }

    if (adapter) {
      return new WFunctionGraph(adapter, domain, op, axis);
    }
    return null;
  }

  /**
   *
   */
  private static polynomialAdapter(value: ContentElement, env: Environment): IFunctionAdapter {
    let adapter: IFunctionAdapter = null;

    if (value instanceof WPolynomial) {
      if ((value).symbols.length === 1) {
        adapter = new PolynomialAdapter(value, env.culture);
      }
    }

    if (value instanceof SymbolElement) {
      adapter = new PolynomialAdapter(value.widen() as WPolynomial, env.culture);
    }

    if (value instanceof RealElement) {
      adapter = new ConstantAdapter(value as RealElement, env.culture.intervalsFactory);
    }

    return adapter;
  }

  /**
   *
   */
  private static isUnivariante(value: ContentElement, varName: string): boolean {
    if (Plot.isVar(value, varName)) {
      return true;
    }
    if (value instanceof RealElement) {
      return true;
    }

    if (value instanceof WPolynomial) {
      const p: WPolynomial = value;
      if (p.symbols.length === 1) {
        return Plot.isVar(p.symbols[0], varName);
      }
    }

    return false;
  }

  /**
   *
   */
  private static isVar(value: ContentElement, varName: string): boolean {
    if (value instanceof SymbolElement) {
      return value.getSymbol() === varName || varName == null;
    }
    return false;
  }
}
