import { AnonymousFunction } from '../elements/abstract/AnonymousFunction';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Evaluate } from '../expr/manipulation/Evaluate';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CValidationFunction extends BaseCorrector {
  /**
   *
   */
  public parse(value: string): Node {
    try {
      if (this.useLatex && (!this.env.extensions || !this.env.extensions.latexEnabled)) {
        return null;
      }

      const expr = this.useLatex ? `Parse(LatexToMarkup("${value}"))` : value;
      const token = this.env.expressions.toTokenElement(expr);

      if (token) {
        const node: Node = new Node(token);
        node.userData = expr;
        return node;
      }
    } catch (e) {
      //
    }
    return null;
  }

  /**
   *
   */
  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const fn: AnonymousFunction = target as AnonymousFunction;
    const param: Node = this.parse(value);

    if (param) {
      const evaluator: Evaluate = new Evaluate(fn, this.env);
      const args: ContentElement[] = [];
      args.push(param.value);
      return evaluator.evaluateB(args);
    }

    return false;
  }

  /**
   *
   */
  public get mathKeyboard(): number {
    return KeyboardConfiguration.STANDARD;
  }

  /**
   *
   */
  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    // Do nothing, an example should be
    // provided for this type of correction.
  }
}
