import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { PublisherApiAuth, useMediator } from '@scolab/common-editor';
import { Spinner } from '@scolab/publisher-ui-kit';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoginAppMediator from '../mediators/LoginAppMediator';
import { linkTo, Routes } from '../config/routes';

const ProtectedComponentComponent: React.FC<RouteComponentProps> = observer(({ children, history }) => {
  const mediator = useMediator(LoginAppMediator);
  const { isAuthenticated } = mediator;

  const redirectToLogin = useCallback(() => {
    // Forward using pure javascript because of possible competing React Router instances
    // with their own prefixes.
    window.location.assign(history.createHref({
      pathname: `${linkTo(Routes.Login)}/`,
      search: `Cause=ProtectedComponent&ReturnUrl=${encodeURIComponent(window.location.toString())}`,
    }));
  }, [history]);

  useEffect(() => {
    const validateToken = async () => {
      // When a token is present, but a user is still unauthenticated, it means the token should
      // be validated.
      if (PublisherApiAuth.hasToken() && !isAuthenticated) {
        await mediator.startSessionFromToken();
      }
    };

    validateToken();
  }, [mediator, isAuthenticated]);

  // Show the route's contents if the user is authenticated.
  if (isAuthenticated) {
    return <>{children}</>;
  }

  // Give a moment for the JWT to be validated before redirecting to the
  // login page. This state will handle the period when the JWT is being
  // validated.
  if (PublisherApiAuth.hasToken()) {
    return <Spinner />;
  }

  // At this point, anything else assumes a failure.
  redirectToLogin();
  return <Spinner />;
});

export const ProtectedComponent = withRouter(ProtectedComponentComponent);
