import { runInAction } from 'mobx';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { ContentTagsStore } from '../models/store/ContentTagsStore';
import { IContentTag } from '../models/IContentTag';
import { getLocalizedStringValue } from '../utils/localisation';

@ViewModel
export class ContentTagsMediator {
  @inject(ContentTagsStore)
  private accessor contentTagsStore: ContentTagsStore;

  public get contentTags(): ReadonlyArray<IContentTag> {
    return this.contentTagsStore.contentTagsList;
  }

  public getContentTagNameFromId = (contentTagId: number) => {
    const contentTag = this.getContentTag(contentTagId);
    return this.getContentTagName(contentTag);
  };

  public getContentTagName = (contentTag: IContentTag) => {
    if (!contentTag) return null;
    return getLocalizedStringValue('fr', contentTag.name);
  };

  public getContentTag = (contentTagId: number) => {
    return this.contentTagsStore.contentTagsMap[contentTagId];
  };

  public createContentTag = (subjectId: number, nameFr: string, description: string) => {
    this.contentTagsStore.createContentTag(subjectId, nameFr, description);
  };

  public changeNameFr = (contentTag: IContentTag, newName: string) => {
    runInAction(() => {
      const newValues: Partial<IContentTag> = {
        name: {
          ...contentTag.name,
          fr: newName,
        },
      };
      this.contentTagsStore.updateContentTag(contentTag, newValues);
    });
  };

  public changeDescription = (contentTag: IContentTag, newDescription: string) => {
    runInAction(() => {
      const newValues: Partial<IContentTag> = {
        description: newDescription,
      };
      this.contentTagsStore.updateContentTag(contentTag, newValues);
    });
  };

  public changeSubject = (contentTag: IContentTag, newSubjectId: number) => {
    runInAction(() => {
      const newValues: Partial<IContentTag> = {
        subjectId: newSubjectId,
      };
      this.contentTagsStore.updateContentTag(contentTag, newValues);
    });
  };

  public mergeTags = (tagIdsToMerge: ReadonlyArray<number>, subjectId: number, nameFr: string, description: string) => {
    this.contentTagsStore.mergeContentTags(tagIdsToMerge, subjectId, nameFr, description);
  };

  public delete = (contentTag: IContentTag) => {
    this.contentTagsStore.deleteContentTag(contentTag);
  };
}
