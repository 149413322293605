import React from 'react';
import styled from 'styled-components';
import { TopLabeledInput } from '@scolab/publisher-ui-kit';
import { inject } from '../../../../dependencyInjection/inject';
import AddAlignmentModalMediator from '../../../../mediators/AddAlignmentModalMediator';

export class StandardFilters extends React.Component {
  @inject(AddAlignmentModalMediator)
  private accessor addAlignmentModalMediator: AddAlignmentModalMediator;

  public render(): JSX.Element {
    return (
      <Wrapper>
        <CodeInput
          data-testid="codeFilter"
          label="Code"
          onChange={this.onCodeChange}
          onClear={this.onCodeClear}
          value={this.addAlignmentModalMediator.codeFilter}
        />
        <DescriptionInput
          data-testid="descriptionFilter"
          label="Description"
          onChange={this.onDescriptionChange}
          onClear={this.onDescriptionClear}
          value={this.addAlignmentModalMediator.descriptionFilter}
        />
      </Wrapper>
    );
  }

  private onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ codeInputValue: e.target.value });
    this.addAlignmentModalMediator.setCodeFilter(e.target.value);
  };

  private onCodeClear = () => {
    this.setState({ codeInputValue: null });
    this.addAlignmentModalMediator.setCodeFilter(null);
  };

  private onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ descriptionInputValue: e.target.value });
    this.addAlignmentModalMediator.setDescriptionFilter(e.target.value);
  };

  private onDescriptionClear = () => {
    this.setState({ descriptionInputValue: null });
    this.addAlignmentModalMediator.setDescriptionFilter(null);
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
`;

const TopLabeledInputStyled = styled(TopLabeledInput)`
  height: 40px;
`;

const CodeInput = styled(TopLabeledInputStyled)`
  width: 233px;
`;

const DescriptionInput = styled(TopLabeledInputStyled)`
  flex: 1;
`;
