import React from 'react';
import { observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { inject } from '../dependencyInjection/inject';
import { ILocales, Locales } from '../models/ILocales';
import { LocaleMediator } from '../mediators/LocaleMediator';

export interface ILocaleProviderProps {
  locales: ILocales;
}

@observer
export class LocaleProvider extends React.Component<ILocaleProviderProps> {
  @inject(LocaleMediator)
  private accessor localeMediator: LocaleMediator;

  public render(): React.ReactNode {
    const {
      children,
    } = this.props;
    return (
      <IntlProvider locale={this.localeMediator.languageCode} messages={this.getMessages()}>
        {children}
      </IntlProvider>
    );
  }

  private getMessages = () => {
    const {
      locales,
    } = this.props;
    switch (this.localeMediator.locale) {
      case Locales.fr_CA: return locales.fr_CA;
      case Locales.en_CA: return locales.en_CA;
      case Locales.en_US: return locales.en_US;
      default: return locales.en_US;
    }
  };
}
