import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { WFactors } from '../../elements/tokens/WFactors';
import { WNumber } from '../../elements/tokens/WNumber';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { FactorizeUtil } from '../../funcs/factorization/utils/FactorizeUtil';

/**
 *
 */
export class Factorize extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polynomial: WPolynomial = args.getPolynomial(0);
    if (polynomial) {
      const o: TokenElement[] = this.factors(polynomial, args.env);
      if (o) {
        return new WFactors(o);
      }
      return polynomial;
    }
    return null;
  }

  /**
   *
   */
  private factors(polyArg: WPolynomial, env: Environment): TokenElement[] {
    let poly: WPolynomial = polyArg;
    if (poly.numMonomials === 1) {
      return null;
    }

    let o: TokenElement[];
    const result: TokenElement[] = [];

    const tokens: TokenElement[] = env.polynomials.simplify(poly);
    const coef: TokenElement = tokens[0];
    poly = tokens[1] as WPolynomial;
    let simplified: boolean = false;

    if (!(coef instanceof RealElement) || (coef.toNumber() !== 1)) {
      result.push(coef);
      simplified = true;
    }

    o = this.sqdiff(poly, env);
    if (this.check(poly, o, env)) {
      return result.concat(o);
    }

    o = this.trinomial(poly, env);
    if (this.check(poly, o, env)) {
      return result.concat(o);
    }

    o = this.double(poly, env);
    if (this.check(poly, o, env)) {
      return result.concat(o);
    }

    // Cas de la simplification simple
    if (simplified) {
      result.push(poly);
      return result;
    }

    return null;
  }

  /**
   *
   */
  private check(
    polynomial: WPolynomial,
    factors: TokenElement[],
    env: Environment): boolean {
    if (!factors) {
      return false;
    }
    if (factors.length < 2) {
      return false;
    }

    let temp: TokenElement = factors[0];
    let i: number = 1;
    while (i < factors.length) {
      temp = env.expressions.multiply(temp, factors[i]);
      i++;
    }

    return temp.equalsTo(polynomial);
  }

  /**
   *
   */
  private sqdiff(poly: WPolynomial, env: Environment): TokenElement[] {
    if (poly.numMonomials !== 2) {
      return null;
    }
    // one coef negative and one positive
    if (poly.coefs[0].toNumber() * poly.coefs[1].toNumber() >= 0) {
      return null;
    }

    let commonRational: WRational = new WRational(1, 1, FractionFormatter.getImproperNotation(env.culture));
    const factors: TokenElement[] = [];
    let workingPoly: WPolynomial = poly.clone();

    // If the poly have rational, we need to isolate them.
    if (workingPoly.allRationalOrIntegerCoefficients) {
      // Convert the two elements to rationals.
      const c0: WRational = this.convertToRational(workingPoly.coefs[0], env);
      const c1: WRational = this.convertToRational(workingPoly.coefs[1], env);

      // Find the lowest common denominator.
      const lcd: number = XMath.lcm(c0.denominator, c1.denominator);

      let denominator: number = c0.denominator;

      if (denominator !== lcd) {
        denominator *= lcd;
      }

      workingPoly.coefs[0] = this.removeDenominator(c0, lcd, env);
      workingPoly.coefs[1] = this.removeDenominator(c1, lcd, env);

      commonRational = commonRational.setDenominator(denominator);

      // Check if the resulting poly can be simplify.
      if (!workingPoly.isSimplified()) {
        const tokens: TokenElement[] = env.polynomials.simplify(workingPoly);
        commonRational = commonRational.setNumerator((tokens[0] as RealElement).toNumber());
        workingPoly = tokens[1] as WPolynomial;
      }

      if (commonRational.toNumber() !== 1) {
        factors.push(commonRational);
      }
    }

    // Verify if both coefficient are square.
    if (!this.isSquareReal(workingPoly.coefs[0]) || !this.isSquareReal(workingPoly.coefs[1])) {
      return null;
    }

    const coefs1: RealElement[] = [];
    const coefs2: RealElement[] = [];
    const powers: number[] = [];

    for (let m: number = 0; m < workingPoly.numMonomials; m++) {
      const c: number = workingPoly.coefs[m].toNumber();
      const sq: number = Math.sqrt(Math.abs(c));

      coefs1.push(env.culture.createNumber(sq));
      coefs2.push(env.culture.createNumber(c < 0 ? -sq : sq));

      for (let s: number = 0; s < workingPoly.symbols.length; s++) {
        const p: number = workingPoly.power(m, s);
        if (p % 2 !== 0) {
          return null;
        }
        powers.push(p / 2);
      }
    }

    const p1: WPolynomial = new WPolynomial(workingPoly.symbols, coefs1, powers, env.culture.numberFormatter);
    const p2: WPolynomial = new WPolynomial(workingPoly.symbols, coefs2, powers, env.culture.numberFormatter);

    factors.push(p1, p2);

    return factors;
  }

  private removeDenominator(value: WRational, lcd: number, env: Environment): WNumber {
    if (value.denominator === lcd) {
      return env.culture.createNumber((value).numerator);
    }
    return env.culture.createNumber((value).numerator * lcd);
  }

  private convertToRational(value: RealElement, env: Environment): WRational {
    return (value instanceof WRational) ? value : new WRational(value.toNumber(), 1, FractionFormatter.getImproperNotation(env.culture));
  }

  private isSquareReal(value: RealElement): boolean {
    if (value instanceof WRational) {
      const r: WRational = value;

      return this.isSquareNumber(r.numerator) && this.isSquareNumber(r.denominator);
    }
    return this.isSquareNumber(value.toNumber());
  }

  private isSquareNumber(value: number): boolean {
    const sq: number = Math.sqrt(Math.abs(value));
    return XMath.isInteger(sq);
  }

  /**
   *
   * (3x+4)(2x-5)
   */
  private trinomial(poly: WPolynomial, env: Environment): TokenElement[] {
    if (poly.numMonomials !== 3) {
      return null;
    }
    if (poly.symbols.length !== 1) {
      return null;
    }
    if (poly.degree !== 2) {
      return null;
    }

    const factors: TokenElement[] = [];

    const v: SymbolElement = poly.symbols[0];
    let c2: number;
    let c1: number;
    let c0: number;
    for (let m: number = 0; m < poly.numMonomials; m++) {
      switch (poly.power(m, 0)) {
        case 0:
          c0 = poly.coefs[m].toNumber();
          break;
        case 1:
          c1 = poly.coefs[m].toNumber();
          break;
        case 2:
          c2 = poly.coefs[m].toNumber();
          break;
      }
    }

    let a: number = c2;
    const b: number = c1;
    const c: number = c0;

    // b * b - 4 * a * c
    const delta: number = XMath.safeSubtract(XMath.safeTimes(b, b), XMath.safeTimes(XMath.safeTimes(4, a), c));
    if (delta >= 0) {
      let m0: number = 1;
      let m1: number = 1;
      const aa = XMath.safeTimes(2, a);
      let x0: number = (XMath.safeAdd(-b, Math.sqrt(delta))) / aa;
      let x1: number = (XMath.safeSubtract(-b, Math.sqrt(delta))) / aa;

      if (!XMath.isInteger(x0) && XMath.isInteger(XMath.safeTimes(x0, a))) {
        x0 *= a;
        m0 *= a;
        a = 1;
      } else if (!XMath.isInteger(x1) && XMath.isInteger(XMath.safeTimes(x1, a))) {
        x1 *= a;
        m1 *= a;
        a = 1;
      }

      if (a === 1) {
        factors.push(this.poly1(v, m0, -x0, env));
        factors.push(this.poly1(v, m1, -x1, env));
      } else {
        factors.push(env.culture.createNumber(a));
        factors.push(this.poly1(v, m1, -x1, env));
      }

      return factors;
    }

    return null;
  }

  /**
   * Build a polynomial of degree 1
   */
  private poly1(variable: SymbolElement, a: number, b: number, env: Environment): WPolynomial {
    return new WPolynomial(
      [variable],
      [env.culture.createNumber(a), env.culture.createNumber(b)],
      [1, 0],
      env.culture.numberFormatter);
  }

  /**
   *
   */
  private double(
    poly: WPolynomial,
    env: Environment): TokenElement[] {
    if (poly.numMonomials !== 4) {
      return null;
    }

    if (poly.coefs[0].toNumber()
      * poly.coefs[1].toNumber()
      * poly.coefs[2].toNumber()
      * poly.coefs[3].toNumber() < 0) {
      return null;
    }

    return (new FactorizeUtil(env)).doubleResult(null, poly);
  }
}
