import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {
  FormModal,
  HorizontalSeparator,
  NestedContainer,
  Spinner,
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { inject } from '../../../dependencyInjection/inject';
import { AlignmentType } from '../alignments/AlignmentModel';
import { AddActivitiesAlignmentsCommand } from '../../../command/AddActivitiesAlignmentsCommand';
import { AddPagesAlignmentsCommand } from '../../../command/AddPagesAlignmentsCommand';
import { CurriculumsTreeView } from './curriculums/CurriculumsTreeView';
import { StandardsAndAlignmentsMediator } from '../../../mediators/StandardsAndAlignmentsMediator';
import AddAlignmentModalMediator from '../../../mediators/AddAlignmentModalMediator';
import { CurriculumTreeNodeId } from '../../../models/ICurriculumTreeHeader';
import { CurriculumCardsList } from './curriculum/CurriculumCardsList';
import { GetAlignmentsRequest } from '../../../requests/AlignmentsElasticRequest';
import { StandardsList } from './standards/StandardsList';
import { IStandard } from '../../../models/IStandard';
import { SelectedStandardsList } from './standards/SelectedStandardsList';
import { AlignmentSearchBox, searchBoxHeight } from './AlignmentSearchBox';
import { SubjectFilter } from '../../subject/SubjectFilter';

export interface IAddAlignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: AlignmentType;
}

export interface IAddAlignmentModalState {
  readonly selectedCurriculumIndex: number;
  readonly selectedGradeIndex: number;
  readonly selectedAlignment: IStandard[];
  readonly searchAlignmentInputValue: string;
}

export const ALIGNEMENT_MODAL_TEST_ID = 'ALIGNEMENT_MODAL_TEST_ID';

@observer
export class AddAlignmentModal extends React.Component<IAddAlignmentModalProps, IAddAlignmentModalState> {
  @inject(StandardsAndAlignmentsMediator)
  private accessor mediator: StandardsAndAlignmentsMediator;

  @inject(AddAlignmentModalMediator)
  private accessor addAlignmentModalMediator: AddAlignmentModalMediator;

  constructor(props: IAddAlignmentModalProps) {
    super(props);
    this.state = {
      selectedCurriculumIndex: null,
      selectedGradeIndex: null,
      selectedAlignment: [],
      searchAlignmentInputValue: '',
    };
  }

  public componentDidMount(): void {
    this.addAlignmentModalMediator.fetchCurriculums();
  }

  public render(): React.ReactNode {
    const {
      isOpen,
      type,
    } = this.props;

    const modalTitle = type === AlignmentType.activity
      ? <FormattedMessage id="alignmentAndStandard.addStandardsToActivity" defaultMessage="Add standards to activity" />
      : <FormattedMessage id="alignmentAndStandard.addStandardsToPage" defaultMessage="Add standards to page" />;

    return (
      <>
        <StyledFormModal
          isOpen={isOpen}
          title={modalTitle}
          cancelLabel={<FormattedMessage id="alignmentAndStandard.cancel" defaultMessage="Cancel" />}
          onClose={this.onClose}
          saveLabel={<FormattedMessage id="alignmentAndStandard.addStandards" defaultMessage="Add standards" />}
          onSave={this.addSelected}
          saveDisabled={this.state.selectedAlignment.length === 0}
        >
          <Wrapper
            data-testid={ALIGNEMENT_MODAL_TEST_ID}
          >
            <Splitter>
              <ContentColumnsWrapper>
                <LeftPanel>
                  <AlignmentSearchBox
                    onClick={this.alignmentsSearchClicked}
                    onChange={this.curriculumSearchBoxChange}
                    onClear={this.curriculumSearchBoxClear}
                    onEnter={this.alignmentsSearchClicked}
                    value={this.state.searchAlignmentInputValue}
                  />
                  <TreeViewWrapper>
                    <CurriculumsTreeView
                      onSelect={this.curriculumSearchBoxClear}
                    />
                  </TreeViewWrapper>
                </LeftPanel>
              </ContentColumnsWrapper>
              <ResultColumnsWrapper>
                <RightPanel
                  data-testid="RightPanel"
                >
                  <NestedContainer>
                    {this.renderRightPanel()}
                  </NestedContainer>
                </RightPanel>
              </ResultColumnsWrapper>
            </Splitter>
            <DisplayDiv
              data-testid="DisplayDiv"
            >
              <DisplayDivTitle>
                <FormattedMessage id="alignmentAndStandard.standardsToAdd" defaultMessage="Standards to add:" />
              </DisplayDivTitle>
              <SelectedStandardsList standards={this.state.selectedAlignment} onClear={this.onSelectStandard} />
            </DisplayDiv>
          </Wrapper>
        </StyledFormModal>
      </>
    );
  }

  private onClose = () => {
    this.props.onClose();
  };

  private curriculumSearchBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchAlignmentInputValue: e.target.value });
  };

  private alignmentsSearchClicked = () => {
    GetAlignmentsRequest(this.state.searchAlignmentInputValue).then((response) => {
      this.addAlignmentModalMediator.setSearchResults(response);
      this.addAlignmentModalMediator.setSelectedNode('');
    });
  };

  private curriculumSearchBoxClear = () => {
    this.setState({ searchAlignmentInputValue: '' });
    this.addAlignmentModalMediator.setSearchResults(null);
  };

  private onSelectCurriculum = (id: number) => {
    const newNode = `/${CurriculumTreeNodeId.curriculums}/${CurriculumTreeNodeId.curriculum}/${id.toString()}`;
    this.addAlignmentModalMediator.setSelectedNode(newNode);
    this.addAlignmentModalMediator.ensureNodeOpen([
      `/${CurriculumTreeNodeId.curriculums}`,
      newNode,
    ]);
    this.curriculumSearchBoxClear();
  };

  private onSelectCourse = (id: number) => {
    const { selectedNode } = this.addAlignmentModalMediator;
    const newNode = `${selectedNode}/${CurriculumTreeNodeId.course}/${id.toString()}`;
    this.addAlignmentModalMediator.ensureNodeOpen([
      `/${CurriculumTreeNodeId.curriculums}`,
      selectedNode,
      newNode,
    ]);
    this.addAlignmentModalMediator.setSelectedNode(newNode);
    this.curriculumSearchBoxClear();
  };

  private onSelectStandard = (standard: IStandard) => {
    if (this.state.selectedAlignment.find(s => s.id === standard.id)) {
      this.setState({
        selectedAlignment: this.state.selectedAlignment.filter(s => s.id !== standard.id),
      });
    } else {
      this.setState({
        selectedAlignment: this.state.selectedAlignment.concat(standard),
      });
    }
  };

  private addSelected = async (): Promise<void> => {
    if (this.props.type === AlignmentType.activity) {
      await AddActivitiesAlignmentsCommand(this.mediator.currentActivityId, this.state.selectedAlignment);
    } else {
      await AddPagesAlignmentsCommand(this.mediator.currentPageGuid, this.state.selectedAlignment, this.mediator.currentActivityId);
    }

    this.setState({
      selectedAlignment: [],
    });

    this.onClose();
  };

  private renderSubjectFilter = (): JSX.Element => {
    const { selectedNodeType } = this.addAlignmentModalMediator;
    const selectedOptions = selectedNodeType === CurriculumTreeNodeId.curriculums
      ? this.addAlignmentModalMediator.curriculumsFilterSubjects
      : this.addAlignmentModalMediator.coursesFilterSubjects;
    const onChange = selectedNodeType === CurriculumTreeNodeId.curriculums
      ? this.addAlignmentModalMediator.setCurriculumsFilterSubjects
      : this.addAlignmentModalMediator.setCoursesFilterSubjects;
    return (
      <SubjectFilterWrapper>
        <StyledSubjectFilter
          selectedOptions={selectedOptions}
          onChange={onChange}
        />
      </SubjectFilterWrapper>
    );
  };

  private renderRightPanel = (): JSX.Element => {
    const {
      filteredCurriculumsList,
      selectedNodeType,
      searchResults,
      filteredCoursesList,
    } = this.addAlignmentModalMediator;
    let component = (<Spinner />);

    if (filteredCurriculumsList) {
      if (searchResults) {
        return (
          <StandardsList
            standards={searchResults}
            onSelect={this.onSelectStandard}
            selectedStandards={this.state.selectedAlignment}
          />
        );
      }
      switch (selectedNodeType) {
        case CurriculumTreeNodeId.curriculums:
          component = (
            <>
              {this.renderSubjectFilter()}
              <HorizontalSeparator />
              <CurriculumCardsList
                type={CurriculumTreeNodeId.curriculums}
                data={filteredCurriculumsList}
                onSelect={this.onSelectCurriculum}
              />
            </>
          );
          break;
        case CurriculumTreeNodeId.curriculum:
          component = (
            <>
              {this.renderSubjectFilter()}
              <HorizontalSeparator />
              <CurriculumCardsList
                type={CurriculumTreeNodeId.curriculum}
                data={filteredCoursesList}
                onSelect={this.onSelectCourse}
              />
            </>
          );
          break;
        case CurriculumTreeNodeId.course:
          const standards = this.addAlignmentModalMediator.standardsList;
          component = (
            <StandardsList
              standards={standards}
              selectedStandards={this.state.selectedAlignment}
              onSelect={this.onSelectStandard}
            />
          );
          break;
      }
    }

    return component;
  };
}

const StyledFormModal = styled(FormModal)`
  gap: 0px;
  height: 803px;
  padding: 0px;
  width: 993px;
  > div:first-child,
  > div:last-child {
    padding: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentColumnsWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.modal.separator.color};
  display: flex;
  flex-direction: row;
  height: 480px;
  position: relative;
`;

const ResultColumnsWrapper = styled(ContentColumnsWrapper)`
  flex: 1;
  border-left: 1px solid ${props => props.theme.modal.separator.color};
`;

const LeftPanel = styled.div`
  width: 320px;
`;

const TreeViewWrapper = styled.div`
  height: calc(100% - 1px - ${searchBoxHeight + 'px'});
  overflow-y: auto;
`;

const RightPanel = styled.div`
  background-color: ${props => props.theme.colorset.grey02};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DisplayDiv = styled.div`
  border-top: 1px solid ${props => props.theme.modal.separator.color};
  padding: 16px 0 0;
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.modal.separator.color};
`;

const DisplayDivTitle = styled.div`
  padding: 0 16px;
  color: ${props => props.theme.colorset.grey10};
  font-size: 14px;
  text-transform: uppercase;
`;

const SubjectFilterWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
`;

const StyledSubjectFilter = styled(SubjectFilter)`
  width: 50%;
`;
