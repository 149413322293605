import { Point } from '../../js/geom/Point';

import { XMath } from '../core/XMath';
import { XSort } from '../core/XSort';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { TallyFormatter } from '../elements/formats/lists/TallyFormatter';
import { WList } from '../elements/tokens/WList';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { XString } from '../core/XString';

/**
 *
 */
export class CTally extends BaseCorrector {
  /**
   *
   */
  public parse(value: string): Node {
    try {
      const items: number[] = this.untally(this.sanitizeInput(value));
      const list: WList
        = this.env.culture.listFactory.createFromNumbers(items);

      if (list) {
        const node: Node = new Node(list.applyFormat(new TallyFormatter(this.env.culture)));
        node.userData = `Listify(${items.join(', ')})`;
        return node;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   *
   */
  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    return this.tallyValidation(this.sanitizeInput(value), target as WList);
  }

  /**
   * Corriger comme une liste de points, x doit être la valeur min, et y doit être la
   * valeur max, ne pas tenir compte de l'ordre, donc préordonner les listes de points.
   */
  private tallyValidation(
    value: string,
    target: WList): boolean {
    let source2: Point[] = this.parseTally(value);
    if (!source2) {
      const example
        = this.env.culture.listFactory.createFromNumbers([10, 10, 5, 5, 5, 0.25, 0.25, 0.25]).applyFormat(new TallyFormatter(this.env.culture));
      this.raiseError(
        CommonError.LIST_TALLY_FORMAT_INVALID,
        [XString.preventWordWrap(example.toText(false))]);
    }

    let target2: Point[] = (target.formatter as TallyFormatter).group(target.items);

    if (source2.length !== target2.length) {
      return false;
    }

    source2 = source2.map(CTally.orderPair);
    target2 = target2.map(CTally.orderPair);

    source2 = source2.sort(XSort.xycoordinate);
    target2 = target2.sort(XSort.xycoordinate);

    for (let i: number = 0; i < source2.length; i++) {
      if (!XMath.safeEquals(source2[i].x, target2[i].x)
        || !XMath.safeEquals(source2[i].y, target2[i].y)) {
        return false;
      }
    }

    return true;
  }

  /**
   *
   */
  private static orderPair(value: Point, ..._: any[]): Point {
    if (value.x < value.y) {
      return value;
    }
    return new Point(value.y, value.x);
  }

  /**
   *
   */
  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    w.writeRaw((target as WList).toText(true));
  }

  /**
   *
   */
  public get mathKeyboard(): number {
    return KeyboardConfiguration.NUMERIC;
  }

  /**
   *
   */
  public get inputCapabilities(): InputCapabilities {
    return super.inputWithSymbols('()+×');
  }

  /**
   *
   */
  private untally(value: string): number[] {
    const tally: Point[] = this.parseTally(value);
    if (!tally) {
      return null;
    }

    const o: number[] = [];

    for (let i: number = 0; i < tally.length; i++) {
      for (let k: number = 0; k < tally[i].x; k++) {
        o.push(tally[i].y);
      }
    }

    return o;
  }

  /**
   *
   */
  private parseTally(valueArg: string): Point[] {
    const o: Point[] = [];

    let value = valueArg.replace(/−/g, '-'); // replace minus sign by dash
    value = value.replace(/[\s\(\)]/g, ''); // remove spaces and parenthesis
    const groups: any[] = value.split('+');

    for (let i: number = 0; i < groups.length; i++) {
      const group: string = groups[i];
      const parts: any[] = group.split('×');

      if (parts.length !== 2) {
        return null;
      }

      const count: number = this.numberParser.parseNumber(parts[0]);
      const key: number = this.numberParser.parseNumber(parts[1]);

      if (!XMath.isInteger(count) || count < 1) {
        return null;
      }

      o.push(new Point(count, key));
    }

    return o;
  }
}
