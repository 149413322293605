import { action, observable, runInAction } from 'mobx';
import { sortBy } from 'lodash';
import { Store } from '../../dependencyInjection/Store';
import { ICurriculum } from '../ICurriculum';
import { ICourse } from '../ICourse';
import { ICourseRef } from '../ICourseRef';
import { IStandard } from '../IStandard';
import {
  IStandardsActivitiesCoverageMap, IStandardsPagesCoverageMap,
} from '../../api/curriculums/getStandardsWithActivitiesAndPagesCoverage';
import { IActivityAlignmentQualityPair } from '../IActivityAlignmentQualityPair';
import { getCurriculums } from '../../api/curriculums/getCurriculums';
import { IPageAlignmentQualityPair } from '../IPageAlignmentQualityPair';

@Store('CurriculumsStore.ts/CurriculumsStore')
export class CurriculumsStore {
  private fetchingCurriculums: boolean = false;

  @observable
  public accessor curriculums: ICurriculum[];

  @observable
  public accessor courses: Map<number, ICourse[]>; // key: curriculumId

  @observable
  public accessor standards: Map<number, IStandard[]>; // key: courseId

  @observable // map key: courseId, object map key: standardId
  public accessor activitiesCoverage: Map<number, IStandardsActivitiesCoverageMap>;

  @observable // map key: courseId, object map key: standardId
  public accessor pagesCoverage: Map<number, IStandardsPagesCoverageMap>;

  constructor() {
    runInAction(
      () => {
        this.curriculums = null;
        this.courses = new Map<number, ICourse[]>();
        this.standards = new Map<number, IStandard[]>();
        this.activitiesCoverage = new Map<number, IStandardsActivitiesCoverageMap>();
        this.pagesCoverage = new Map<number, IStandardsPagesCoverageMap>();
      },
    );
  }

  public async fetchCurriculums(): Promise<void> {
    if (this.fetchingCurriculums) return;
    this.fetchingCurriculums = true;
    const curriculums = await getCurriculums();
    this.setCurriculums(curriculums.concat());
    this.fetchingCurriculums = false;
  }

  @action
  private setCurriculums(curriculums: ICurriculum[]): void {
    this.curriculums = curriculums;
  }

  @action
  public addCurriculum(curriculum: ICurriculum): void {
    this.curriculums.push(curriculum);
  }

  @action
  public removeCurriculum(curriculumId: number): void {
    if (this.courses.has(curriculumId)) {
      this.courses.delete(curriculumId);
    }
    this.curriculums = this.curriculums.filter((c) => {
      return c.id !== curriculumId;
    });
  }

  @action
  public removeCourse(curriculumId: number, courseId: number): void {
    const curriculum = this.getCurriculum(curriculumId);
    curriculum.courses = curriculum.courses.filter(c => c.id !== courseId);

    if (this.courses.has(curriculumId)) {
      this.courses.set(curriculumId, this.courses.get(curriculumId).filter(c => c.id !== courseId));
    }

    this.standards.delete(courseId);
    this.activitiesCoverage.delete(courseId);
  }

  @action
  public addCourse(curriculumId: number, course: ICourse): void {
    const curriculum = this.getCurriculum(curriculumId);
    curriculum.courses.push(course);

    const courses = this.getCourses(curriculumId);
    this.setCourses(curriculumId, courses.concat(course));
  }

  @action
  public addStandardAt(curriculumId: number, courseId: number, standard: IStandard, position: number): void {
    this.getCourse(curriculumId, courseId).standardsCount++;

    if (!this.standards.has(courseId)) {
      this.standards.set(courseId, [standard]);
    } else {
      const standards = this.standards.get(courseId);
      this.standards.set(courseId, standards.slice(0, position).concat([standard]).concat(standards.slice(position)));
    }

    const activitiesCoverage = this.activitiesCoverage.get(courseId);
    const newActivitiesCoverage = { ...activitiesCoverage };
    newActivitiesCoverage[standard.id] = [];
    this.activitiesCoverage.set(courseId, newActivitiesCoverage);

    const pagesCoverage = this.pagesCoverage.get(courseId);
    const newPagesCoverage = { ...pagesCoverage };
    newPagesCoverage[standard.id] = [];
    this.pagesCoverage.set(courseId, newPagesCoverage);
  }

  @action
  public removeStandard(curriculumId: number, courseId: number, standardId: number): void {
    this.getCourse(curriculumId, courseId).standardsCount--;

    this.standards.set(courseId, this.standards.get(courseId).filter((s) => {
      return s.id !== standardId;
    }));

    const activitiesCoverage = this.activitiesCoverage.get(courseId);
    const newActivitiesCoverage = { ...activitiesCoverage };
    delete newActivitiesCoverage[standardId];
    this.activitiesCoverage.set(courseId, newActivitiesCoverage);

    const pagesCoverage = this.pagesCoverage.get(courseId);
    const newPagesCoverage = { ...pagesCoverage };
    delete newPagesCoverage[standardId];
    this.pagesCoverage.set(courseId, newPagesCoverage);
  }

  @action
  public setCourses(curriculumId: number, courses: ICourse[]): void {
    this.courses.set(curriculumId, courses);
  }

  @action
  public setStandards(courseId: number, standards: IStandard[]): void {
    this.standards.set(courseId, standards);
  }

  @action
  public setCourseCoverage(courseId: number,
                           activitiesCoverage: IStandardsActivitiesCoverageMap,
                           pagesCoverage: IStandardsPagesCoverageMap): void {
    this.activitiesCoverage.set(courseId, activitiesCoverage);
    this.pagesCoverage.set(courseId, pagesCoverage);
  }

  @action
  public removeAlignment(courseId: number, standardId: number, activityId: number): void {
    this.activitiesCoverage.get(courseId)[standardId]
      = this.activitiesCoverage.get(courseId)[standardId].filter((alignment) => {
        return alignment.activityId !== activityId;
      });
  }

  @action
  public addAlignments(courseId: number, standardId: number, activities: ReadonlyArray<number>, quality: number): void {
    const coverage = this.activitiesCoverage.get(courseId)[standardId];

    const newCoverage = (coverage ?? []).concat(activities.map((activityId) => {
      return {
        activityId,
        quality,
      };
    }));

    this.activitiesCoverage.get(courseId)[standardId] = sortBy(newCoverage, ['activityId']);
  }

  @action
  public removeAlignments(courseId: number, standardId: number, activities: ReadonlyArray<number>): void {
    const coverage = this.activitiesCoverage.get(courseId)[standardId];

    const newCoverage = (coverage ?? []).filter((alignment: IActivityAlignmentQualityPair) => {
      return !activities.includes(alignment.activityId);
    });

    this.activitiesCoverage.get(courseId)[standardId] = sortBy(newCoverage, ['activityId']);
  }

  @action
  public changeAlignmentQuality(courseId: number, standardId: number, activityId: number, quality: number): void {
    const alignment = this.getStandardActivitiesCoverage(courseId, standardId).find(a => a.activityId === activityId);
    alignment.quality = quality;
  }

  public getStandardActivitiesCoverage(courseId: number, standardId: number): ReadonlyArray<IActivityAlignmentQualityPair> {
    const activitiesCoverage = this.activitiesCoverage.get(courseId);

    if (!activitiesCoverage) {
      return [];
    }

    if (!activitiesCoverage.hasOwnProperty(standardId)) {
      activitiesCoverage[standardId] = [];
    }

    return activitiesCoverage[standardId];
  }

  public getStandardPagesCoverage(courseId: number, standardId: number): ReadonlyArray<IPageAlignmentQualityPair> {
    const pagesCoverage = this.pagesCoverage.get(courseId);
    return pagesCoverage && pagesCoverage.hasOwnProperty(standardId)
      ? pagesCoverage[standardId]
      : [];
  }

  public getCurriculum(curriculumId: number): ICurriculum {
    if (!this.curriculums) return null;
    return this.curriculums.find(curriculum => curriculum.id === curriculumId);
  }

  public findCourse(courseId: number): ICourse {
    for (const courses of this.courses.values()) {
      const course = courses.find(course => course.id === courseId);
      if (course) return course;
    }
    return null;
  }

  public getCourse(curriculumId: number, courseId: number): ICourse {
    if (!this.courses.has(curriculumId)) return null;
    return this.courses.get(curriculumId).find(course => course.id === courseId);
  }

  public getStandard(courseId: number, standardId: number): IStandard {
    return this.standards.has(courseId)
      ? this.standards.get(courseId).find(s => s.id === standardId)
      : null;
  }

  public getCourses(curriculumId: number): ICourse[] {
    return this.courses.has(curriculumId)
      ? this.courses.get(curriculumId)
      : null;
  }

  public findCourseRef(courseId: number): ICourseRef {
    const curriculum = this.curriculums.find(c => c.courses.findIndex(c => c.id === courseId) !== -1);
    return curriculum.courses.find(c => c.id === courseId);
  }

  public findCourseCurriculum(courseId: number): ICurriculum {
    return this.curriculums.find(c => c.courses.findIndex(c => c.id === courseId) !== -1);
  }

  public getStandards(courseId: number): IStandard[] {
    if (!this.standards.has(courseId)) return null;
    return this.standards.get(courseId);
  }

  public getDescendantsStandards(courseId: number, standardId: number): IStandard[] {
    const standards = this.getStandards(courseId);
    if (!standards) return [];

    let standardIndex = standards.findIndex(s => s.id === standardId);
    if (standardIndex === -1) return [];

    const standard = standards[standardIndex];
    const descendants: IStandard[] = [];

    standardIndex++;
    while (standardIndex < standards.length) {
      const descendant = standards[standardIndex];
      if (descendant.indent > standard.indent) {
        descendants.push(descendant);
      } else {
        break;
      }
      standardIndex++;
    }

    return descendants;
  }
}
