import { Point } from '../../../js/geom/Point';
import { IDictionary } from '../../../js/utils/IDictionary';

import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../elements/tokens/WListOfSegments';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WPolyline } from '../../elements/tokens/WPolyline';
import { WString } from '../../elements/tokens/WString';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de ligne brisée.
 */
export class Polyline extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
      if (args.getSegments(0)) {
        return this.segments(args.getSegments(0));
      }
    } else if (args.length === 3) {
      if (args.getPoint(0) && args.getString(1) && args.getReals(2)) {
        return this.commands(args.getPoint(0), args.getString(1), args.getReals(2));
      }
    }

    return null;
  }

  /**
   *
   */
  private commands(
    originP: WPoint,
    dirsS: WString,
    lengths: WList): WPolyline {
    const dirs: string = dirsS.getString();

    if (dirs.length !== lengths.count) {
      return null;
    }

    const o: Point[] = [];

    let p: Point = originP.toPoint();
    o.push(p);
    for (let i: number = 0; i < dirs.length; i++) {
      const m: number = lengths.getValueAt(i);
      const d: string = dirs.charAt(i);
      if (!Polyline._v.hasOwnProperty(d)) {
        return null;
      }
      let __v: Point = Polyline._v[d] as Point;
      __v = new Point(__v.x * m, __v.y * m);
      p = p.add(__v);
      o.push(p);
    }

    return new WPolyline(o);
  }

  /**
   *
   */
  private segments(
    value: WListOfSegments): WPolyline {
    const poly: TokenElement = SegmentsUtil.toPoly(value.toSegments());
    if (!poly) {
      throw new MathError(Polyline.ERR_NOT_A_PATH);
    }
    if (poly instanceof WPolyline) {
      return poly;
    }

    if (poly instanceof WPolygon) {
      const v: Point[] = poly.vertices;
      v.push(v[0]); // close the polyline because we are converting a polygon to a polyline.
      return new WPolyline(v);
    }

    return null;
  }

  /**
   *
   */
  private points(
    value: WListOfPoints): WPolyline {
    if (value.count < 2) {
      throw new MathError(Polyline.ERR_NOT_A_PATH);
    }
    return new WPolyline(value.toPoints());
  }

  /**
   *
   */
  private static ERR_NOT_A_PATH: string = 'Not a path';

  /**
   *
   */
  private static _v: IDictionary = Polyline.v();

  /**
   *
   */
  private static v(): IDictionary {
    const o: IDictionary = {};

    o['←'] = new Point(-1, 0);
    o['↑'] = new Point(0, 1);
    o['→'] = new Point(1, 0);
    o['↓'] = new Point(0, -1);

    o['↖'] = new Point(-1, 1);
    o['↗'] = new Point(1, 1);
    o['↘'] = new Point(1, -1);
    o['↙'] = new Point(-1, -1);

    return o;
  }
}
