import { Delegate } from '../../../js/utils/Delegate';

import { XString } from '../../core/XString';
import { ListElement } from '../../elements/abstract/ListElement';
import { Node } from '../../elements/abstract/Node';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { Apply } from '../../elements/constructs/Apply';

/**
 * Utility class to simplify analysis of en expression tree.
 */
export class Skeleton {
  private static WILDCARD: string = '@';

  private static UNDEFINED: string = '?';

  /**
   *
   */
  public static createSkeleton(
    node: Node,
    mask: string = null,
    maxListLength: number = -1): string {
    try {
      let value: string = Skeleton.parse(node, maxListLength);
      if (mask) {
        value = value.split('').map(
          Delegate.partial(Skeleton.guardChar, mask), {}).join('');
      }
      return value;
    } catch (e) {
      return null;
    }
  }

  /**
   *
   */
  private static guardChar(mask: string, char: string, index: number, ..._: any[]): string {
    if (index >= mask.length) {
      return char;
    }
    if (mask.charAt(index) === Skeleton.WILDCARD) {
      return Skeleton.WILDCARD;
    }
    return char;
  }

  /**
   *
   */
  private static parse(
    node: Node,
    maxListLength: number): string {
    if (node.value instanceof Apply) {
      const p: string[] = [];
      for (let i: number = 1; i < node.numChildren; i++) {
        p.push(Skeleton.parse(node.childs[i], maxListLength));
      }
      return `${Skeleton.parse(node.childs[0], maxListLength)}(${p.join(',')})`;
    }
    if (node.value instanceof ListElement) {
      const list: ListElement = node.value;
      if (list.count <= maxListLength) {
        const l: string[] = [];
        for (let k: number = 0; k < list.count; k++) {
          const itemCode: string = list.getItemAt(k).getElementCode();
          l.push(itemCode || Skeleton.UNDEFINED);
        }
        return `(${l.join(',')})`;
      }
    }

    const code: string = node.value.getElementCode();
    return code != null ? node.value.getElementCode() : Skeleton.UNDEFINED;
  }

  /**
   * Push all tokens of an expression into container.
   */
  public static tokens(node: Node, container: any[]): void {
    if (node.value instanceof TokenElement) {
      container.push(node.value);
    }
    for (let i: number = 0; i < node.numChildren; i++) {
      Skeleton.tokens(node.childs[i], container);
    }
  }

  /**
   * @args array of arrays
   * returns array of string
   */
  public static combine(format: string, args: any[]): any[] {
    let c: number = 1;
    const val: any[] = [];
    for (let u: number = args.length - 1; u >= 0; u--) {
      val.unshift(c);
      c *= args[u].length;
    }

    const comb: any[] = [];
    for (let k: number = 0; k < c; k++) {
      const temp: any[] = [format];
      for (let n: number = 0; n < args.length; n++) {
        temp.push(args[n][Math.floor(k / val[n]) % args[n].length]);
      }
      comb.push(XString.substitute.apply(null, temp));
    }

    return comb;
  }
}
