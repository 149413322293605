export const createScriptElement = async (filename: string) => {
  const tagFactory = () => {
    try {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', prefixWithPublicPath(filename));
      return script;
    } catch (e) {
    }
  };

  // Network errors may prevent scripts from loading. This
  // will attempt to load the script up to 3 times before
  // giving up, spreading requests in time.
  return appendLoadableNode(tagFactory())
    .catch(() => waitFor(2000).then(() => appendLoadableNode(tagFactory())))
    .catch(() => waitFor(3000).then(() => appendLoadableNode(tagFactory())))
    .catch(() => {
      throw new Error(`Failed to load script: ${filename}`);
    });
};

export const createStyleElement = async (filename: string) => {
  try {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', prefixWithPublicPath(filename));
    return appendNode(link);
  } catch (e) {
  }
};

export const createPrefetchElement = async (dns: string) => {
  try {
    const link = document.createElement('link');
    link.setAttribute('rel', 'dns-prefetch');
    link.setAttribute('href', dns);
    return appendNode(link);
  } catch (e) {
  }
};

const prefixWithPublicPath = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }
  return `${__webpack_public_path__}vendor/${url}`;
};

const appendNode = (link: HTMLElement) => {
  return new Promise<void>((resolve) => {
    document.head.appendChild(link);
    resolve();
  });
};

const appendLoadableNode = async (element: HTMLElement): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    element.onload = () => resolve();
    element.onerror = () => {
      element.parentNode.removeChild(element);
      reject();
    };
    document.head.appendChild(element);
  });
};

const waitFor = (ms: number) => new Promise<void>(resolve => setTimeout(resolve, ms));
