import { Point } from '../../js/geom/Point';

import { Match } from '../core/Match';
import { XMath } from '../core/XMath';
import { XNumber } from '../core/XNumber';
import { XRound } from '../core/XRound';
import { XSort } from '../core/XSort';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { BaseNumberFormatter } from '../elements/formats/BaseNumberFormatter';
import { CurrencyFormatter } from '../elements/formats/numbers/CurrencyFormatter';
import { LocaleNumberFormatter } from '../elements/formats/numbers/LocaleNumberFormatter';
import { NumberWordsFormatter } from '../elements/formats/numbers/NumberWordsFormatter';
import { PercentFormatter } from '../elements/formats/numbers/PercentFormatter';
import { PositiveNegativeFormatter } from '../elements/formats/numbers/PositiveNegativeFormatter';
import { WNotANumber } from '../elements/tokens/WNotANumber';
import { WNumber } from '../elements/tokens/WNumber';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { AlternateForm } from '../expr/manipulation/alt/AlternateForm';
import { ExpandNumber } from '../expr/manipulation/alt/ExpandNumber';
import { FactorInteger } from '../expr/manipulation/alt/FactorInteger';
import { ScientificNotation } from '../expr/manipulation/alt/ScientificNotation';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CNumberWords } from './CNumberWords';
import { CommonError } from './CommonError';
import { NumberOrPercent } from './NumberOrPercent';
import { BaseCorrector } from './BaseCorrector';
import { WInfinity } from '../elements/tokens/WInfinity';

/**
 *
 */
export class CNumber extends BaseCorrector {
  private form: AlternateForm;

  private plusMinus: NumberOrPercent;

  private checkRounding: boolean;

  private checkWordRepresentation: boolean;

  constructor(
    form: AlternateForm,
    plusMinus: NumberOrPercent,
    checkRounding: boolean,
    checkWordRepresentation: boolean) {
    super();
    this.form = form;
    this.plusMinus = plusMinus;
    this.checkRounding = checkRounding;
    this.checkWordRepresentation = checkWordRepresentation;
  }

  private static RGX_N: string = '[\\d]*[\\.,]?[\\d]+';

  public parse(valueArg: string): Node {
    let node: Node = null;

    let value = this.translateInput(valueArg);

    if (this.form) {
      value = this.noSup(value);
      value = value.replace(/,/g, '.');

      node = new Node(this.env.culture.parseNumber(this.env.expressions.toNumber(value)));
      node.userData = value;
      return node;
    }

    node = new Node(this.numberParser.parseNumberWithFormat(value));
    if (node.value instanceof WNotANumber) {
      node.userData = 'NaN()';
    } else if (node.value instanceof WInfinity) {
      if (node.value.negative) {
        node.userData = '-∞';
      } else {
        node.userData = '∞';
      }
    } else if (node.value instanceof WNumber) {
      const n: string = String(node.value.toNumber());
      if (node.value.formatter instanceof PercentFormatter) {
        node.userData = `ApplyFormat(${n}, PercentFormat())`;
      } else if (node.value.formatter instanceof CurrencyFormatter) {
        node.userData = `ApplyFormat(${n}, CurrencyFormat())`;
      } else {
        node.userData = String(n);
      }
    }
    return node;
  }

  public correct(
    valueArg: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const value1 = valueArg; // original value

    let value = this.translateInput(valueArg);

    if (this.form instanceof ScientificNotation) {
      const value3: Point = this.breakScientificNumber(value);
      if (value3) {
        const c: number = Math.abs(value3.x);
        if (c < 1 || c >= 10) {
          // Gentle reminder if coefficient doesnt follow this rule 1 <= n < 10
          // Should be a different gentle reminder
          this.raiseError(
            CommonError.SCIENTIFIC_NOTATION_FORMAT,
            [this.env.culture.formatNumber(1.13)]);
        }

        const target3: Point
          = ScientificNotation.parse((target as WNumber).toNumber());

        return value3.equals(target3);
      }
      // Gentle reminder on scientific notation
      this.raiseError(
        CommonError.SCIENTIFIC_NOTATION_FORMAT,
        [this.env.culture.formatNumber(1.13)]);
    } else if (this.form instanceof ExpandNumber) {
      const expandNumber: ExpandNumber = this.form;
      switch (expandNumber.form) {
        case 0: // 2000+400+7
          return this.correctExpanded0(value, target as RealElement);
        case 1: // 2 x 1000 + 4 x 100 + 7
          return this.correctExpanded1(value, target as RealElement);
        case 2:
          return this.correctExpanded2(value, target as RealElement);
      }
    } else if (this.form instanceof FactorInteger) {
      // [1-9][\\d]*(<sup>[1-9][\\d]*</sup>)(×[1-9][\\d]*(<sup>[1-9][\\d]*</sup>)?)*

      const factorError: number
        = (this.form).form === FactorInteger.DEFAULT_FORM
          ? CommonError.FACTOR_INTEGER_DEFAULT
          : CommonError.FACTOR_INTEGER_EXP;

      value = this.validateDecomposition(value, (target as WNumber).toNumber(), factorError);
      if (value == null) {
        return false;
      }

      // 1. Replace superscript 2 and superscript 3 by the appropriate markup
      value = this.sup23(value);

      let formatError: boolean = false;

      value = value.replace(/\((\d+)\)/, '$1');
      if (new RegExp('^[1-9][\\d]*(<sup>[1-9][\\d]*<\\/sup>)?(×[1-9][\\d]*(<sup>[1-9][\\d]*<\\/sup>)?)*$').test(value)) {
        value = this.sanitizeIntegerFactorization(value);

        if (value === this.form.alt(target)) {
          return true;
        }

        const nonExp: FactorInteger = new FactorInteger(this.env.culture, FactorInteger.DEFAULT_FORM);
        const exp: FactorInteger = new FactorInteger(this.env.culture, FactorInteger.EXPONENTIAL_FORM);

        if (value === nonExp.alt(target)
          || value === exp.alt(target)) {
          formatError = true;
        } else {
          return false;
        }
      } else {
        formatError = true;
      }

      if (formatError) {
        this.raiseError(factorError);
      }
    } else {
      // Number in decimal or base form
      if (value.length > 0) {
        const n: WNumber = target as WNumber;
        const f: BaseNumberFormatter = n.formatter;
        const t: number = n.toNumber();
        let r: number = this.numberParser.parseNumber(value, f);

        if (isNaN(r)) {
          // Check if the number was written in words
          let isUsingWordRepresentation: boolean = false;
          if (this.checkWordRepresentation) {
            try {
              const cnw: CNumberWords = new CNumberWords();
              super.configureOther(cnw);
              isUsingWordRepresentation
                = cnw.correct(
                  value1,
                  new WNumber(t, 1, false, new NumberWordsFormatter(this.env.culture)));
            } catch (e) {
              //
            }
          }

          if (isUsingWordRepresentation) {
            this.raiseError(CommonError.NUM_NOT_IN_DIGITS);
          } else {
            this.raiseError(CommonError.NAN);
          }
        } else {
          this.checkDecimalAndThousandSeparator(value);

          if (this.plusMinus != null) {
            const diff: number = XRound.safeRound(Math.abs(r - t));
            const maxDiff: number = this.plusMinus.getAbsoluteValue(t);
            if (this.options.expectEstimate && diff === 0 && maxDiff > 0) {
              this.raiseError(CommonError.NOT_AN_ESTIMATE);
            }
            return diff <= maxDiff;
          }

          const decimalSplitIndex: number = value.indexOf(this.env.culture.numberFormatter.decimalSeparator);
          const decimalPart: string = decimalSplitIndex === -1 ? '' : value.substring(decimalSplitIndex + 1);

          if (r === t) {
            if (f instanceof PercentFormatter) { // If the excepted answer is a percent value, expect percent format.
              if (!new RegExp('%$').test(value)) {
                this.raiseError(CommonError.PERCENT_NUMBER_FORMAT, [f.toLocaleString(0.1)]);
              }
            }

            if (f instanceof CurrencyFormatter) {
              // Pour 3 $, accepter 3 ou 3,00
              // Pour 3,50 $, accepter 3,50

              // 1. Check precision by couting digits after decimal separator

              if (decimalSplitIndex !== -1) {
                if (XString.countDigits(decimalPart) !== this.env.culture.currency.precision) {
                  this.raiseError(CommonError.CURRENCY_NUMBER_PRECISION, [f.toLocaleString(2.5)]);
                }
              }
            }

            if (f instanceof LocaleNumberFormatter) {
              const minDecPlaces: number = f.minDecPlaces;
              const keepIntegers: boolean = f.keepIntegers;

              if (!keepIntegers || !XMath.isInteger(r)) {
                if (minDecPlaces > 0) {
                  if (XString.countDigits(decimalPart) < minDecPlaces) {
                    this.raiseError(
                      CommonError.MIN_DEC_PLACES,
                      [this.env.feminize.parse((new NumberWordsFormatter(this.env.culture)).toLocaleString(minDecPlaces)),
                        minDecPlaces > 1 ? 's' : '',
                        f.toLocaleString(XRound.halfAway(5 / 9, minDecPlaces - 1))]);
                  }
                }
              }
            }

            if (f instanceof PositiveNegativeFormatter) {
              const fps: PositiveNegativeFormatter = f;
              // Vérifier le format du nombre signé seulement si on a la bonne réponse
              // Doit commencer par - ou +
              if (fps.positiveFormat === '+{0}'
                && value.charAt(0) !== '\u2212'
                && value.charAt(0) !== '+') {
                this.raiseError(CommonError.SIGNED_NUMBER_FORMAT);
              }
            }

            // Valide qu'il y a toujours un chiffre avant le séparateur décimal
            this.checkZeroBeforeDecimalSep(value);

            return true;
          }
          if (f instanceof PercentFormatter) {
            // Check if the user wrote his number without the percent sign (%)
            r = XMath.safeTimes(this.numberParser.parseNumber(value), 0.01); // Parse as a regular number without formatter
            if (r === t) {
              this.raiseError(CommonError.PERCENT_NUMBER_FORMAT, [f.toLocaleString(0.1)]);
            }
          } else if (this.checkRounding) {
            if (n.approximate) {
              // Numbers formatted with currency appears to have
              // a specific number of decimals, but the can have
              // more internally. The same when using LocaleNumber
              // with a max number of decimals.
              if (r === n.formatValue) {
                return true;
              }
            }

            // Maybe the user didn't round enough, or too much.
            const rd: number = XNumber.decimals(r);
            const td: number = XNumber.decimals(t); //
            const sd: number = XString.countDigits(decimalPart); // 1.30

            // rd - td
            // 1.29 - 1.3
            // 1.3 - 1.29

            const subRounded: boolean = rd > td && td > 0 && XRound.halfAway(r, td) === t;
            const overRounded: boolean = td > rd && rd > 0 && XRound.halfAway(t, rd) === r;

            if (rd === sd) { // non-significant zeros will cancel that gentle reminder
              if (subRounded || overRounded) {
                this.raiseError(
                  (td === 1
                    ? CommonError.ROUND_TO_1_DEC_PLACE
                    : CommonError.ROUND_TO_N_DEC_PLACES),
                  [td]);
              }
            }
          }
        }
      } else {
        this.raiseError(CommonError.NAN);
      }
      return false;
    }

    return true;
  }

  private correctExpanded0(valueArg: string, target: RealElement): boolean {
    const value = this.validateDecomposition(valueArg, target.toNumber(), CommonError.EXPANDED_NUMBER0_FORMAT);
    if (value == null) {
      return false;
    }

    const termPattern = CNumber.RGX_N;
    const rx = new RegExp(`(^${termPattern})(\\+${termPattern})*$`);

    if (rx.test(value)) {
      let numbers: any[] = value.split('+');
      numbers = numbers.map(this.formatAndParse, this);

      if (numbers.some(this.testValZero, this)) {
        this.raiseError(CommonError.EXPANDED_NUMBER_SIGNIFICANT_DIGITS);
      }

      // Commutativité
      numbers = numbers.sort(XSort.numeric);
      numbers = numbers.reverse();

      return numbers.join('+') === (this.form as ExpandNumber).alt(target);
    }

    this.raiseError(CommonError.EXPANDED_NUMBER0_FORMAT);
  }

  private correctExpanded1(valueArg: string, target: RealElement): boolean {
    let value = this.validateDecomposition(valueArg, target.toNumber(), CommonError.EXPANDED_NUMBER1_FORMAT);
    if (value == null) {
      return false;
    }

    // compare without parenthesis, validate parenthesis in user input by simplifying the expression
    const value2 = this.noParenthesis(value);
    const termPattern = `${CNumber.RGX_N}×${CNumber.RGX_N}`;

    const rx = new RegExp(`(^${termPattern})(\\+${termPattern})*$`);
    if (rx.test(value2)) {
      const ds: RegExp = new RegExp('×(' + CNumber.RGX_N + ')', 'g'); // trouve les nombres décimaux et vérifie le séparateur décimal
      let d: Match = Match.tryParse(ds.exec(value2));
      while (d) {
        this.checkDecimalSeparator(d.groups[1]);
        d = Match.tryParse(ds.exec(value2));
      }
      // put the internal decimal separator just before the comparison
      value = value.replace(/,/g, '.');

      const valueN = this.env.expressions.toNumber(value);
      const targetN = target.toNumber();

      if (valueN === targetN) {
        const targetE = ExpandNumber.expand(targetN).map((term: ReadonlyArray<number>) => {
          return [term[0], 10 ** term[1]].sort((n1, n2) => XSort.numeric(n1, n2));
        }).sort((term1, term2) => {
          return XSort.numeric(term1[0], term2[0]);
        });
        const valueE = value2.split('+').map((term: string) => {
          return [
            this.numberParser.parseNumber(term.split('×')[0]),
            this.numberParser.parseNumber(term.split('×')[1]),
          ].sort((n1, n2) => XSort.numeric(n1, n2));
        }).sort((term1, term2) => {
          return XSort.numeric(term1[0], term2[0]);
        });

        if (valueE.length === targetE.length) {
          if (valueE.every((valueTerm, index) => {
            return valueTerm[0] === targetE[index][0]
              && valueTerm[1] === targetE[index][1];
          })) {
            return true;
          }
        }
        this.raiseError(CommonError.EXPANDED_NUMBER1_FORMAT);
      } else {
        return false;
      }
    } else {
      this.raiseError(CommonError.EXPANDED_NUMBER1_FORMAT);
    }
  }

  private correctExpanded2(valueArg: string, target: RealElement): boolean {
    // 2 x 10ÿ3 + 4 x 10ÿ2 + 7 x 10
    // The input return superscript parts wrapped with <sup> </sup>
    // 12<sup>34</sup>a+<sup>3</sup>
    // The expand number class return it in a math format --> 1x10^3+...
    // Format with optional power 1 [1-9]×10(<sup>-?[\\d]+</sup>)?(\\+[1-9]×10(<sup>-?[\\d]+</sup>)?)*

    let value = this.validateDecomposition(valueArg, target.toNumber(), CommonError.EXPANDED_NUMBER2_FORMAT);
    if (value == null) {
      return false;
    }

    // 1. Replace superscript 2 and superscript 3 by the appropriate markup
    value = this.sup23(value);

    // 2.
    let value2 = this.noParenthesis(value);

    const termPattern = `(${CNumber.RGX_N}×${CNumber.RGX_N}<sup>[−-]?${CNumber.RGX_N}<\\/sup>|${CNumber.RGX_N}<sup>[−-]?${CNumber.RGX_N}<\\/sup>×${CNumber.RGX_N})`;
    const rx = new RegExp(`^${termPattern}(\\+${termPattern})*$`);

    // 3) verify the format using the html markup
    if (rx.test(value2)) {
      value2 = this.noSup(value2);
      value = this.noSup(value);
      value = value.replace(/,/g, '.');
      const valueN = this.env.expressions.toNumber(value);
      const targetN = target.toNumber();

      if (valueN === targetN) {
        // array format [coefficient, 10, exponent]
        const targetE = ExpandNumber.expand(targetN).map((term: ReadonlyArray<number>) => {
          return [term[0], 10, term[1]];
        });
        const valueE = value2.split('+').map((term) => {
          if (term.indexOf('×') < term.indexOf('^')) {
            // n x 10 ^ n
            return [
              this.numberParser.parseNumber2(term.substring(0, term.indexOf('×'))),
              this.numberParser.parseNumber2(term.substring(term.indexOf('×') + 1, term.indexOf('^'))),
              this.numberParser.parseNumber2(term.substring(term.indexOf('^') + 1)),
            ];
          }
          // 10 ^ n x n
          return [
            this.numberParser.parseNumber2(term.substring(term.indexOf('×') + 1)),
            this.numberParser.parseNumber2(term.substring(0, term.indexOf('^'))),
            this.numberParser.parseNumber2(term.substring(term.indexOf('^') + 1, term.indexOf('×'))),
          ];
        }).sort((term1, term2) => {
          return XSort.numeric(term1[2], term2[2]);
        }).reverse();

        if (valueE.length === targetE.length) {
          if (valueE.every((valueTerm, index) => {
            return valueTerm[0] === targetE[index][0]
              && valueTerm[1] === targetE[index][1]
              && valueTerm[2] === targetE[index][2];
          })) {
            return true;
          }
        }
        this.raiseError(CommonError.EXPANDED_NUMBER2_FORMAT);
      } else {
        return false;
      }
    } else {
      this.raiseError(CommonError.EXPANDED_NUMBER2_FORMAT);
    }
  }

  private translateInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '^{1}');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
      value = value.replace(/\^(.)/g, '<sup>$1</sup>');
    }
    value = value.replace(/ /g, '');
    value = value.replace(/x/g, '×');
    value = value.replace(/-/g, '\u2212');
    value = XString.trimEnclosingParenthesis(value);
    return value;
  }

  private formatAndParse(number: string, ..._: any[]): number {
    this.checkDecimalSeparator(number);
    return this.numberParser.parseNumber(number);
  }

  private testValZero(val: number, ..._: any[]): boolean {
    return val === 0;
  }

  /**
   * Sanitize steps:
   *  a) replace <sup></sup> tags by ^
   *  b) sort by base (2^4 comes before 3^2)
   *  c) remove ^1 as they are not in the default output but we accept it
   */
  private sanitizeIntegerFactorization(valueArg: string): string {
    let value = this.noSup(valueArg);

    // What if the user enter 2^2*2*3?
    let parts: any[] = value.split('×');

    parts.sort(this.compareBase);

    // Remove exponent 1
    parts = parts.map(
      this.removeExponentialOne, this);

    value = parts.join('×'); // User input ordered

    return value;
  }

  private removeExponentialOne(s: string, ..._: any[]): string {
    const o: any[] = s.split('^');
    if (o.length === 1 || o[1] === '1') {
      return o[0];
    }
    return s;
  }

  private compareBase(a: string, b: string): number {
    const ba: number = Number(a.split('^')[0]);
    const bb: number = Number(b.split('^')[0]);
    return XSort.numeric(ba, bb);
  }

  private sup23(valueArg: string): string {
    let value = valueArg;
    value = value.split('²').join('<sup>2</sup>');
    value = value.split('³').join('<sup>3</sup>');
    return value;
  }

  private noSup(valueArg: string): string {
    let value = valueArg;
    value = value.replace(/<sup>/g, '\^');
    value = value.replace(/<\/sup>/g, '');
    return value;
  }

  private noParenthesis(valueArg: string): string {
    let value = valueArg;
    value = value.split('(').join('');
    value = value.split(')').join('');
    return value;
  }

  public writeTo(w: IWriter, target: ContentElement, ...targets: any[]): void {
    let i: number;
    const n: WNumber = target as WNumber;

    if (this.form instanceof ScientificNotation) {
      const sc: Point = ScientificNotation.parse(n.toNumber());
      w.writeNumber(sc.x);
      w.writeInfixOperator('×');
      w.writeNumber(10);
      w.writeSuperscript(sc.y);
    } else if (this.form instanceof ExpandNumber) {
      const baseExponents: any[] = ExpandNumber.expand(n.toNumber());
      for (i = 0; i < baseExponents.length; i++) {
        if (i > 0) {
          w.writeInfixOperator('+');
        }

        const baseExponent: any[] = baseExponents[i];

        switch (this.form.form) {
          case 0: // 2400 --> 2000 + 400
            w.writeNumber(XRound.safeRound(baseExponent[0] * (10 ** baseExponent[1])));
            break;
          case 1: // 2400 --> 2 x 1000 + 4 x 100
            w.writeInfixOperator('(');
            w.writeNumber(baseExponent[0]);
            w.writeInfixOperator('×');
            w.writeNumber(10 ** baseExponent[1]);
            w.writeInfixOperator(')');
            break;
          case 2: // 2400 --> 2 x 10 ^ 3 + 4 x 10 ^ 2
            w.writeInfixOperator('(');
            w.writeNumber(baseExponent[0]);
            w.writeInfixOperator('×');
            w.writeNumber(10);
            w.writeSuperscript(baseExponent[1]);
            w.writeInfixOperator(')');
            break;
        }
      }
    } else if (this.form instanceof FactorInteger) {
      const factorForm: FactorInteger = this.form;
      switch (factorForm.form) {
        case FactorInteger.DEFAULT_FORM: // 2 × 2 × 3

          const factors: any[] = XNumber.factors(n.toNumber());
          for (i = 0; i < factors.length; i++) {
            const factor: number = factors[i];
            if (i > 0) {
              w.writeInfixOperator('×');
            }
            w.writeNumber(factor);
          }

          break;
        case FactorInteger.EXPONENTIAL_FORM: // 2² × 3

          const powers: any[] = FactorInteger.powers(n.toNumber());

          for (i = 0; i < powers.length; i++) {
            const power: any[] = powers[i];
            if (i > 0) {
              w.writeInfixOperator('×');
            }
            w.writeNumber(power[0]);
            if (power[1] > 1) {
              w.writeSuperscript(power[1]);
            }
          }

          break;
      }
    } else {
      w.writeRaw(n.toText(true));
    }
  }

  public get mathKeyboard(): number {
    if (this.useElementaryKeyboard12) {
      return KeyboardConfiguration.ELEMENTARY12;
    }

    const nk: number = KeyboardConfiguration.NUMERIC;
    const ek: number = KeyboardConfiguration.NUMERIC_EXP;
    if (this.form) {
      if (this.form instanceof ExpandNumber) {
        return this.form.form === 2 ? ek : nk;
      }
      if (this.form instanceof FactorInteger) {
        return this.form.form === FactorInteger.EXPONENTIAL_FORM ? ek : nk;
      }
      if (this.form instanceof ScientificNotation) {
        return ek;
      }
    }
    return nk;
  }

  public get inputCapabilities(): InputCapabilities {
    let symbols: string = null;
    let superscript: boolean = false;
    if (this.form) {
      if (this.form instanceof ScientificNotation) {
        symbols = '×−';
        superscript = true;
      } else if (this.form instanceof ExpandNumber) {
        switch (this.form.form) {
          case 0:
            symbols = '+';
            break;
          case 1:
            symbols = '+×';
            break;
          case 2:
            symbols = '+×−';
            superscript = true;
            break;
        }
      } else if (this.form instanceof FactorInteger) {
        symbols = '×';
        superscript = this.form.form === FactorInteger.EXPONENTIAL_FORM;
      }
    }

    const i: InputCapabilities = super.inputWithSymbols(symbols);
    i.superscript = superscript;

    if (symbols && symbols.indexOf('×') !== -1) {
      i.xMultiplicationSign = true;
    }

    return i;
  }

  public get useElementaryKeyboard12(): boolean {
    if (!this.env.culture.configuration.useElementaryKeyboard12) {
      return false;
    }
    if (this.form !== null) {
      return false;
    }
    if (this.origin instanceof WNumber) {
      return XMath.isInteger(this.origin.toNumber());
    }
    return false;
  }

  private validateDecomposition(
    value: string,
    target: number,
    error: number): string {
    const o: any[] = this.splitDecomposition(value);
    if (o == null) {
      this.raiseError(error);
    }

    if (o.length > 1) {
      if (isNaN(o[1])) {
        this.raiseError(error);
      } else if (target !== o[1]) {
        return null;
      }
    }

    return o[0];
  }

  /**
   * 10 + 2 returns ["10 + 2"]
   * 12 = 10 + 2 returns ["10 + 2", 12]
   * 10 + 2 = 12 returns ["10 + 2", 12]
   */
  private splitDecomposition(
    value: string): any[] {
    if (value.indexOf('=') === -1) {
      return [value];
    }

    let o: any[] = value.split('=');
    if (o.length !== 2) {
      return null;
    }

    const n: number = this.numberParser.parseNumber(o[1]);
    if (isNaN(n)) {
      o = o.reverse();
      o[1] = this.numberParser.parseNumber(o[1]);
    } else {
      o[1] = n;
    }
    return o;
  }

  private static RGX_SC_NUMBER: string
    = '^([−]?[\\d]*[\\.,]?[\\d]+)×10<sup>([−]?[\\d]*)</sup>$';

  /**
   * ([-]?[\d]*[\.,]?[\d]+)×10<sup>([-]?[\d]*)</sup>
   *
   * a) Nombre décimal
   * b) Signe de multiplication
   * c) Le nombre 10
   * d) Exponsant entier (négatif ou positif)
   *
   * Exemple de bon format reçu dans "value"
   * 1×10<sup>2</sup>
   */
  private breakScientificNumber(
    value: string): Point {
    if (new RegExp(CNumber.RGX_SC_NUMBER).test(value)) {
      const o: Match = Match.tryParse((new RegExp(CNumber.RGX_SC_NUMBER)).exec(value));
      this.checkDecimalSeparator(String(o.groups[1]));
      const mantissa: number = this.numberParser.parseNumber(String(o.groups[1]));
      const exponent: number = this.numberParser.parseNumber(String(o.groups[2]));
      return new Point(mantissa, exponent);
    }
    return null;
  }
}
