import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { RealsImpl } from '../../elements/utils/RealsImpl';

/**
 * Operations on polynomials.
 */
export class PolynomialsImpl {
  /**
   *
   */
  private realsImpl: RealsImpl;

  /**
   *
   */
  constructor(realsImpl: RealsImpl) {
    this.realsImpl = realsImpl;
  }

  /**
   *
   */
  public multiplyR(a: WPolynomial, b: RealElement): WPolynomial {
    const r: WPolynomial = a.clone();
    for (let m: number = 0; m < r.numMonomials; m++) {
      r.coefs[m] = this.realsImpl.times(r.coefs[m], b);
    }
    return r;
  }

  /**
   *
   */
  public multiply(a: WPolynomial, b: WPolynomial): WPolynomial {
    // Faire une copie des deux polynômes dans un polynôme qui
    // contient les variables combinées des deux polynômes
    const p: WPolynomial
      = new WPolynomial(
        SymbolElement.merge(a.symbols, b.symbols),
        [],
        [],
        a.formatter);

    const c: WPolynomial = p.add(a);
    const d: WPolynomial = p.add(b);

    for (let i: number = 0; i < c.numMonomials; i++) {
      for (let k: number = 0; k < d.numMonomials; k++) {
        for (let g: number = 0; g < p.symbols.length; g++) {
          // push the sum of the powers
          p.powers.push(c.power(i, g) + d.power(k, g));
        }
        p.coefs.push(this.realsImpl.times(c.coefs[i], d.coefs[k]));
      }
    }

    return p;
  }

  /**
   * The polynomials must have the same structure,
   * and the second must have only one monomial.
   */
  public divide(
    dividend: WPolynomial,
    divisor: WPolynomial): WPolynomial {
    // Validate
    if (divisor.numMonomials !== 1) {
      return null;
    }
    if (dividend.symbols.length !== divisor.symbols.length) {
      return null;
    }

    let s: number;

    for (s = 0; s < dividend.symbols.length; s++) {
      if (!dividend.symbols[s].equalsTo(divisor.symbols[s])) {
        return null;
      }
    }

    // Divide
    const r: WPolynomial = dividend.clone();
    for (let m: number = 0; m < dividend.numMonomials; m++) {
      for (s = 0; s < r.symbols.length; s++) {
        r.powers[r.poweri(m, s)] -= divisor.power(0, s);
      }

      r.coefs[m] = this.realsImpl.divideR(r.coefs[m], divisor.coefs[0], true);
    }
    return r;
  }

  /**
   *
   */
  public addR(a: WPolynomial, b: RealElement): WPolynomial {
    const copy: WPolynomial = a.clone();

    for (let i: number = copy.numMonomials - 1; i >= 0; i--) { // if the monomials are ordered then the constant should be at the end so start by the end
      if (copy.sumPowers(i) === 0) { // constant monomial
        copy.coefs[i] = this.realsImpl.add(copy.coefs[i], b);
        return copy;
      }
    }

    // if the code come here then it means that constant
    // monomial is not currently declared so we add it.
    copy.coefs.push(b);
    const powers: number[] = copy.newMonomial();
    for (let j: number = 0; j < powers.length; j++) {
      copy.powers.push(powers[j]);
    }
    return copy;
  }

  /**
   *
   */
  public simplify(a: WPolynomial): TokenElement[] {
    const commonFactor: WPolynomial = a.commonFactor;
    return [commonFactor.normalize(this.realsImpl), this.divide(a, commonFactor).normalize(this.realsImpl)] as TokenElement[];
  }
}
