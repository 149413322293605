import { IPageContent } from '@scolab/content-model';
import { publisherApi } from '../../utils/fetch';

/**
 * Fetches the page with GUID d312abf4-aaf4-438d-990c-a57758e72f64 from activity 9752.
 */
export const pageContentBlankPageContent = async (): Promise<IPageContent> => {
  const response = await publisherApi<IPageContent>(`/pages/content/blank`);
  return response.json();
};
