import { XString } from '../core/XString';
import { StringNavigator } from '../core/str/StringNavigator';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { NumberWordsFormatter } from '../elements/formats/numbers/NumberWordsFormatter';
import { WNumber } from '../elements/tokens/WNumber';
import { WString } from '../elements/tokens/WString';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { COptions } from './COptions';
import { CNumber } from './CNumber';
import { CString } from './CString';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CNumberWords extends BaseCorrector {
  private cs: CString;

  private cn: CNumber;

  private formatter: NumberWordsFormatter;

  /**
   *
   */
  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.cs = new CString();
    this.cn = new CNumber(null, null, true, false);
    this.formatter = new NumberWordsFormatter(env.culture);
    super.configureOther(this.cs);
    super.configureOther(this.cn);
  }

  /**
   *
   */
  public parse(valueArg: string): Node {
    const value = this.sanitizeInput(valueArg);
    const n: number = this.formatter.fromLocaleString(value);
    if (isNaN(n)) {
      return null;
    }

    const node: Node = new Node(new WNumber(n, 1, false, this.formatter));
    node.userData = `ApplyFormat(${n}, NumberWordsFormat())`;
    return node;
  }

  /**
   * Clean number-into-words string with less strict rules before comparison.
   */
  private withoutDashes(
    valueArg: string): string {
    let value = this.strict(valueArg);
    value = value.replace(/-/g, ' ');
    return value;
  }

  /**
   * Clean number-into-words string with strict rules before comparison.
   */
  private strict(valueArg: string): string {
    let value = XString.trim(valueArg);
    value = value.replace(/\s+/g, ' '); // collapse spaces
    return value;
  }

  private words(str: string): string[] {
    const o: string[] = [];
    const n: StringNavigator = new StringNavigator(str);

    let w: string = n.nextWord();
    while (w) {
      o.push(w);
      w = n.nextWord();
    }
    return o;
  }

  /**
   * source: vingts
   * target: vingt
   *
   * source: troi
   * target: trois
   */
  private equalsIgnoreExtraS(
    source: string,
    target: string): boolean {
    if (source === target) {
      return true;
    }

    return source.charAt(source.length - 1) === 's'
      && source.substring(0, source.length - 1) === target;
  }

  public correct(
    valueArg: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const value = this.sanitizeInput(valueArg);

    const n: WNumber = target as WNumber;
    let s: WString = new WString(this.strict(n.toText(true)));

    if (this.cs.correct(this.strict(value), s)) { // Strict comparison
      return true;
    }

    if (/\s-|-\s/.test(value)) {
      this.raiseError(CommonError.NO_SPACE_BEFORE_OR_AFTER_DASH);
    }

    // PP: À cause de l'état actuel des manuels scolaire qui appliquent ou non la nouvelle orthographe,
    // il serait peut-être prudent de tolérer une réponse où il manque des traits d'union,
    // tout en utilisant un message ad hoc de la part d'Alfred : « Tu as oublié quelques traits d'union. »
    s = new WString(this.withoutDashes(n.toText(true)));
    if (this.cs.correct(this.withoutDashes(value), s)) {
      const sa: string = this.strict(n.toText(true));
      const sb: string = this.strict(value);

      // On doit gérer trois cas, soit des tirets en trop, manquants, ou les deux
      if (this.missingDashes(sa, sb) && this.extraDashes(sa, sb)) {
        this.raiseError(CommonError.NUM_IN_WORDS_DASH_MISUSE);
      } else if (this.missingDashes(sa, sb)) {
        this.raiseError(CommonError.NUM_IN_WORDS_DASH_MISSING);
      } else if (this.extraDashes(sa, sb)) {
        this.raiseError(CommonError.NUM_IN_WORDS_DASH_EXTRA);
      }
    }

    // Bad answer, but see if we can cut some slack on string comparison
    if (this.env.culture.languageCode === 'fr') {
      // Les chiffres sont invariants, on peut donc comparer mot par mot
      const sw: string[] = this.words(value);
      const tw: string[] = this.words(n.toText(true));

      if (sw.length === tw.length) {
        let testExtraS: boolean = true;
        for (let k: number = 0; k < sw.length; k++) {
          if (!this.equalsIgnoreExtraS(sw[k], tw[k])) {
            testExtraS = false;
            break;
          }
        }
        if (testExtraS) {
          this.raiseError(CommonError.NUM_IN_WORDS_INVARIABLE);
        }
      }
    }

    let numeric: boolean = false; // Indicates that the answer was written as a numeric number.

    try {
      if (this.cn.correct(value, n)) {
        numeric = true;
        // We can't throw the common error here since we want to catch those coming from CNumber.correct
      } else {
        return false;
      }
    } catch (e) {
      if (e instanceof CommonError) {
        return false;
      }
      throw e;
    }

    if (numeric) {
      // The right number was inputed, we ask the user to rewrite in words.
      this.raiseError(CommonError.NUM_NOT_IN_WORDS);
    }

    // Will never reach here.
    return false;
  }

  /**
   * Returns true if b contains spaces where a dash was required by a
   */
  private missingDashes(
    a: string,
    b: string): boolean {
    for (let i: number = 0; i < a.length; i++) {
      if (i < b.length) {
        if (b.charAt(i) === ' ' && a.charAt(i) === '-') {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Returns true if b contains dashes where a space was required by a
   */
  private extraDashes(
    a: string,
    b: string): boolean {
    for (let i: number = 0; i < a.length; i++) {
      if (i < b.length) {
        if (b.charAt(i) === '-' && a.charAt(i) === ' ') {
          return true;
        }
      }
    }

    return false;
  }

  public get inputCapabilities(): InputCapabilities {
    return this.cs.inputCapabilities;
  }

  public get mathKeyboard(): number {
    return this.cs.mathKeyboard;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    this.cs.writeTo(w, new WString((target as WNumber).toText(true)));
  }
}
