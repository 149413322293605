import { ContextModelStore } from '../models/store/ContextModelStore';
import { generateContextsCommand } from './GenerateContextsCommand';
import { IVariablesEditor } from '../models/IVariablesEditor';
import { instantiate } from '../dependencyInjection/instantiate';

export const setContextModelsCommand = async (variablesEditor: IVariablesEditor) => {
  const contextModelStore = instantiate(ContextModelStore);
  contextModelStore.setVariablesEditor(variablesEditor);
  await generateContextsCommand(contextModelStore.variablesEditor);
};
