import { MathError } from '../../core/MathError';
import { XSort } from '../../core/XSort';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { Expression } from '../../elements/abstract/Expression';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { Node } from '../../elements/abstract/Node';
import { RealElement } from '../../elements/abstract/RealElement';
import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { Apply } from '../../elements/constructs/Apply';
import { Interval } from '../../elements/constructs/Interval';
import { Lambda } from '../../elements/constructs/Lambda';
import { List } from '../../elements/constructs/List';
import { Root } from '../../elements/constructs/Root';
import { Set } from '../../elements/constructs/Set';
import { FormatProvider } from '../../elements/factories/FormatProvider';
import { ListFactory } from '../../elements/factories/ListFactory';
import { RadicalFormatter } from '../../elements/formats/radicals/RadicalFormatter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { WError } from '../../elements/tokens/WError';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { WNumber } from '../../elements/tokens/WNumber';
import { WPair } from '../../elements/tokens/WPair';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../ArgumentsObject';
import { Environment } from '../Environment';
import { Evaluate } from './Evaluate';
import { Formatter } from './Formatter';
import { Skeleton } from './Skeleton';
import { CrossMultiply } from './rules/CrossMultiply';
import { FractionsArithmetic } from './rules/FractionsArithmetic';
import { IManipulationRule } from './rules/IManipulationRule';
import { NegativeDistributivity } from './rules/NegativeDistributivity';
import { NegativeExponent } from './rules/NegativeExponent';
import { RadicalRationalBase } from './rules/RadicalRationalBase';
import { RadicalsDivision } from './rules/RadicalsDivision';
import { ReduceRadical } from './rules/ReduceRadical';
import { AlmostEqualTo } from '../../funcs/relational/AlmostEqualTo';
import { RecallFunction } from '../../elements/abstract/RecallFunction';
import { Recall } from '../../elements/constructs/Recall';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { Distributivity } from './rules/Distributivity';

/**
 *
 */
export class ApplyRecursive {
  private expr: Expression;

  private env: Environment;

  private format: FormatProvider;

  private formatter: Formatter;

  /**
   *
   */
  constructor(
    expr: Expression,
    env: Environment,
    format: FormatProvider = null) {
    this.expr = expr;
    this.env = env;
    this.format = format;
    this.formatter = new Formatter(format);
  }

  /**
   * Return the node, after trying to simplify the expression tree.
   */
  private copy: Node;

  private verbose: boolean;

  public simplify(
    stateful: boolean,
    verbose: boolean,
    includeFirstState: boolean): Expression {
    this.verbose = verbose;

    this.startSeed = this.env.prng.count; // take note of the current prng generation so we can later check if it was used.
    this._states = [];
    this.copy = this.expr.root.clone();

    if (includeFirstState && stateful) {
      this.states.push(this.captureState(true, null, null, null, 'case-4'));
    }

    /*
    applyPEMDAS is required when we need to respect a precise order, when we display to the user
    the steps. But this method is a little less efficient than calling directly applyRecursive, so if
    we don't need to show the steps, we use applyWithoutPEMDAS.
    */
    stateful
      ? this.applyDeepestFirst(this.copy)
      : this.applyDefaultOrder(this.copy);

    this.applyValueManipulationRules(stateful ? ApplyRecursive.STATEFUL : ApplyRecursive.STATELESS);

    if (!this.ignoreLastState && stateful) {
      this.states.push(this.captureState(true, null, null, null, 'case-5'));
    }

    this.setFormat(this.copy, false);
    const numStates: number = this.states.length;

    if (numStates > 0) {
      this.setFormat(this.states[numStates - 1].root, true);

      // Offset comments and tags on states
      for (let k: number = this.states.length - 1; k >= 1; k--) {
        this.states[k].comment = this.states[k - 1].comment;
        this.states[k].tag = this.states[k - 1].tag;
      }
      this.states[0].comment = null;
    }

    this.endSeed = this.env.prng.count;
    return new Expression(this.copy);
  }

  private _states: Expression[];

  public get states(): Expression[] {
    return this._states;
  }

  private startSeed: number;

  private endSeed: number;

  /**
   * Indicates whether this expression generated a random
   * value by checking if the current Prng was used.
   */
  public get isRandom(): boolean {
    return this.endSeed > this.startSeed;
  }

  private applyValueManipulationRules(stateMode: number): void {
    if (!this.copy.isLeaf) {
      return;
    }
    for (let i: number = 0; i < ApplyRecursive.bubbleRules.length; i++) {
      const rule: IManipulationRule = ApplyRecursive.bubbleRules[i];
      if (!rule.willApply(stateMode)) {
        continue;
      }
      let element: ContentElement = null;
      do {
        rule.resetBeforeApply();
        element = rule.applyValue(this.copy.value, this.format, stateMode, this.env, true);
        if (element) {
          if (stateMode === ApplyRecursive.STATEFUL) {
            this.states.push(this.captureState(rule.useEmphasis, rule.comment, rule.tag, this.copy, 'case-6'));
          }
          if (rule.decorationRemoved) {
            // last step displayed might have decorated elements,
            // but there won't be an extra step showing the same
            // element without decoration.
            this.doIgnoreLastState('ignore-last-state-2');
          }
          this.copy.clear();
          this.copy.value = element;
        }
      } while (element);
    }
  }

  private static STATELESS: number = -1;

  private static STATEFUL: number = 0;

  private applyDefaultOrder(node: Node): void {
    this.preApplyRecursive(node, ApplyRecursive.STATELESS);
    this.applyRecursive(node, ApplyRecursive.STATELESS);
  }

  private maxDepth: number;

  private pendingStateMode: number;

  private applyDeepestFirst(node: Node): void {
    // Wrap lambda and compute the maximum parenthesis depth
    this.maxDepth = 0;
    this.pendingStateMode = 0;
    this.preApplyRecursive(node, ApplyRecursive.STATEFUL);

    // Apply the deeper nodes before
    while (this.maxDepth >= 0) {
      if (this.maxDepth === 0 && this.pendingStateMode !== 0) {
        this.pendingStateMode = ApplyRecursive.STATEFUL;
        // clearElementsStyles(node);
        this.states.push(this.captureState(false, null, null, node, 'case-7'));
      }
      this.applyDepth(node, this.maxDepth, this.pendingStateMode);
      this.maxDepth--;
    }
  }

  private preApplyRecursive(node: Node, stateMode: number): void {
    let i: number;

    for (i = 0; i < ApplyRecursive.captureRules.length; i++) {
      const rule: IManipulationRule = ApplyRecursive.captureRules[i];
      if (!rule.willApply(stateMode)) {
        continue;
      }
      let node2: Node = null;
      do {
        rule.resetBeforeApply();
        node2 = rule.applyNode(node, stateMode, this.env);
        if (node2) {
          if (stateMode === ApplyRecursive.STATEFUL) {
            this.states.push(this.captureState(rule.useEmphasis, rule.comment, rule.tag, node, 'case-8'));
          }
          node.value = node2.value;
          node.absorb(node2);
          if (rule.pauseState !== 0) {
            this.states.push(this.captureState(rule.useEmphasis, rule.comment, rule.tag, node, 'case-9'));
          }

          this.pendingStateMode = (this.pendingStateMode | rule.pauseState) & ~rule.resumeState;
        }
      } while (node2);
    }

    for (i = 0; i < node.numChildren; i++) {
      this.preApplyRecursive(node.childs[i], stateMode);
    }

    if (node.value instanceof Lambda) {
      this.applyLambda(node);
    }

    this.maxDepth = Math.max(this.maxDepth, node.groupDepth);
  }

  private applyLambda(node: Node): void {
    const fn: AnonymousFunction = new AnonymousFunction(node.clone(), this.env.options.piecewiseRange);
    node.value = fn;
    node.clear();

    const rc: RecallFunction = new RecallFunction(fn);
    this.applyRecall(fn.node, rc);
  }

  private applyRecall(node: Node, recall: RecallFunction): void {
    if (node.value instanceof Recall) {
      node.value = recall;
    } else {
      node.childs.forEach((child: Node) => this.applyRecall(child, recall));
    }
  }

  private applyDepth(node: Node, depth: number, stateMode: number): void {
    if (node.groupDepth === depth) {
      this.applyLeftRightByPriority(node, stateMode);
      if (this.prioritySkipList.length > 0) {
        this.applyLeftRightByPriority(node, stateMode);
      }
    } else {
      for (let i: number = 0; i < node.numChildren; i++) {
        this.applyDepth(node.childs[i], depth, stateMode);
      }
    }
  }

  private priorityList: number[];

  private prioritySkipList: number[]; // Contains priority index that were skipped and to be treated later on

  private applyLeftRightByPriority(node: Node, stateMode: number): void {
    this.priorityList = [];
    this.prioritySkipList = [];
    this.createPriorityList(node);
    this.priorityList = this.priorityList.sort(XSort.numeric);

    while (this.priorityList.length > 0) {
      const priority: number = this.priorityList.pop();
      while (this.prioritySkipList.indexOf(priority) !== -1) {
        this.prioritySkipList.splice(this.prioritySkipList.indexOf(priority), 1);
      }
      this.applyRecursive(node, stateMode, priority);
    }
  }

  private createPriorityList(node: Node): void {
    if (this.priorityList.indexOf(node.priority) === -1) {
      this.priorityList.push(node.priority);
    }
    for (let i: number = 0; i < node.numChildren; i++) {
      this.createPriorityList(node.childs[i]);
    }
  }

  private static captureRules: any[]
    = [
      new CrossMultiply(),
    ];

  private static bubbleRules: any[]
    = [
      new CrossMultiply(),
      new RadicalRationalBase(),
      new FractionsArithmetic(),
      new RadicalsDivision(1),
      new NegativeExponent(),
      new Distributivity(),
      new ReduceRadical(),
      /* new ReduceFraction(), */
      new NegativeDistributivity(),
    ];

  /**
   *
   */
  private applyRecursive(
    current: Node,
    stateMode: number,
    priority: number = -1): void {
    let i: number;

    // Apply from bottom to top
    for (i = 0; i < current.numChildren; i++) {
      this.applyRecursive(current.childs[i], stateMode, priority);
    }

    if (current.value instanceof Interval) {
      const interval: WInterval = this.constructInterval(current);
      if (interval) {
        current.clear();
        current.value = interval;
      }
    } else if (current.value instanceof Root) {
      const radical: WRadical = this.constructRadical(current);
      if (radical) {
        current.clear();
        current.value = radical;
      }
    } else if (current.value instanceof List) {
      const list: ListElement = this.constructList(current);
      if (list) {
        current.clear();
        current.value = list;
      }
    } else if (current.value instanceof Set) {
      const dictionary: WDictionary = this.constructDictionary(current);
      if (dictionary) {
        current.clear();
        current.value = dictionary;
      } else {
        const _set: WFiniteSet = this.constructSet(current);
        if (_set) {
          current.clear();
          current.value = _set;
        }
      }
    } else if (current.value instanceof Apply) {
      if (current.priority === priority || priority === -1 || ApplyRecursive.isFunctionApplication(current)) {
        let cancel: boolean = false;
        let modified: boolean = false;
        for (i = 0; i < ApplyRecursive.bubbleRules.length; i++) {
          const rule: IManipulationRule = ApplyRecursive.bubbleRules[i];
          if (!rule.willApply(stateMode)) {
            continue;
          }

          let node2: Node = null;
          do {
            rule.resetBeforeApply();
            node2 = null;
            if (cancel) {
              if (current.isLeaf) {
                const value2: ContentElement = rule.applyValue(current.value, this.format, stateMode, this.env, false);
                if (value2) {
                  node2 = new Node(value2);
                }
              }
            } else {
              node2 = rule.applyNode(current, stateMode, this.env);
            }

            if (node2) {
              modified = true;

              if (stateMode === ApplyRecursive.STATEFUL) {
                this.states.push(this.captureState(rule.useEmphasis, rule.comment, rule.tag, current, 'case-10'));
              }

              current.value = node2.value;
              current.absorb(node2);

              const nApply: number = current.count(ApplyRecursive.isApplyNode);
              if (nApply > 1) { // Deep apply
                this.applyRecursive(current, ApplyRecursive.STATEFUL);
                return;
              }
              cancel = nApply === 0;
            }
          } while (node2);
        }

        if (modified && stateMode === ApplyRecursive.STATEFUL) {
          // Commented out because of https://screenshots.scolab.com/Public/Jing/SB/firefox_2018-12-18_13-10-35.png
          // states.push(captureState(false, null, null, current, "case-11"));
          // this.doIgnoreLastState("ignore-last-state-3");
          // console.log('ignore append state');
        }

        if (!cancel && current.numChildren > 0) {
          this.ignoreLastState = false;

          // Continue apply
          const firstValue: ContentElement = current.firstChild.value;

          if (firstValue instanceof FunctionElement) {
            if (this.tryApplyFunction(current, stateMode)) {
              // Tree was modified as a result of this function application.

              const wasCondensedWork: boolean = firstValue.wasCondensedWork;
              const hasPendingWork: boolean = firstValue.hasPendingWork;

              if (!(firstValue instanceof RelationalElement)
                && !wasCondensedWork
                && !this.copy.isLeaf
                && stateMode === ApplyRecursive.STATEFUL) {
                // Checked that the function application has produced
                // something else than a condensed data structure.
                this.states.push(this.captureState(true, null, null, current, 'case-12'));
              }

              if (hasPendingWork) {
                this.applyRecursive(current, stateMode, priority);
              }
              // Was removing last step of Sqrt(36000/625) https://screenshots.scolab.com/Public/Jing/SB/chrome_2020-08-05_12-55-59.png
              /*
              else if(wasCondensedWork && this.copy.isLeaf){
                console.log('firstValue', firstValue);
                console.log('this.copy.value', this.copy.value);
                this.doIgnoreLastState("ignore-last-state-4");
              } */
            }
          }
        }
      } else if (priority !== -1) {
        // Priority is defined and we just skipped an apply, it could be
        // treated in later priority or not. We have to keep track in order to
        // know if we want to do another apply pass.
        if (this.prioritySkipList.indexOf(current.priority) === -1) {
          this.prioritySkipList.push(current.priority);
        }
      }
    }
  }

  private static isFunctionApplication(node: Node): boolean {
    if (!node) {
      return false;
    }
    if (!(node.value instanceof Apply)) {
      return false;
    }
    if (!(node.firstChild.value instanceof FunctionElement)) {
      return false;
    }
    const fn: FunctionElement = node.firstChild.value;
    return (fn.getAttributes() & Attributes.FUNCTION_MODEL) > 0;
  }

  private static isApplyNode(node: Node): boolean {
    return node.value instanceof Apply;
  }

  private constructDictionary(dictNode: Node): WDictionary {
    const items: WPair[] = [];

    if (dictNode.childs.length === 0) {
      return null;
    }

    for (let i: number = 0; i < dictNode.numChildren; i++) {
      const child: Node = dictNode.childs[i];
      if (child.value instanceof WPair) {
        items.push(child.value);
      } else {
        return null;
      }
    }

    return new WDictionary(items);
  }

  private constructSet(setNode: Node): WFiniteSet {
    const items: TokenElement[] = [];
    for (let i: number = 0; i < setNode.numChildren; i++) {
      const child: Node = setNode.childs[i];
      if (child.value instanceof TokenElement && child.isLeaf) {
        items.push(child.value);
      } else {
        return null;
      }
    }

    return this.env.createNormalizedSet(items);
  }

  private constructList(listNode: Node, narrowAndWiden = false): ListElement {
    const items: ContentElement[] = [];

    let narrowable = false;
    let widenable = false;

    for (let i = 0; i < listNode.numChildren; i++) {
      const child: Node = listNode.childs[i];
      const value: ContentElement = child.value;

      if (value.getListItemCode() != null) {
        items.push(value);
        continue;
      }

      if (value.narrow()?.getListItemCode() != null) {
        if (narrowAndWiden) {
          items.push(value.narrow());
          continue;
        } else {
          narrowable = true;
        }
      }

      if (value.widen()?.getListItemCode() != null) {
        if (narrowAndWiden) {
          items.push(value.widen());
          continue;
        } else {
          widenable = true;
        }
      }

      if (value instanceof ListElement) {
        // list of list
        items.push(value);
        continue;
      }

      return null;
    }

    if (items.length === 0) {
      return this.env.culture.createEmptyList();
    }

    const list = (new ListFactory(this.env.culture)).createList(items);

    if (list) {
      return list;
    }

    if (narrowable || widenable) {
      return this.constructList(listNode, true);
    }
  }

  private constructRadical(node: Node): WRadical {
    if (node.childs.length === 1) {
      if (node.firstChild.value instanceof RealElement) {
        return new WRadical(
          node.firstChild.value,
          this.env.culture.createNumber(Number(node.value.other)),
          this.env.culture.createNumber(1),
          new RadicalFormatter(this.env.culture));
      }
    }

    return null;
  }

  /**
   *
   */
  private constructInterval(node: Node): WInterval {
    const closure: IntervalClosure = (node.value as Interval).closure;

    const lBound: ContentElement = node.childs[0].value;
    const rBound: ContentElement = node.childs.length === 1 ? lBound : node.childs[1].value;

    return WInterval.parse(
      closure,
      lBound,
      rBound,
      this.env.culture.formats.intervalFormatImpl);
  }

  /**
   * Capture the current expression tree, while flagging some nodes with css styles names.
   */
  private captureState(useEmphasis: boolean, comment: string, tag: string, node: Node = null, tag2: string = null): Expression {
    // apply the class name for every node concerned
    if (useEmphasis && node) {
      node.className = 'emphasis';
    }

    // make a clone of the expression tree from the root
    const state: Expression = new Expression(this.copy.clone());
    if (this.verbose) {
      state.comment = comment;
    }

    state.tag = `State-${String(this.states.length)}${tag ? ' --> ' + tag : ''}${tag2 ? ' --> ' + tag2 : ''}`;
    // trace("State- " + states.length)

    // restore the old styles names in the working copy of the expression tree
    this.clearNodesStyles(this.copy);

    // console.log(tag, tag2);
    return state;
  }

  /**
   *
   */
  private clearNodesStyles(
    node: Node): void {
    node.className = null;
    for (let i: number = 0; i < node.numChildren; i++) {
      this.clearNodesStyles(node.childs[i]);
    }
  }

  /**
   *
   */
  /*
  private function clearElementsStyles(
      node:Node):void{
    if(node.value)node.value.className = null;
    for(var i:uint = 0 ; i < node.numChildren ; i++){
      clearElementsStyles(node.childs[i]);
    }
  }
  */

  /**
   * Return true if the tree has been modified as a result of the function application.
   */
  private tryApplyFunction(
    node: Node,
    stateMode: number): boolean {
    const func: FunctionElement = node.childs[0].value as FunctionElement;

    if ((func.getAttributes() & Attributes.COMMUTATIVE) > 0) {
      return this.applyCommutatives(node);
    }
    if (func instanceof RelationalElement) {
      return this.applyRelational(node, func);
    }

    return this.applyDefault(node, func, stateMode);
  }

  /**
   * Return true if the tree has been modified as a result of the function application.
   */
  private applyCommutatives(node: Node): boolean {
    const funcNode: Node = node.firstChild;
    const func: FunctionElement = funcNode.value as FunctionElement;

    // Retirer la fonction à appliquer qui n'est pas un argument
    // pour faciliter la correspondance entre les index dans la
    // liste d'arguments et dans la liste d'enfants
    node.removeChild(funcNode);
    // *****************

    let modified: boolean = false;

    const beginIndex: number = 0;
    let endIndex: number = Number.MAX_SAFE_INTEGER;

    while (true) {
      let args: ContentElement[] = this.applyArguments(node, 0);

      if (args.length === 1) {
        node.value = args[0]; // try to rebox the content
        node.clear();
        break;
      }

      endIndex = beginIndex + 2;

      // takes the two first arguments
      args = args.slice(beginIndex, endIndex);

      let result: ContentElement = this.applyResult(func, args, false); // Pas de conversion, ensuite on essaie le reverse sans conversion,
      if (!result && !func.newValueNode) {
        result = this.applyResult(func, args.reverse(), false); // Depending on the function signature, reversing the parameters could work, and still make sense
      }
      if (!result && !func.newValueNode) {
        result = this.applyResult(func, args, true);
      }
      if (!result && !func.newValueNode) {
        result = this.applyResult(func, args.reverse(), true);
      }

      let newNode: Node = null;
      if (result != null) {
        newNode = new Node(result);
      } else if (func.newValueNode) {
        newNode = func.newValueNode;
        this.applyRecursive(newNode, ApplyRecursive.STATELESS);
      }

      if (newNode) {
        node.removeAt(endIndex - 1); // remove at end before or else the beginIndex will be invalid
        node.removeAt(beginIndex);
        node.insertChild(newNode, beginIndex);
        modified = true;
        continue;
      }

      break;
    }

    // If after applying the function there is more than one argument
    // then the function element must stay in place
    if (node.childs.length > 1) {
      node.insertChild(funcNode, 0);
    }

    return modified;
  }

  /**
   * Return true if the tree has been modified as a result of the function application.
   */
  private applyDefault(
    node: Node,
    func: FunctionElement,
    stateMode: number): boolean {
    const args: ContentElement[] = this.applyArguments(node, 1); // The function element is kept at index 0
    const result: ContentElement = this.applyResult(func, args);

    if (result != null) {
      // Function application resulted in a single ContentElement,
      // we can replace the Apply by the resulting ContentElement
      node.value = result;
      node.clear();
      return true;
    }
    if (func.newArguments1) {
      // When result is array, that means that the apply
      // must stay in place, but that the arguments has been modified/simplified
      // The array contains a modified copy of each arguments
      node.clear(1); // preserve the first element, begin the clear at index 1
      for (let i: number = 0; i < func.newArguments1.length; i++) {
        node.appendChild(new Node(func.newArguments1[i]));
      }

      return true;
    }
    if (func.newValueNode) {
      const newNode: Node = func.newValueNode;
      node.value = newNode.value;
      node.absorb(newNode);
      this.applyRecursive(node, ((func.getAttributes() & Attributes.EXPRESSION_FACTORY) > 0) ? ApplyRecursive.STATEFUL : stateMode);
    }

    return false;
  }

  private ignoreLastState: boolean = false;

  private doIgnoreLastState(tag: string): void {
    // console.log (tag);
    this.ignoreLastState = true;
  }

  private applyRelational(node: Node, relArg: RelationalElement): boolean {
    let rel = relArg;
    let modified: boolean = false;
    let state: Expression;
    while (true) {
      rel.assignFlag = false;

      const args: ContentElement[] = this.applyArguments(node, 1);
      const result: ContentElement = this.applyResult(rel, args);

      if (rel.assignFlag) {
        this.doIgnoreLastState('ignore-last-state-1');
        state = this.captureState(true, null, null, null, 'case-1');
      }

      if (result != null) {
        if (state) {
          this.states.push(state);
        }

        node.value = result;
        node.clear();
        modified = true;
        // should break, the task here is complete
        // break; we dont need it since there is one at the end of the loop
      } else if (rel.newArguments2) {
        if (state) {
          this.states.push(state);
        }

        if (rel.newArguments2.length === 2) {
          // each operand of the relation is replaced by the new node

          const l: Node = rel.newArguments2[0];
          const r: Node = rel.newArguments2[1];

          if (rel.requireReverse) {
            rel = rel.reverse;
            node.clear(0);
            node.insertChild(new Node(rel), 0);
          } else {
            node.clear(1);
          }

          node.insertChild(l, 1);
          node.insertChild(r, 2);

          this.states.push(this.captureState(true, null, null, null, 'case-2'));

          this.applyRecursive(l, ApplyRecursive.STATELESS);
          this.applyRecursive(r, ApplyRecursive.STATELESS);

          state = this.captureState(true, null, null, null, 'case-3');

          modified = true;
          continue;
        }
      }

      break;
    }

    return modified;
  }

  private applyResult(
    func: FunctionElement,
    args: ContentElement[],
    convert: boolean = true): ContentElement {
    let evaluator: Evaluate;
    try {
      if (func instanceof AnonymousFunction) {
        evaluator = new Evaluate(func, this.env);
        return evaluator.evaluateN(args);
      }

      if (func instanceof RecallFunction) {
        // arg0: stopCondition
        // arg1: defaultValueOnStopOrArgument
        // arg2-argN: recall arguments
        if (args.length > 0) {
          if (args[0] instanceof WBoolean) {
            if ((args[0] as WBoolean).toBoolean()) {
              // stop
              return args.length > 1 ? args[1] : null;
            }
            // Send arguments 1 to n, only use 2 to n if it matches the arguments count.
            // Argument-1 can be a default or the first argument of the recall.
            evaluator = new Evaluate(func.functionRef, this.env);
            return evaluator.evaluateN(args.slice(args.length - evaluator.getArgumentsCount()));
          }
          throw new MathError('First argument of recall(@) must be true/false.');
        }
      } else {
        // Reset function state
        func.wasCondensedWork = false;
        func.hasPendingWork = false;
        func.newArguments1 = null;
        func.newArguments2 = null;
        func.newValueNode = null;

        const callResult: ContentElement = func.callReturnElement(new ArgumentsObject(args, this.env, convert));

        if (callResult) {
          return callResult;
        }
        if (args.length === 1) {
          if (args[0] instanceof ListElement) {
            return this.applyResult(func, (args[0] as ListElement).items);
          }
        }
      }
    } catch (error) {
      if (error instanceof MathError) {
        return error.errorElement ? error.errorElement : new WError(error.message);
      }
      throw error;
    }
    return null;
  }

  /**
   * Transform every childs of a node into an argument list of type ContentElement.
   */
  private applyArguments(node: Node, beginIndex: number = 0): ContentElement[] {
    if (node.childs.length === beginIndex + 1) {
      const value = node.childs[beginIndex].value;
      if (value instanceof List) {
        return this.applyArguments(node.childs[beginIndex]);
      }
      if (value instanceof ListOfListElement) {
        const narrowed = value.narrow();

        if (narrowed) { // might be a list of points or something
          return [narrowed];
        }

        return value.items; // treat the list of list as a list of arguments
      }
    }

    const args: ContentElement[] = [];
    for (let i = beginIndex; i < node.childs.length; i++) {
      args.push(node.childs[i].value);
    }

    return args;
  }

  /**
   * Formats are applied to numbers, list of numbers, rationals.
   */
  private setFormat(
    node: Node,
    recursive: boolean): void {
    if (!this.format) {
      return;
    }

    if (recursive) {
      if (this.format.numberFormatImpl || this.format.rationalFormatImpl) {
        const node2: Node = this.childNodeToFormat(node);

        if (node2) {
          this.setFormat(node2, false);

          if (node2.value instanceof WNumber) {
            if (node2.value.approximate) {
              node.childs[0].value = new AlmostEqualTo();
            }
          }
        }
      }
    }

    node.value = this.formatter.applyFormat(node.value);
  }

  //
  private static rightAssignmentPatterns: any[] = Skeleton.combine('=({0},{1})', [['n', 'r'], ['x', 'i']]);

  private static leftAssignmentPatterns: any[] = Skeleton.combine('=({0},{1})', [['x', 'i'], ['n', 'r']]);

  /**
   *
   */
  private childNodeToFormat(parentNode: Node): Node {
    if (!parentNode.isSimple) {
      return null;
    }

    const s: string = Skeleton.createSkeleton(parentNode);
    let i: number;

    for (i = 0; i < ApplyRecursive.rightAssignmentPatterns.length; i++) {
      if (s === ApplyRecursive.rightAssignmentPatterns[i]) {
        return parentNode.childs[1];
      }
    }

    for (i = 0; i < ApplyRecursive.leftAssignmentPatterns.length; i++) {
      if (s === ApplyRecursive.leftAssignmentPatterns[i]) {
        return parentNode.childs[2];
      }
    }

    return null;
  }
}
