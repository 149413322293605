import { observable, action } from 'mobx';
import { getPageBoundStoreNameGetter, getPageBoundStorePreloadInfo } from '../../dependencyInjection/utils';
import { Store } from '../../dependencyInjection/Store';
import { IVariablesEditor } from '../IVariablesEditor';

export const ContextModelSelection = Symbol('ContextModelSelection');

export const getContextModelStoreName = getPageBoundStoreNameGetter('ContextModelStore');
const preloadInfo = getPageBoundStorePreloadInfo('ContextModelStore');

@Store(getContextModelStoreName, preloadInfo)
export class ContextModelStore {
  @observable
  public accessor variablesEditor: IVariablesEditor;

  @action.bound
  public setVariablesEditor(variablesEditor: IVariablesEditor): void {
    this.variablesEditor = variablesEditor;
  }

  @action.bound
  public setDebugSeed(value: number): void {
    this.variablesEditor.debugSeed = value;
  }

  @action.bound
  public setDebugSeedLocked(value: boolean): void {
    this.variablesEditor.debugSeedLocked = value;
  }

  @action.bound
  public setDebugLocale(value: string): void {
    this.variablesEditor.debugLocale = value;
  }
}
