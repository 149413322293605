import { UserSessionStore } from '../models/store/UserSessionStore';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { PublisherApiAuth } from '../services/PublisherApiAuth';

@ViewModel
export class UserSettingsMediator {
  @inject(UserSessionStore)
  private accessor userSessionStore: UserSessionStore;

  public get avatarURL(): string {
    return this.userSessionStore.memberInfo.avatar;
  }

  public logOut = async (): Promise<void> => {
    await this.userSessionStore.invalidateCurrentJWT();
    PublisherApiAuth.clearToken();
    window.location.href = '/';
  };
}
